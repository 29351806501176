import { Carousel } from 'react-bootstrap'
import './HeroImageCarousel.sass'
import { BannerP2EORP2O, BannerCrabadaBattleGame, BannerPizzaGameAnnounce,BannerUSDTogleAnnounce } from '../../images/index'
import { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const heroImagesAnnounce = [
  {
    name: 'Crabada Blog',
    img: BannerP2EORP2O,
    route: 'https://p2eanalytics.com/web3/play-to-earn-or-play-to-own-whats-the-difference'
  },
  {
    name: 'Pizza Game',
    img: BannerPizzaGameAnnounce,
    route: '/games/pizza-game'
  },
  {
    name: `USD Toggle`,
    img: BannerUSDTogleAnnounce,
    route: '/games/crabada'
  },{
    name: `Launching into Space with Imperium Empires`,
    img: BannerCrabadaBattleGame,
    route: 'https://p2eanalytics.com/web3/crabada-arena-mode'
  }
]

function HeroImageCarousel(props: any) {
  const [heroImages, setHeroImages] = useState([])
  
  useLayoutEffect(() => {
    function handleReSize() {
      let heroImages: any = window.screen.width > 767 ? heroImagesAnnounce : heroImagesAnnounce;
      setHeroImages(heroImages)
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])

  return (
    <div className='hero-carousel-container'>
      <Carousel className='hero-carousel'
        controls={true}
      >
        {
          heroImages && heroImages.map((item: any, index: number) => 
          <Carousel.Item interval={4000} key={index}>
            <Link to={item.route}>
              <img
                className="d-block w-100"
                src={item.img}
                alt="Second slide"
              />
            </Link>
          </Carousel.Item>
          )
        }
      </Carousel>
    </div>
  )
}

export default HeroImageCarousel;
