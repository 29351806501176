import MarketplaceContainer from "../../../components/Marketplace/MarketplaceContainer";
import {AVAX} from "../../../images";
import SnailEconomyAPI from "../api/SnailEconomyAPI";

function SnailTrailMarketplace() {
  return (
      <MarketplaceContainer
          nftName={"Snail"}
          baseCurrencyName={"AVAX"}
          baseCurrencyImgSrc={AVAX}
          api={SnailEconomyAPI}
          isDollarToggleVisible={false}
          isInSightsVisible={true}
      />
  )
}

export default SnailTrailMarketplace
