import axios from 'axios'

// Economy API
export const getMarketplaceSales = (frequency: string) => {
  let url = "https://api.p2eanalytics.com/62a215479fffa60a038299e7/economy/marketplace";
  let params = {
    frequency: frequency
  };
  return axios.get(url, { params });
}

export const getMarketplaceGeneral = () => {
  let url = "https://api.p2eanalytics.com/62a215479fffa60a038299e7/economy/marketplace/general";
  return axios.get(url);
}

const ChefToolEconomyAPI = {
  getMarketplaceSales: getMarketplaceSales,
  getMarketplaceGeneral: getMarketplaceGeneral
}

export default ChefToolEconomyAPI;
