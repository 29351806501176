import { useEffect, useState } from 'react'
import './ReadingProgressBar.sass'

function ReadingProgressBar(props: any) {
  const [progressWidth, setProgressWidth] = useState(0)
  useEffect(() => {
    const onScroll = () => setProgressWidth(window.pageYOffset / (document.body.clientHeight - window.innerHeight) * 100)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  
  return (
    <div className='reading-progress-bar'>
      <div className='current-progress'
        style={{width: progressWidth + '%'}}
      ></div>
    </div>
  )
}

export default ReadingProgressBar