import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReadingProgressBar from '../../components/ReadingProgressBar/ReadingProgressBar'
import ResourcesAPI from './ResourcesAPI'
import mikoBlogs from './MikoBlogs'
import './WordPress.sass'
import LatestArticles from './LatestArticles'
import {Helmet} from "react-helmet";

function ResourcesDetail() {
  const detailRef = useRef<any>(null);
  const [latestArticlesHeight, setLatestArticlesHeight] = useState(0);
  const [currentPost, setCurrentPost] = useState({
    id: '',
    created_at: '',
    modified_at: '',
    slug: '',
    // type: '',
    link: '',
    title: '',
    content: '',
    excerpt: '',
    // featured_image_src: '',
    // featured_image_src_square: '',
    featured_image_src_original: '',
    author_name: '',
    // author_avatar: '',
    est_reading_time: ''
  });
  const params = useParams();

  useEffect(() => {
    getResourceBySlug();
    initLatestArticlesHeight();
  }, [params.slug]);

  useEffect(() => {
    initLatestArticlesHeight();
  }, [currentPost]);

  const initLatestArticlesHeight = () => {
    setLatestArticlesHeight(detailRef.current.clientHeight);
  }

  const getResourceBySlug = () => {
    ResourcesAPI.getResourceBySlug(params.slug)
      .then(res => {
        if(res.data){
          let _data = res.data[0]
          setCurrentPost({
            id: _data.id,
            created_at: _data.date,
            modified_at: _data.modified,
            slug: _data.slug,
            // type: _data.type,
            link: _data.link,
            title: _data.title.rendered,
            content: _data.content.rendered,
            excerpt: _data.excerpt.rendered,
            // featured_image_src: _data.featured_image_src,
            // featured_image_src_square: _data.featured_image_src_square,
            featured_image_src_original: _data.yoast_head_json?.og_image ? _data.yoast_head_json?.og_image[0].url : '',
            author_name: _data?._embedded.author[0].name ? _data?._embedded.author[0].name : _data.yoast_head_json.twitter_misc["Written by"],
            // author_avatar: _data?._embedded.author[0].avatar_urls["96"],
            est_reading_time: _data.yoast_head_json?.twitter_misc ? _data.yoast_head_json?.twitter_misc["Est. reading time"] : '',
          });
          // 
          if(_data.yoast_head_json?.og_image) {
            document.querySelector('meta[property="twitter:image"]')?.setAttribute("content", _data.yoast_head_json?.og_image[0].url);
            document.querySelector('meta[property="og:image"]')?.setAttribute("content", _data.yoast_head_json?.og_image[0].url);
          }
          document.querySelector('meta[property="twitter:image:alt"]')?.setAttribute("content", _data.slug);
          document.querySelector('meta[property="twitter:description"]')?.setAttribute("content", _data.excerpt.rendered);
          document.querySelector('meta[property="twitter:title"]')?.setAttribute("content", _data.title.rendered);
          // 
          document.querySelector('meta[property="og:image:alt"]')?.setAttribute("content", _data.slug);
          document.querySelector('meta[property="og:description"]')?.setAttribute("content", _data.excerpt.rendered);
          document.querySelector('meta[property="og:title"]')?.setAttribute("content", _data.title.rendered);
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }

  const getAuthor = (slug: string, author: string) => {
    if (mikoBlogs.includes(slug)) {
      return 'By Miko'
    } else {
      return author ? 'By ' + author : ''
    }
  }

  return (
    <div id='page' className='resources detail pb-5'>
      <Helmet>
        <meta name="twitter:image" content={currentPost.featured_image_src_original} />
      </Helmet>
      <ReadingProgressBar />
      <div className='container'>
        {
          currentPost.featured_image_src_original && 
          <div className='blog-img-container mt-5'>
            <img src={currentPost.featured_image_src_original} alt='' />
          </div>
        }
        {/*  */}
        <div className='row mt-5'>
          <div className='col-12 col-sm-12 col-md-8'>
            <div className='' ref={detailRef}>
              <h2 className='blog-title'>
                {currentPost.title}
              </h2>
              <div className='blog-date mt-3'>{currentPost.created_at ? ('Posted ' + moment(currentPost.created_at).format('MMM DD, YYYY h:mm a')) : ''}</div>
              <div className='blog-date mt-2'>
                {currentPost.est_reading_time?.replace('minutes', 'min')}
              </div>
              <div className='blog-date mt-2'>{getAuthor(currentPost.slug, currentPost.author_name)}</div>
              <div className='blog-content mt-5' dangerouslySetInnerHTML={{__html: currentPost.content}}></div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-4'>
            <div className='latest-articles blue-top'>
              <h3 className='title'>Latest Articles</h3>
              <div className='articles-container' style={{maxHeight: `${latestArticlesHeight}px`}}>
                <LatestArticles />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourcesDetail