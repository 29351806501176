import axios from 'axios'

// Economy API
export const getMarketplaceSales = (frequency: string) => {
  let url = "https://api.p2eanalytics.com/62c70624eaf0ec75e8c13c03/economy/marketplace";
  let params = {
    frequency: frequency
  };
  return axios.get(url, { params });
}

export const getMarketplaceGeneral = () => {
  let url = "https://api.p2eanalytics.com/62c70624eaf0ec75e8c13c03/economy/marketplace/general";
  return axios.get(url);
}

const SnailEconomyAPI = {
  getMarketplaceSales: getMarketplaceSales,
  getMarketplaceGeneral: getMarketplaceGeneral
}

export default SnailEconomyAPI;
