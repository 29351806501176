import { useNavigate } from 'react-router-dom'
import './CareerCard.sass'

function CareerCard(props: any) {
  const { career } = props
  const navigate = useNavigate()

  return (
    <div className='career-card my-3'>
      <h4 className='career-title'>{career.title}</h4>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-8'>
          <p className='career-content'>
            {career.description}
          </p>
        </div>
      </div>
      <div className='row mx-0'>
        <button className='btn-apply'
          onClick={() => navigate(`/careers/${career.id}`)}
        >Read More</button>
      </div>
    </div>
  )
}

export default CareerCard