import { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import './CareersHeader.sass'

function CareersHeader() {
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState('')

  useEffect(() => {
    if(location.pathname.includes('economy')) {
      setCurrentRoute('Economy')
    } else if(location.pathname.includes('population')) {
      setCurrentRoute('Population')
    } else if(location.pathname.includes('crab-prices')) {
      setCurrentRoute('Crab Prices')
    }
  }, [location])

  return (
    <div className='careers-header'>
      <div className='header-container'>
        <div className='header-title'>
          Opportunities
        </div>
        {/* <ul className='nav-items'>
          <li className='nav-item active'>
            <Link to={`/`} className='nav-link'>Devs</Link>
          </li>
          <li className='nav-item'>
            <Link to={`/`} className='nav-link'>Design</Link>
          </li>
          <li className='nav-item'>
            <Link to={`/`} className='nav-link'>Marketing</Link>
          </li>
          <li className='nav-item'>
            <Link to={`/`} className='nav-link'>Operations</Link>
          </li>
        </ul>
        <DropdownButton className='filter-dropdown' title={'Filter'}>
          <Dropdown.Item href={'/'}>{'Development'}</Dropdown.Item>
          <Dropdown.Item href={'/'}>{'Design'}</Dropdown.Item>
          <Dropdown.Item href={'/'}>{'Operations'}</Dropdown.Item>
          <Dropdown.Item href={'/'}>{'Publice Relations'}</Dropdown.Item>
          <Dropdown.Item href={'/'}>{'Finance'}</Dropdown.Item>
        </DropdownButton> */}
      </div>
      <div className='mobile-header'>
        <div className='container'>
          <div className='header-title'>
            Opportunities
          </div>
          {/* <DropdownButton className='filter-dropdown' title={'Filter'}>
            <Dropdown.Item href={'/'}>{'Development'}</Dropdown.Item>
            <Dropdown.Item href={'/'}>{'Design'}</Dropdown.Item>
            <Dropdown.Item href={'/'}>{'Operations'}</Dropdown.Item>
            <Dropdown.Item href={'/'}>{'Publice Relations'}</Dropdown.Item>
            <Dropdown.Item href={'/'}>{'Finance'}</Dropdown.Item>
          </DropdownButton> */}
        </div>
      </div>
    </div>
  )
}

export default CareersHeader