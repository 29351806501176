import axios from 'axios'

const headers = {
    'Content-Type': 'application/json',
};

const getGameProfiles = (page: number) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/game_profile?order_by=date&order=asc&per_page=10&page=${page}`;
    return axios.get(url, { headers: headers });
}

const getProfileById = (id: any) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/game_profile/${id}`;
    return axios.get(url, { headers: headers });
}

const getProfileBySlug = (slug: any) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/game_profile?slug=${slug}`;
    return axios.get(url, { headers: headers });
}

const GameProfileAPI = {
    getGameProfiles: getGameProfiles,
    getProfileById: getProfileById,
    getProfileBySlug: getProfileBySlug
}

export default GameProfileAPI;
