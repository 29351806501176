import { useContext, useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom"
import GameProfileAPI from "../apis/GameProfileAPI";
import ProjectAPI from "../apis/ProjectAPI";
import GamePageLayout from "../components/Layouts/GamePageLayout"
import NavContext from "../context/NavContext";
import { BannerCrabada, BannerPizzaGame, SnailTrailHeader } from "../images";
import Profile from "./Profile/Profile"

const gameNavs = [{
    title: 'Crabada',
    type: 'game',
    position: 'middle', // top, middle, bottom
    banner: BannerCrabada,
    routes: [
      {
        name: 'Profile',
        route: '/games/crabada-game/profile'
      },
      {
        name: 'Marketplace',
        route: '/games/crabada/marketplace'
      },
      // {
      //   name: 'Treasure Under Sea',
      //   route: '/games/crabada/treasure-under-sea'
      // },
      {
        name: 'Population',
        route: '/games/crabada/population'
      },
      // {
      //   name: 'Crab Prices',
      //   route: '/games/crabada/crab-prices'
      // },
    ],
  },
  {
    title: 'Pizza Game',
    type: 'game',
    position: 'middle', // top, middle, bottom
    banner: BannerPizzaGame,
    routes: [
      {
        name: 'Profile',
        route: '/games/pizza-game/profile'
      },
      {
        name: 'Marketplace',
        route: '/games/pizza-game/marketplace'
      },
      {
        name: 'Pizza Token',
        route: '/games/pizza-game/pizza-token'
      },
      {
        name: 'Soda Token',
        route: '/games/pizza-game/soda-token'
      },
    ],
  },
  {
    title: 'Snail Trail',
    type: 'game',
    position: 'middle',
    banner: SnailTrailHeader,
    routes: [
      {
        name: 'Profile',
        route: '/games/snail-trail/profile'
      },
      {
        name: 'Marketplace',
        route: '/games/snail-trail/marketplace'
      },
      // {
      //   name: 'Slime Token',
      //   route: '/games/snail-trail/slime-token'
      // }
    ],
  }
]

function Games(props: any) {
  const { setNavs } = useContext(NavContext);
  const params = useParams();
    const slug = params.slug;
    const [data, setData] = useState<any>(null);
    const [erc20IDs, setErc20IDs] = useState<any>([]);
    const [gameNav, setGameNav] = useState<any>(null);

    useEffect(() => {
      getGameProfileInfo();
    }, [slug]);

    const getGameProfileInfo = () => {
      GameProfileAPI.getProfileBySlug(slug)
        .then((res: any) => {
          if(res.status === 200 && res.data) {
            setData(res.data[0]);
            getGameAssetIdsByGameName(res.data[0].game_name);
            initGameNav(res.data[0]);
          }
        })
        .catch((err: any) => {
            console.log('err: ', err);
        });
    }

    const getGameAssetIdsByGameName = (game_name: string) => {
      ProjectAPI.getProjects()
        .then((res: any) => {
          if(res.status === 200 && res.data) {
            let _erc20IDs = [];
            for (let i = 0; i < res.data.length; i++) {
              if(res.data[i].name === game_name) {
                let assets = res.data[i].assets;
                for (let j = 0; j < assets.length; j++) {
                  if(assets[j].includes('erc20:')) {
                    _erc20IDs.push(assets[j]);
                  }
                }
              }
            }
            setErc20IDs(_erc20IDs);
          }
        })
        .catch((err: any) => {
          console.log('err: ', err);
        });
    }

    const initGameNav = (res: any) => {
      for(let i = 0; i < gameNavs.length; i++) {
        if(gameNavs[i].title === res.game_name) {
          let _gameNav = gameNavs[i];
          _gameNav.banner = res.banner_image.guid;
          _gameNav.routes[0].route = `/games/${slug}/profile`;
          setGameNav(_gameNav);
          setNavs(_gameNav)
          break;
        }
      }
    }

  return (
    <GamePageLayout title={data?.game_name} navs={gameNav} tokens={data?.game_tokens} erc20IDs={erc20IDs}>
      <Routes>
        <Route
          path="/"
          element={<Profile data={data} erc20IDs={erc20IDs} />}
        />
        <Route path='/profile' element={<Profile data={data} />} />
      </Routes>
    </GamePageLayout>
  )
}

export default Games
