import ComingSoon from "../Marketplace/ComingSoon";
import Inflation from "./Inflation";

type InflationContainerProps = {
  isUnderMaintenance?: boolean,
  title: string,
  chartsComponent: any
}

function InflationContainer({isUnderMaintenance = false, title, chartsComponent} : InflationContainerProps) {
  return (
      isUnderMaintenance ?
          <ComingSoon title={title} /> :
          <Inflation
            title={title}
            chartsComponent={chartsComponent}
          />
  )
}

export default InflationContainer