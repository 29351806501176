import InflationContainer from "../../../components/Inflation/InflationContainer";
import TreasureUnderSeaChart from "./TreasureUnderSeaChart";

function TreasureUnderSeaInflation() {
  return (
      <InflationContainer
          title="Treasure Under Sea"
          chartsComponent={
            <TreasureUnderSeaChart
                inSightsShow={true}
            />
          }
          isUnderMaintenance={true}
      />
  )
}

export default TreasureUnderSeaInflation