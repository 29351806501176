import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CrabPrices from './CrabPrices'
import PopulationContainer from './Population/PopulationContainer'
import NavContext from '../../context/NavContext'
import { BannerCrabada } from '../../images'
import GameTopNavBar from '../../components/GameTopNavBar/GameTopNavBar'
import CrabadaTokens from './CrabadaTokens/CrabadaTokens'
import TreasureUnderSeaInflation from './TreasureUnderSea/TreasureUnderSeaInflation'
import CrabadaMarketplace from "./Marketplace/CrabadaMarketplace";

const gameNavs = {
  title: 'Crabada',
  type: 'game',
  position: 'middle', // top, middle, bottom
  banner: BannerCrabada,
  routes: [
    {
      name: 'Profile',
      // route: '/games/crabada/profile'
      route: '/games/crabada-game/profile'
    },
    {
      name: 'Marketplace',
      route: '/games/crabada/marketplace'
    },
    // {
    //   name: 'Treasure Under Sea',
    //   route: '/games/crabada/treasure-under-sea'
    // },
    {
      name: 'Population',
      route: '/games/crabada/population'
    },
    // {
    //   name: 'Crab Prices',
    //   route: '/games/crabada/crab-prices'
    // },
  ],
}

function Crabada() {
  const { setNavs } = useContext(NavContext)
  useEffect(() => {
    setNavs(gameNavs)
  }, [])

  return(
    <div className='container-fluid py-5'>
      <div className='dashboard-titlebar'>
        <div className='title-bar'>
          <div className='title'>Crabada</div>
          <CrabadaTokens />
        </div>
      </div>
      <GameTopNavBar navs={gameNavs} />
      <div className='dashboard-container'>
        <div className='dashboard-content'>
          <Routes>
            {/* <Route
              path="/"
              element={<Navigate to="/games/crabada/profile" replace />}
            /> */}
            <Route path='/marketplace' element={<CrabadaMarketplace />} />
            <Route path='/treasure-under-sea' element={<TreasureUnderSeaInflation />} />
            <Route path='/population' element={<PopulationContainer />} />
            <Route path='/crab-prices' element={<CrabPrices />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Crabada