import Marketplace from './Marketplace'
import ComingSoon from "./ComingSoon";
import MarketplaceApiType from "../../games/MarketplaceApiType";
import MaintenanceMarketplace from './MaintenanceMarketplace';

type MarketplaceContainerProps = {
  nftName: string,
  baseCurrencyName: string,
  baseCurrencyImgSrc: string,
  api: MarketplaceApiType,
  isDollarToggleVisible: boolean,
  isInSightsVisible: boolean,
  isComingSoon?: boolean,
  isMaintenance?: boolean,
  isTechnicalDifficultiesCallOutBoxVisible?: boolean
}

function MarketplaceContainer(
    {
      isComingSoon = false,
      isMaintenance = false,
      baseCurrencyName,
      baseCurrencyImgSrc,
      api,
      nftName,
      isDollarToggleVisible,
      isInSightsVisible,
      isTechnicalDifficultiesCallOutBoxVisible = false
    } : MarketplaceContainerProps
) {
  return (
    <>
      {!isComingSoon && !isMaintenance && 
        <Marketplace
            baseCurrencyName={baseCurrencyName}
            baseCurrencyImgSrc={baseCurrencyImgSrc}
            api={api}
            nftName={nftName}
            isDollarToggleVisible={isDollarToggleVisible}
            isInSightsVisible={isInSightsVisible}
            isTechnicalDifficultiesCallOutBoxVisible={isTechnicalDifficultiesCallOutBoxVisible}
        />
      }
      {isComingSoon && <ComingSoon title="Marketplace" />}
      {!isComingSoon && isMaintenance && <MaintenanceMarketplace />}
    </>
  )
}

export default MarketplaceContainer