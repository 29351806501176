import InflationContainer from "../../../components/Inflation/InflationContainer";
import SodaTokenCharts from "./SodaTokenCharts";

function SodaTokenInflation() {
	return (
			<InflationContainer
				title="Soda Token"
				chartsComponent={
					<SodaTokenCharts
							inSightsShow={true}
					/>
				}
			/>
	)
}

export default SodaTokenInflation