import axios from 'axios'

const headers = {
    'Content-Type': 'application/json',
};

// Token Values
export const getTokenValues = () => {
    let url = "https://api.crabada.com/public/price/using";
    return axios.get(url);
}

// Economy API
export const getMarketplaceSales = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/626ab3f9f19ae260acb3f7df/economy/marketplace";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getRollingData = () => {
    let url = "https://api.p2eanalytics.com/626ab3f9f19ae260acb3f7df/economy/marketplace";
    let params = {
        frequency: 'TWENTY_FOUR'
    };
    return axios.get(url, { params });
}

export const getMarketplaceGeneral = () => {
    let url = "https://api.p2eanalytics.com/626ab3f9f19ae260acb3f7df/economy/marketplace/general";
    return axios.get(url);
}

export const getInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/626ab49efe0d854695198b42/economy/inflation";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/626ab49efe0d854695198b42/economy/inflation/general";
    return axios.get(url);
}

// Population API
export const getPopulation = (crabPopulationFilter: string) => {
    let url = "https://api.p2eanalytics.com/626a2a0f2fc2e75a7576cf54/population";
    let params = {
        pure: crabPopulationFilter
    };
    return axios.get(url, { params });
}

export const getPopulationDistribution = () => {
    let url = "https://api.p2eanalytics.com/626a2a0f2fc2e75a7576cf54/population/distribution";
    return axios.get(url);
}

export const getPopulationPurity = () => {
    let url = "https://api.p2eanalytics.com/626a2a0f2fc2e75a7576cf54/population/purity";
    return axios.get(url);
}

export const getPopulationPurityDistribution = () => {
    let url = "https://api.p2eanalytics.com/626a2a0f2fc2e75a7576cf54/population/purity/distribution";
    return axios.get(url);
}

export const getPopulationClassPurityDistribution = () => {
    let url = "https://api.p2eanalytics.com/626a2a0f2fc2e75a7576cf54/population/purity/class/distribution";
    return axios.get(url);
}

// Crab Prices API
export const getCrabPrices = (params: any) => {
    let url = "https://api.p2eanalytics.com/marketplace/game/crabada/prices";
    return axios.post(url, params, { headers: headers });
}

const CrabadaAPI = {
    getTokenValues: getTokenValues,
    getMarketplaceSales: getMarketplaceSales,
    getRollingData: getRollingData,
    getMarketplaceGeneral: getMarketplaceGeneral,
    getInflationData: getInflationData,
    getInflationGeneral: getInflationGeneral,
    getPopulation: getPopulation,
    getPopulationDistribution: getPopulationDistribution,
    getPopulationPurity: getPopulationPurity,
    getPopulationPurityDistribution: getPopulationPurityDistribution,
    getPopulationClassPurityDistribution: getPopulationClassPurityDistribution,
    getCrabPrices: getCrabPrices
}

export default CrabadaAPI;