import { Link } from 'react-router-dom'
import { 
  Twitter,
  Telegram,
  Discord,
  Medium,
  GameControllerIconB
} from '../../images/index'
import './FeaturedGame.sass'

function FeaturedGame(props: any) {
  const { game } = props;

  return (
    <div className='featured-game'>
      <div className='card'>
        <div className='card-container'>
          <div className='card-header'>
            <Link to={`/games/${game.slug}/profile`} className="link-img">
              <img src={game.banner_image.guid} className='thumbnail' alt='' />
            </Link>
          </div>
          <div className='card-body'>
            {/* <div className='row'> */}
              <div className='game-title-wrapper'>
                <div className='game-title'>{game.game_name}</div>
              </div>
              <div className='token-items-wrapper mt-2'>
                <div className='token-items'>
                  {
                    game.game_tokens && game.game_tokens.map((item: any, index: number) =>
                      <div className='token-item' key={index}>
                        <img src={item.guid} className='token-icon' alt='-' />{item.post_title}
                      </div>
                    ) 
                  }
                </div>
              </div>
            {/* </div> */}
            <div className='row mt-4'>
              <div className='col-9 col-sm-10 col-md-6'>
                <div className='social-items'>
                  {
                    game.social_twitter && 
                    <a href={game.social_twitter} className='social-item' target='_blank'>
                      <img src={Twitter} className='social-icon' alt='twitter' />
                    </a>
                  }
                  {
                    game.social_telegram && 
                    <a href={game.social_telegram} className='social-item' target='_blank'>
                      <img src={Telegram} className='social-icon' alt='telegram' />
                    </a>
                  }
                  {
                    game.social_discord && 
                    <a href={game.social_discord} className='social-item' target='_blank'>
                      <img src={Discord} className='social-icon' alt='discord' />
                    </a>
                  }
                  {
                    game.social_medium && 
                    <a href={game.social_medium} className='social-item' target='_blank'>
                      <img src={Medium} className='social-icon' alt='medium' />
                    </a>
                  }
                </div>
              </div>
              <div className='col-3 col-sm-2 col-md-6 d-flex'>
                <a href={game.game_website} className='social-link' target='_blank' rel='nofollow'>
                  <img src={GameControllerIconB} className='icon-web' alt='' />
                  {/* {game.game_website ? game.game_website.replace('https://', '').replace('http://', '').replace('www.', '') : ''} */}
                </a>
              </div>
            </div>
            <div className='button-wrapper'>
              <Link to={`/games/${game.slug}/profile`} className='btn-launch'>LAUNCH</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedGame;
