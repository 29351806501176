import axios from 'axios'

// Token Values
export const getTokenValues = () => {
    let url = "https://api.p2eanalytics.com/erc20s/erc20:62c705a640548e647e8cf30e/quote";
    return axios.get(url);
}

export const getSlimeCirculatingSupplyInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/62c705a640548e647e8cf30e/economy/inflation?scanIndexId=62c706a46e04e92c768854ae";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getSlimeCirculatingSupplyInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/62c705a640548e647e8cf30e/economy/inflation/general?scanIndexId=62c706a46e04e92c768854ae";
    return axios.get(url);
}

export const getSlimeNetSupplyInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/62c705a640548e647e8cf30e/economy/inflation?scanIndexId=62c7068ba46f0b60ddb07fd8";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getSlimeNetSupplyInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/62c705a640548e647e8cf30e/economy/inflation/general?scanIndexId=62c7068ba46f0b60ddb07fd8";
    return axios.get(url);
}

const SlimeAPI = {
    getTokenValues: getTokenValues,
    getSlimeCirculatingSupplyInflationData: getSlimeCirculatingSupplyInflationData,
    getSlimeCirculatingSupplyInflationGeneral: getSlimeCirculatingSupplyInflationGeneral,
    getSlimeNetSupplyInflationData: getSlimeNetSupplyInflationData,
    getSlimeNetSupplyInflationGeneral: getSlimeNetSupplyInflationGeneral
}

export default SlimeAPI;
