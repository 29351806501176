import moment from "moment"
import { useEffect, useState } from "react"
import ComponentLoader from "../../../components/ComponentLoader/ComponentLoader"
import { BarGraph, LineGraph } from "../../../components/Graphs"
import { Bulk, ChartIcon, Craboid, Gem, InfomationIcon, Organic, Prime, Ruined, Sunken, Surge } from "../../../images"
import { toDateString } from "../../../utils/DateUtil"
import CrabadaAPI from "../CrabadaAPI"

const crabs: any = {
  SURGE: {color: '#fc252b', background: '#40090B', img: Surge},
  SUNKEN: {color: '#108c8c', background: '#074040', img: Sunken},
  PRIME: {color: '#c9b22e', background: '#40380F', img: Prime},
  BULK: {color: '#793024', background: '#401913', img: Bulk},
  CRABOID: {color: '#0068ec', background: '#001C40', img: Craboid},
  RUINED: {color: '#533fb4', background: '#1D1640', img: Ruined},
  GEM: {color: '#ec2c9e', background: '#400C2B', img: Gem},
  ORGANIC: {color: '#34a527', background: '#14400F', img: Organic}
}

function PopulationChart(props: any) {
  const { totalPopulation } = props
  const [loading, setLoading] = useState(false)
  // Filter Param
  const [filter, setFilter] = useState('ALL')
  // Area/Bar Toggle
  const [isPopulationBarChart, setIsPopulationBarChart] = useState(false)
  // Charts Data
  const [populationRowData, setPopulationRowData] = useState([])
  const [populationData, setPopulationData] = useState({
    series: [],
    labels: [],
    colors: []
  })

  useEffect(() => {
    getPopulation()
  }, [filter])

  const getPopulation = () => {
    setLoading(true)
    CrabadaAPI.getPopulation(filter)
      .then(res => {
        if(res.data) {
          setPopulationRowData(res.data)
          initPopulationData(res.data, isPopulationBarChart)
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const initPopulationData = (_data: any, _isPopulationBarChart: boolean) => {
    let _series: any = [];
    let _colors: any = [];
    Object.keys(_data).forEach((key) => {
      _series.push({
        name: key,
        type: _isPopulationBarChart ? 'bar' : 'line',
        color: crabs[key].color,
        data: formattedCrabPopulationData(_data[key])
      });
      _colors.push(crabs[key].color);
    });
    setPopulationData({...populationData, series: _series, colors: _colors})
  }

  const formattedCrabPopulationData = (data: any) => {
    let _data = [];
    for(let i = 0; i < data.length; i++) {
      let date = new Date(data[i].timestamp);
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss');
      _data.push({
        x: dateResult,
        y: data[i].totalPopulation,
        category: 'crabPopulation', 
        timeFilter: ''
      });
    }
    return _data;
  }

  // Action
  const handlePopulationBarChartToggle = (e: boolean) => {
    setLoading(true)
    setIsPopulationBarChart(e)
    initPopulationData(populationRowData, e)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }
  
  return (
    <>
      <div className='row card-header'>
        <div className='header-info'>
          <div className='title'>
            Total Population
            {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
          </div>
          <div className='value mt-2'>
            { totalPopulation ? totalPopulation.toLocaleString() : '' }
          </div>
        </div>
        <div className='header-toolbar mt-3'>
          <div className='time-filters'>
            <button className={`btn-filter ${filter === 'PURE' ? 'active' : ''}`}
              onClick={() => setFilter('PURE')}
            >Pure</button>
            <button className={`btn-filter ${filter === 'NON_PURE' ? 'active' : ''}`}
              onClick={() => setFilter('NON_PURE')}
            >Non Pure</button>
            <button className={`btn-filter ${filter === 'ALL' ? 'active' : ''}`}
              onClick={() => setFilter('ALL')}
            >All Crabs</button>
          </div>
          <img src={ChartIcon} className={`chart-icon ${isPopulationBarChart ? 'active' : ''}`} alt='-' 
            onClick={() => handlePopulationBarChartToggle(!isPopulationBarChart)}
          />
        </div>
      </div>
      <div className='card-body'>
        {
          loading ? <ComponentLoader loading={loading} />
          : <div className='chart-wrapper'>
            {
              isPopulationBarChart
              ? <BarGraph 
                data={populationData.series}
                colors={populationData.colors}
                percentage={false}
                range={filter}
              />
              : <LineGraph 
                data={populationData.series}
                colors={populationData.colors}
                percentage={false}
                range={filter}
              />
            }
          </div>
        }
      </div>
    </>
  )
}

export default PopulationChart
