import './Footer.sass'
import { useState } from 'react'
import { Modal, Box } from '@mui/material'
import { Discord, Twitch, Twitter, Youtube, Facebook } from '../../images'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 40px)',
  maxWidth: '900px',
  maxHeight: 'calc(100vh - 250px)',
  color: '#FFF',
  background: 'linear-gradient(180deg, #001431 0%, #000D1F 100%)',
  border: '2px solid #1370F6',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  zIndex: 10000,
  overflow: 'auto'
}

function Footer() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div className='footer'>
      <div className='footer-top'>
        <strong>P2E ANALYTICS</strong> &nbsp; | &nbsp; <span>Your GameFi Hub</span>
      </div>
      <div className='footer-middle'>
        <a className='social-link twitter' href='https://twitter.com/P2EAnalytics' target='_blank'>
          <img src={Twitter} className='icon-social' alt='-' />
        </a>
        <a className='social-link discord' href='https://discord.com/invite/VDkTXHNrSp' target='_blank'>
          <img src={Discord} className='icon-social' alt='-' />
        </a>
        <a className='social-link twitch' href='https://www.twitch.tv/p2eanalytics' target='_blank'>
          <img src={Twitch} className='icon-social' alt='-' />
        </a>
        <a className='social-link youtube' href='https://www.youtube.com/channel/UCpT5JPTxuAvXirIYkLxMeWQ' target='_blank'>
          <img src={Youtube} className='icon-social' alt='-' />
        </a>
        <a className='social-link facebook' href='https://www.facebook.com/p2eanalytics' target='_blank'>
          <img src={Facebook} className='icon-social' alt='-' />
        </a>
      </div>
      <div className='footer-bottom'>
        <div className='link-text' onClick={handleOpen}>This is not financial advice</div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>
            TERMS AND CONDITIONS
          </h2>
          <p>
            P2E Analytics and its members, officers, directors, owners, employees, agents, representatives, suppliers and service providers provides this website (the “Site”) for informational and entertainment purposes only. Use of and access to the Site and the information, materials, services, and other content available on or through the Site (“Content”) are subject to these terms of use and all applicable laws.
          </p>
          {/*  */}
          <h2 className='mt-4'>
            NOT INVESTMENT ADVICE
          </h2>
          <p>
            The Content is for informational and entertainment purposes only, you should not construe any such information or other material as investment, financial, or other advice. Nothing contained on our Site constitutes a solicitation, recommendation, endorsement, or offer by P2E Analytics or any third party service provider to buy or sell any securities or other financial instruments in this or in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction. All Content on this site is information and for entertainment of a general nature and does not address the circumstances of any particular individual, entity, and/or asset. Nothing in the Site constitutes professional and/or financial advice, nor does any information on the Site constitute a comprehensive or complete statement of the matters discussed. P2E Analytics is not a fiduciary by virtue of any person’s use of or access to the Site or Content. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information or other Content on the Site before making any decisions based on such information or other Content. In exchange for using the Site, you agree not to hold P2E Analytics, its affiliates or any third party service provider liable for any possible claim for damages arising from any decision you make based on information or other Content made available to you through the Site.
          </p>
        </Box>
      </Modal>
    </div>
  )
}

export default Footer
