import GameProfile from "../../components/GameProfile/GameProfile";

const Profile = (props: any) => {
    const { data, erc20IDs } = props;
    
    return (
        <GameProfile 
            data={data}
            erc20IDs={erc20IDs}
        />
    );
}

export default Profile;