import axios from 'axios'

export const getSodaCirculatingSupplyInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/627ddf314ee9b70027cabf86/economy/inflation?scanIndexId=627ddfe0f5e1d9229cd6d068";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getSodaCirculatingSupplyInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/627ddf314ee9b70027cabf86/economy/inflation/general?scanIndexId=627ddfe0f5e1d9229cd6d068";
    return axios.get(url);
}


export const getSodaNetSupplyInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/627ddf314ee9b70027cabf86/economy/inflation?scanIndexId=62bb0857af0a347e8dc56aca";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getSodaNetSupplyInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/627ddf314ee9b70027cabf86/economy/inflation/general?scanIndexId=62bb0857af0a347e8dc56aca";
    return axios.get(url);
}

const SodaTokenAPI = {
    getSodaCirculatingSupplyInflationData: getSodaCirculatingSupplyInflationData,
    getSodaCirculatingSupplyInflationGeneral: getSodaCirculatingSupplyInflationGeneral,
    getSodaNetSupplyInflationData: getSodaNetSupplyInflationData,
    getSodaNetSupplyInflationGeneral: getSodaNetSupplyInflationGeneral
}

export default SodaTokenAPI;
