import InflationContainer from "../../../components/Inflation/InflationContainer";
import SlimeTokenCharts from "./SlimeTokenCharts";

function SlimeTokenInflation() {
	return (
			<InflationContainer
				title="Slime Token"
				chartsComponent={
					<SlimeTokenCharts
							inSightsShow={true}
					/>
				}
				isUnderMaintenance={true}
			/>
	)
}

export default SlimeTokenInflation