import { Switch } from '@mui/material'
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react'
import Insights from "../Insights/Insights";
import InsightsToggleButton from "../InsightsToggleButton/InsightsToggleButton";
import MarketplaceCharts from "./MarketplaceCharts";
import MarketplaceApiType from "../../games/MarketplaceApiType";

type MarketplaceProps = {
  nftName: string,
  baseCurrencyName: string,
  baseCurrencyImgSrc: string,
  api: MarketplaceApiType,
  isDollarToggleVisible: boolean,
  isInSightsVisible: boolean,
  isTechnicalDifficultiesCallOutBoxVisible?: boolean
}

function Marketplace(props: MarketplaceProps) {

  const {
    baseCurrencyName,
    baseCurrencyImgSrc,
    api,
    nftName,
    isDollarToggleVisible,
    isInSightsVisible,
    isTechnicalDifficultiesCallOutBoxVisible = false
  } = props;

  const [iHeight, setIHeight] = useState(0)
  const [isInSightsShown, setIsInSightsShown] = useState(true)
  // Currency Switch
  const [isBaseCurrencyOfMarketplace, setIsBaseCurrencyOfMarketplace] = useState(true) //
  // Action
  const handleMarketplaceCurrencySwitch = (e: ChangeEvent<HTMLInputElement>) => {
    setIsBaseCurrencyOfMarketplace(e.target.checked);
  }

  useEffect(() => {
    initHeight()
  }, [])

  useLayoutEffect(() => {
    function handleReSize() {
      initHeight()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])

  const initHeight = () => {
    let cHeight = document.getElementById('chartSection')?.clientHeight
    setIHeight(cHeight ? cHeight : 0)
    if(window.screen.width < 1200) {
      setIsInSightsShown(false)
    }
  }

  return (
      <>
        <div className='dashboard-charts'>
          <hr className='section-divider' />
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-lg-8'>
              <div className='section-title-bar'>
                <div className='section-title'>{nftName}&nbsp;Marketplace</div>
                {isDollarToggleVisible &&
                  <div className='currency-switch ms-3'>
                    <div className="p-event-none">Dollar</div>
                    <Switch
                        checked={isBaseCurrencyOfMarketplace}
                        size="small"
                        onChange={(e) => handleMarketplaceCurrencySwitch(e)}
                    />
                    <div className="p-event-none">{baseCurrencyName}</div>
                  </div>
                }
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
              <InsightsToggleButton
                  className='ms-auto mt-2'
                  show={isInSightsShown}
                  toggleInsights={setIsInSightsShown}
              />
            </div>
          </div>
          {/*  */}
          <div className='row'>
            <div className='charts-section' id='chartSection'
                 style={{'width': isInSightsShown ? 'calc(100% - 310px)' : '100%'}}
            >
              <MarketplaceCharts
                  isBaseCurrencyOfMarketplace={isBaseCurrencyOfMarketplace}
                  isInSightsShown={isInSightsShown}
                  baseCurrencyName={baseCurrencyName}
                  baseCurrencyImgSrc={baseCurrencyImgSrc}
                  api={api}
                  nftName={nftName}
                  isTechnicalDifficultiesCallOutBoxVisible={isTechnicalDifficultiesCallOutBoxVisible}
              />
              {/*  */}
              <div className='row mx-0'>
                <a className='btn-submit-feedback mt-3'
                   href='https://discord.gg/VDkTXHNrSp'
                   target='_blank'
                >Submit Feedback</a>
              </div>
            </div>
            {isInSightsVisible &&
              <div className={`insights-section ${isInSightsShown ? 'd-block' : 'd-none'}`} style={{height: iHeight + 'px'}}>
                <Insights/>
              </div>
            }
          </div>
        </div>
      </>
  )
}

export default Marketplace
