import './Insights.sass'
import { useEffect, useRef, useState } from 'react';
import { mikoTweets, p2eTweets, tweets } from './tweets';


function Insights() {
  const [stickyClass, setStickyClass] = useState('')
  const [ insightsTop ] = useState(269)
  const insightsRef = useRef<any>(null)

  useEffect(() => {
    const onScroll = () => {
      if(window.pageYOffset > insightsTop) { // insightsRef.current.offsetTop
        setStickyClass('sticky')
      } else {
        setStickyClass('')
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  
  return (
    <div className={`insights-bar ${stickyClass}`} ref={insightsRef}>
      <div className='tweets'>
        {
          mikoTweets && mikoTweets.map((item: any, index: number) =>
            <a className={`tweet ${item.sponsored ? 'sponsored' : ''}`} href={item.link} target='_blank' key={index}>
              <div className='tweet-date'>{item.sponsored ? 'Sponsored Post' : item.timestamp}</div>
              <div className='tweet-content'>{item.content}</div>
            </a>
          )
        }
      </div>
    </div>
  )
}

export default Insights