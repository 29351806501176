import { round } from "lodash"
import moment from "moment"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ComponentLoader from "../../../components/ComponentLoader/ComponentLoader"
import { AreaGraph, BarGraph, MixedGraph } from "../../../components/Graphs"
import {ChartIcon, InfomationIcon, SLIME} from "../../../images"
import { toDateString } from "../../../utils/DateUtil"
import { EventEmitter } from "../../../utils/Events"
import SlimeAPI from "../api/SlimeAPI";
import {Tooltip, Overlay} from "react-bootstrap";

function SlimeTokenCharts(props: any) {
  const { inSightsShow, avalaunchUrl = "https://avalaunch.app/project-details?id=57", whitepaperExplanationUrl = "https://docs.snailtrail.art/security/contracts/"} = props
  const metricsWrapperRef = useRef<any>(null)
  const metricsRef = useRef<any>(null)
  const [metricsBoxRatio, setMetricsBoxRatio] = useState(1)
  const [openSideNavStatus, setOpenSideNavStatus] = useState(false)
  // 
  const [loading, setLoading] = useState(false)
  const [barToggleLoading, setBarToggleLoading] = useState(false)
  // Time Filter
  const [inflationTimeFilter, setInflationTimeFilter] = useState('ALL_TIME') // 
  // Area/Bar Toggle
  const [isInflationBarChart, setIsInflationBarChart] = useState(false)
  // Response Data
  const [circulatingInflationRowData, setCirculatingInflationRowData] = useState([])
  const [netSupplyInflationRowData, setNetSupplyInflationRowData] = useState([])

  // Charts Data
  const [inflationCirculatingMetrics, setInflationCirculatingMetrics] = useState({
    supply30DaysAgo: '',
    supply7DaysAgo: ''
  })
  const [inflationNetMetrics, setInflationNetMetrics] = useState({
    supply30DaysAgo: '',
    supply7DaysAgo: ''
  })
  const [inflationCirculatingRolling, setInflationCirculatingRolling] = useState({
    burned: '',
    inflationPercent: '',
    issuance: '',
    netSupply: ''
  })
  const [inflationNetRolling, setInflationNetRolling] = useState({
    burned: '',
    inflationPercent: '',
    issuance: '',
    netSupply: ''
  })
  const [circulatingSupplyData, setCirculatingSupplyData] = useState([])
  const [netSupplyData, setNetSupplyData] = useState([])
  const [circulatingInflationData, setCirculatingInflationData] = useState([])
  const [circulatingIssuanceData, setCirculatingIssuanceData] = useState([])
  const toolTip1 = useRef(null);
  const [showToolTip1, setShowToolTip1] = useState(false);
  const toolTip2 = useRef(null);
  const [showToolTip2, setShowToolTip2] = useState(false);
  // Action
  const handleInflationBarChartToggle = (e:boolean, isCirculatingSupply: boolean = true) => {
    setBarToggleLoading(true)
    setIsInflationBarChart(e)
    initCirculatingSupplyData(isCirculatingSupply ? circulatingInflationRowData : netSupplyInflationRowData, inflationTimeFilter, e)
    setTimeout(() => {
      setBarToggleLoading(false)
    }, 100)
  }

  // useEffect(() => {
  //   getSlimeNetSupplyInflationData()
  // }, [inflationTimeFilter])

  useEffect(() => {
    getSlimeCirculatingInflationData()
  }, [inflationTimeFilter])

  useEffect(() => {
    getSlimeCirculatingInflationGeneral()
  }, [])

  // useEffect(() => {
  //   getSlimeNetInflationGeneral()
  // }, [])

  const getSlimeNetSupplyInflationData = () => {
    setLoading(true)
    SlimeAPI.getSlimeNetSupplyInflationData(inflationTimeFilter)
    .then(res => {
      if(res.data.data) {
        setNetSupplyInflationRowData(res.data.data)
        initNetSupplyData(res.data.data, inflationTimeFilter, isInflationBarChart)
      }
      setLoading(false)
    })
    .catch(err => {
      console.log('err: ', err)
      setLoading(false)
    })
  }

  const getSlimeCirculatingInflationGeneral = () => {
    setLoading(true)
    SlimeAPI.getSlimeCirculatingSupplyInflationGeneral()
      .then(res => {
        if(res.data) {
          setInflationCirculatingMetrics({
            supply30DaysAgo: (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)) > 9999 
              ? round(res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString() 
              : (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString(),
            supply7DaysAgo: (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)) > 9999 
              ? round(res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString() 
              : (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString()
          })
          setInflationCirculatingRolling({
            burned: (res.data.rollingWindows.burned / Math.pow(10, 18)).toLocaleString(),
            inflationPercent: (res.data.rollingWindows.inflationPercent * 100).toFixed(3),
            issuance: (res.data.rollingWindows.issuance / Math.pow(10, 18)).toLocaleString(),
            netSupply: (res.data.rollingWindows.netSupply / Math.pow(10, 18)).toLocaleString()
          })
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const toolTip = (
      <Tooltip id="overlay-example" {...props}>
        <p>
          Net circulating supply is determined from SLIME rewards distributions, distributions from <a href={avalaunchUrl} target="_blank">Avalaunch</a>, distributions from the game vaults, distributions from the airdrop, distributions from the private round, and distributions to team/advisory. For more info please <a href={whitepaperExplanationUrl} target="_blank">view</a> the 'contracts' section of the whitepaper
        </p>
      </Tooltip>
  )

  const getSlimeNetInflationGeneral = () => {
    setLoading(true)
    SlimeAPI.getSlimeNetSupplyInflationGeneral()
      .then(res => {
        if(res.data) {
          setInflationNetMetrics({
            supply30DaysAgo: (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)) > 9999
              ? round(res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString()
              : (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString(),
            supply7DaysAgo: (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)) > 9999
              ? round(res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString()
              : (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString()
          })
          setInflationNetRolling({
            burned: (res.data.rollingWindows.burned / Math.pow(10, 18)).toLocaleString(),
            inflationPercent: (res.data.rollingWindows.inflationPercent * 100).toFixed(3),
            issuance: (res.data.rollingWindows.issuance / Math.pow(10, 18)).toLocaleString(),
            netSupply: (res.data.rollingWindows.netSupply / Math.pow(10, 18)).toLocaleString()
          })
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const getSlimeCirculatingInflationData = () => {
    setLoading(true)
    SlimeAPI.getSlimeCirculatingSupplyInflationData(inflationTimeFilter)
      .then(res => {
        if(res.data.data) {
          setCirculatingInflationRowData(res.data.data)
          initCirculatingSupplyData(res.data.data, inflationTimeFilter, isInflationBarChart)
          initCirculatingSupplyInflationData(res.data.data, inflationTimeFilter)
          initIssuanceData(res.data.data, inflationTimeFilter)
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const initCirculatingSupplyData = (_data: any, _timeFilter: string, _isInflationBarChart: boolean) => {
    let netSupplyArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      netSupplyArr.push({ 
        x: dateResult, 
        y: _data[i].netSupply / Math.pow(10, 18),
        category: 'tus', 
        timeFilter: _timeFilter
      })
    }

    let _netSupplyData: any = []
    _netSupplyData.push({name: 'Net Circulating Supply', type: (_isInflationBarChart ? 'bar' : 'area'), data: netSupplyArr})
    setCirculatingSupplyData(_netSupplyData)
  }

  const initNetSupplyData = (_data: any, _timeFilter: string, _isInflationBarChart: boolean) => {
    let netSupplyArr: any = []

    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      netSupplyArr.push({
        x: dateResult,
        y: _data[i].netSupply / Math.pow(10, 18),
        category: 'tus',
        timeFilter: _timeFilter
      })
    }

    let _netSupplyData: any = []
    _netSupplyData.push({name: 'Net Supply', type: (_isInflationBarChart ? 'bar' : 'area'), data: netSupplyArr})
    setNetSupplyData(_netSupplyData)
  }

  const initCirculatingSupplyInflationData = (_data: any, _timeFilter: string) => {
    let issuanceArr: any = []
    let breedingArr: any = []
    let inflationArr: any = []

    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      // if(dateResult >= moment.utc('2022-03-01 00:00:00').format('YYYY-MM-DD HH:mm:ss')) {
        // 
        issuanceArr.push({
          x: dateResult,
          y: _data[i].mintPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });
        // 
        breedingArr.push({
          x: dateResult,
          y: _data[i].burnPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });
        // 
        inflationArr.push({
          x: dateResult,
          y: _data[i].inflationPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });      
      // }
    }
    
    let _inflationData: any = []
    _inflationData.push({name: 'Issuance', type: 'line', color: '#1370F6', data: issuanceArr})
    _inflationData.push({name: 'Breed Fee', type: 'line', color: '#B02170', data: breedingArr})
    _inflationData.push({name: '+/- Inflation', type: 'bar', color: '#00000000', data: inflationArr})
    setCirculatingInflationData(_inflationData)
  }

  const initIssuanceData = (_data: any, _timeFilter: string) => {
    let _mintsArr: any = []
    let _burnsArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      let mints = _data[i].timeBucketMints / Math.pow(10, 18);
      _mintsArr.push({
        x: dateResult, 
        y: mints, 
        category: 'tus', 
        timeFilter: _timeFilter
      })

      const burns = _data[i].timeBucketBurns / Math.pow(10, 18);
      _burnsArr.push({
        x: dateResult, 
        y: burns, 
        category: 'tus', 
        timeFilter: _timeFilter
      })
    }

    let _issuanceBreedData: any = []
    _issuanceBreedData.push({name: 'mints', data: _mintsArr})
    _issuanceBreedData.push({name: 'burns', data: _burnsArr})
    setCirculatingIssuanceData(_issuanceBreedData)
  }

  // LayoutEffect
  useLayoutEffect(() => {
    function handleReSize() {
      scaleMetricsBox()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [inSightsShow, openSideNavStatus])

  const scaleMetricsBox = () => {
    if(metricsWrapperRef.current.clientWidth < metricsRef.current.clientWidth) {
      setMetricsBoxRatio(Math.round(metricsWrapperRef.current.clientWidth * 10 / metricsRef.current.clientWidth) / 10 - 0.1)
    } else {
      setMetricsBoxRatio(1)
    }
  }

  EventEmitter.subscribe('openSideNav', (event: any) => {
    setOpenSideNavStatus(event)
  })
	
	return (
		<>
      {/* Net Supply Chart */}
			{/*<div className='row'>*/}
      {/*  <div className='col-12'>*/}
      {/*    <div className='chart-card'>*/}
      {/*      <div className='row'>*/}
      {/*        <div className='col-12 col-sm-12 col-md-12 col-lg-8'>*/}
      {/*          <div className='row card-header'>*/}
      {/*            <div className='header-info'>*/}
      {/*              <div className='title'>*/}
      {/*                Net Supply*/}
      {/*                /!* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> *!/*/}
      {/*              </div>*/}
      {/*              <div className='value mt-2'>*/}
      {/*                <div className='token-value'>*/}
      {/*                  <img src={SLIME} className='token-icon me-1' alt='-' />*/}
      {/*                  { inflationNetRolling.netSupply }*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className='header-toolbar mt-3'>*/}
      {/*              <div className='time-filters'>*/}
      {/*                <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}*/}
      {/*                  onClick={() => setInflationTimeFilter('SEVEN')}*/}
      {/*                >7 Days</button>*/}
      {/*                <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}*/}
      {/*                  onClick={() => setInflationTimeFilter('THIRTY')}*/}
      {/*                >30 Days</button>*/}
      {/*                <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}*/}
      {/*                  onClick={() => setInflationTimeFilter('ALL_TIME')}*/}
      {/*                >All Time</button>*/}
      {/*              </div>*/}
      {/*              <img src={ChartIcon} className={`chart-icon ${isInflationBarChart ? 'active' : ''}`} alt='-' */}
      {/*                onClick={() => handleInflationBarChartToggle(!isInflationBarChart, false)}*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className='card-body'>*/}
      {/*            {*/}
      {/*              (barToggleLoading || loading) ? <ComponentLoader loading={barToggleLoading || loading} />*/}
      {/*              : <div className='chart-wrapper'>*/}
      {/*                {*/}
      {/*                  isInflationBarChart*/}
      {/*                  ? <BarGraph */}
      {/*                    data={netSupplyData}*/}
      {/*                    colors={['#B02170']}*/}
      {/*                    percentage={false}*/}
      {/*                    range={inflationTimeFilter}*/}
      {/*                    gradient={true}*/}
      {/*                  />*/}
      {/*                  : <AreaGraph */}
      {/*                    data={netSupplyData}*/}
      {/*                    colors={['#B02170']}*/}
      {/*                    percentage={false}*/}
      {/*                    range={inflationTimeFilter}*/}
      {/*                    gradient={true}*/}
      {/*                  />*/}
      {/*                }*/}
      {/*              </div>*/}
      {/*            }*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className='col-12 col-sm-12 col-md-12 col-lg-4'>*/}
      {/*          <div className='metrics-wrapper' ref={metricsWrapperRef}>*/}
      {/*            <div className='chart-sales m-auto' ref={metricsRef}*/}
      {/*              style={{transform: `scale(${metricsBoxRatio})`}}*/}
      {/*            >*/}
      {/*              {*/}
      {/*                (inflationNetMetrics && inflationNetMetrics.supply7DaysAgo) ?*/}
      {/*                <div className='all-time-sales my-2'>*/}
      {/*                  <div className='sales-title'>Net Supply - 7 days ago</div>*/}
      {/*                  <div className='sales-value'>*/}
      {/*                    <img src={SLIME} className='token-icon' alt='-' />*/}
      {/*                    {inflationNetMetrics.supply7DaysAgo}*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*                : ''*/}
      {/*              }*/}
      {/*              {*/}
      {/*                (inflationNetMetrics && inflationNetMetrics.supply30DaysAgo) ?*/}
      {/*                <div className='all-time-sales my-2'>*/}
      {/*                  <div className='sales-title'>Net Supply - 30 days ago</div>*/}
      {/*                  <div className='sales-value'>*/}
      {/*                    <img src={SLIME} className='token-icon' alt='-' />*/}
      {/*                    {inflationNetMetrics.supply30DaysAgo}*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*                : ''*/}
      {/*              }*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Net Circulating Supply Chart */}
      <div className='row'>
        <div className='col-12'>
          <div className='chart-card'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className='row card-header'>
                  <div className='header-info'>
                    <div className='title'
                         onMouseOver={() => {setShowToolTip1(true)}}
                         onMouseOut={() => {setTimeout(() => { setShowToolTip1(false) }, 3000) } }
                    >
                      Net Circulating Supply
                      <img src={InfomationIcon} className='info-icon ms-1' alt='-' ref={toolTip1} />
                      <Overlay target={toolTip1.current} show={showToolTip1} placement="right">
                        {toolTip}
                      </Overlay>
                    </div>
                    <div className='value mt-2'>
                      <div className='token-value'>
                        <img src={SLIME} className='token-icon me-1' alt='-' />
                        { inflationCirculatingRolling.netSupply }
                      </div>
                    </div>
                  </div>
                  <div className='header-toolbar mt-3'>
                    <div className='time-filters'>
                      <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                              onClick={() => setInflationTimeFilter('SEVEN')}
                      >7 Days</button>
                      <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                              onClick={() => setInflationTimeFilter('THIRTY')}
                      >30 Days</button>
                      <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                              onClick={() => setInflationTimeFilter('ALL_TIME')}
                      >All Time</button>
                    </div>
                    <img src={ChartIcon} className={`chart-icon ${isInflationBarChart ? 'active' : ''}`} alt='-'
                         onClick={() => handleInflationBarChartToggle(!isInflationBarChart)}
                    />
                  </div>
                </div>
                <div className='card-body'>
                  {
                    (barToggleLoading || loading) ? <ComponentLoader loading={barToggleLoading || loading} />
                        : <div className='chart-wrapper'>
                          {
                            isInflationBarChart
                                ? <BarGraph
                                    data={circulatingSupplyData}
                                    colors={['#B02170']}
                                    percentage={false}
                                    range={inflationTimeFilter}
                                    gradient={true}
                                />
                                : <AreaGraph
                                    data={circulatingSupplyData}
                                    colors={['#B02170']}
                                    percentage={false}
                                    range={inflationTimeFilter}
                                    gradient={true}
                                />
                          }
                        </div>
                  }
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                <div className='metrics-wrapper' ref={metricsWrapperRef}>
                  <div className='chart-sales m-auto' ref={metricsRef}
                       style={{transform: `scale(${metricsBoxRatio})`}}
                  >
                    {
                      (inflationCirculatingMetrics && inflationCirculatingMetrics.supply7DaysAgo) ?
                          <div className='all-time-sales my-2'>
                            <div className='sales-title'>Net Circulating Supply - 7 days ago</div>
                            <div className='sales-value'>
                              <img src={SLIME} className='token-icon' alt='-' />
                              {inflationCirculatingMetrics.supply7DaysAgo}
                            </div>
                          </div>
                          : ''
                    }
                    {
                      (inflationCirculatingMetrics && inflationCirculatingMetrics.supply30DaysAgo) ?
                          <div className='all-time-sales my-2'>
                            <div className='sales-title'>Net Circulating Supply - 30 days ago</div>
                            <div className='sales-value'>
                              <img src={SLIME} className='token-icon' alt='-' />
                              {inflationCirculatingMetrics.supply30DaysAgo}
                            </div>
                          </div>
                          : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Net Circulating Supply Issuance */}
      <div className='row sm'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info'>
                <div className='title'
                     onMouseOver={() => {setShowToolTip2(true)}}
                     onMouseOut={() => {setTimeout(() => { setShowToolTip2(false) }, 3000) } }
                >
                  Net Circulating Inflation
                   <img src={InfomationIcon} className='info-icon ms-1' alt='-' ref={toolTip2} />
                    <Overlay target={toolTip2.current} show={showToolTip2} placement="right">
                      {toolTip}
                    </Overlay>
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={SLIME} className='token-icon me-1' alt='-' />
                    { inflationCirculatingRolling.inflationPercent }%
                  </div>
                </div>
              </div>
            </div>
            <div className='row card-header'>
              <div className='custom-legend mt-1'>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#1370F6'}}></span>Issuance
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#B02170'}}></span>Breed Fee
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#008F20'}}></span>
                  <span className='dot ms-0' style={{background: '#8F0004'}}></span>
                  +/- Inflation
                </div>
              </div>
              <div className='header-toolbar'>
                <div className='time-filters'>
                  <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <MixedGraph 
                    data={circulatingInflationData}
                    colors={['#1370F6', '#B02170', '#008F20']}
                    percentage={true}
                    range={inflationTimeFilter}
                    gradient={false}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info mt-2'>
                <div className='title' style={{color: '#1370F6'}}>
                  Issuance
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={SLIME} className='token-icon me-1' alt='-' />
                    { inflationCirculatingRolling.issuance }
                  </div>
                </div>
              </div>
              <div className='header-info mt-2'>
                <div className='title' style={{color: '#B02170'}}>
                  Breed Fee
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={SLIME} className='token-icon me-1' alt='-' />
                    { inflationCirculatingRolling.burned }
                  </div>
                </div>
              </div>
            </div>
            <div className='row card-header'>
              <div className='custom-legend mt-1'>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#1370F6'}}></span>Mints
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#B02170'}}></span>Breed Fee
                </div>
              </div>
              <div className='header-toolbar'>
                <div className='time-filters'>
                  <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <BarGraph 
                    data={circulatingIssuanceData}
                    colors={['#004EBF', '#B02170']}
                    // colors={['#B02170']}
                    percentage={false}
                    range={inflationTimeFilter}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
		</>
	)
}

export default SlimeTokenCharts