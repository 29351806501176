import { useEffect, useLayoutEffect, useState } from 'react'
import { Organic, Gem, Bulk, Craboid, Prime, Ruined, Sunken, Surge } from '../../../images'
import CrabadaAPI, {getPopulationClassPurityDistribution} from '../CrabadaAPI'
import './Population.sass'
import Population from './Population'

const crabs: any = {
  SURGE: {color: '#fc252b', background: '#40090B', img: Surge},
  SUNKEN: {color: '#108c8c', background: '#074040', img: Sunken},
  PRIME: {color: '#c9b22e', background: '#40380F', img: Prime},
  BULK: {color: '#793024', background: '#401913', img: Bulk},
  CRABOID: {color: '#0068ec', background: '#001C40', img: Craboid},
  RUINED: {color: '#533fb4', background: '#1D1640', img: Ruined},
  GEM: {color: '#ec2c9e', background: '#400C2B', img: Gem},
  ORGANIC: {color: '#34a527', background: '#14400F', img: Organic}
}

// const purityColor: any = {
//   PURE: '#1888FE',
//   NON_PURE: '#00224E'
// }

function PopulationContainer() {
  // Page
  const [width, setWidth] = useState(window.screen.width)
  // Charts Data
  const [populationDistribution, setPopulationDistribution] = useState([])
  const [totalPopulation, setTotalPopulation] = useState(0)
  // 
  const [totalPurityData, setTotalPurityData] = useState({
    pure: '',
    non_pure: '',
    total: ''
  })
  const [classPurityDistribution, setClassPurityDistribution] = useState({})

  useEffect(() => {
    getPopulationDistribution()
    getPopulationPurityDistribution()
    getPopulationClassPurityDistribution()
  }, [])

  const getPopulationDistribution = () => {
    CrabadaAPI.getPopulationDistribution()
      .then(res => {
        if(res.data) {
          let _populationDistributionData: any = []
          let _totalPopulation = 0
          Object.keys(res.data).forEach((key) => {
            _populationDistributionData.push({
              name: key,
              value: res.data[key],
              color: crabs[key].background,
              img: crabs[key].img
            })
            _totalPopulation += res.data[key]
          })
          setPopulationDistribution(_populationDistributionData)
          setTotalPopulation(_totalPopulation)
        }
      })
      .catch(err => {
        console.log('err: ', err);
      })
  }

  const getPopulationPurityDistribution = () => {
    CrabadaAPI.getPopulationPurityDistribution()
      .then(res => {
        if(res.data) {
          setTotalPurityData({
            ...totalPurityData,
            pure: res.data.PURE.toLocaleString(),
            non_pure: res.data.NON_PURE.toLocaleString(),
            total: (res.data.PURE + res.data.NON_PURE).toLocaleString()
          })
        }
      })
      .catch(err => {
        console.log('err: ', err);
      })
  }

  const getPopulationClassPurityDistribution = () => {
    CrabadaAPI.getPopulationClassPurityDistribution()
    .then(res => {
      if(res.data) {
        setClassPurityDistribution(res.data)
      }
    })
    .catch(err => {
      console.log('getPopulationPurityDistribution err: ', err);
    })
  }

  useLayoutEffect(() => {
    function handleReSize() {
      setWidth(window.screen.width)
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])
  
  return (
    <>
      <Population 
        populationDistribution={populationDistribution}
        totalPopulation={totalPopulation}
        totalPurityData={totalPurityData}
        classPurityDistribution={classPurityDistribution}
      />
    </>
  )
}

export default PopulationContainer