import { useEffect, useLayoutEffect, useState } from 'react'
import InsightsToggleButton from "../InsightsToggleButton/InsightsToggleButton";
import MaintenanceCard from "../MaintenanceCard/MaintenanceCard";
import Insights from "../Insights/Insights";

type ComingSoonProps = {
  title: string
}

function ComingSoon(props : ComingSoonProps) {

  const {title} = props

  const [iHeight, setIHeight] = useState(0)
  const [show, setShow] = useState(false)

  useEffect(() => {
    initHeight()
  }, [])

  useLayoutEffect(() => {
    function handleReSize() {
      initHeight()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])

  const initHeight = () => {
    let cHeight = document.getElementById('chartSection')?.clientHeight
    setIHeight(cHeight ? cHeight : 0)
    if(window.screen.width < 1200) {
      setShow(false)
    }
  }

  return (
      <div className='dashboard-charts crab-prices'>
        <hr className='section-divider' />
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-8'>
            <div className='section-title-bar'>
              <div className='section-title'>{title}</div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
            <InsightsToggleButton
                className='ms-auto mt-2'
                show={show}
                toggleInsights={setShow}
            />
          </div>
        </div>
        {/*  */}
        <div className='row'>
          <div className='charts-section' id='chartSection'
               style={{'width': show ? 'calc(100% - 310px)' : '100%'}}
          >
            <MaintenanceCard message='Coming Soon' />
            {/*  */}
            <div className='row mx-0'>
              <a className='btn-submit-feedback mt-3'
                 href='https://discord.gg/VDkTXHNrSp'
                 target='_blank'
              >Submit Feedback</a>
            </div>
          </div>
          <div className={`insights-section ${show ? 'd-block' : 'd-none'}`} style={{height: iHeight + 'px'}}>
            <Insights />
          </div>
        </div>
      </div>
  )
}

export default ComingSoon
