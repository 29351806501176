import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReadingProgressBar from '../../components/ReadingProgressBar/ReadingProgressBar';
import LatestVideos from './LatestVideos';
import './WordPress.sass';
import { StreamyardVideos } from './LatestVideos';
import { StreamyardPNG, TwitchPNG, YoutubePNG } from '../../images';
import {Helmet} from "react-helmet";

function ResourcesVideo() {
  const { id } = useParams();
  const [currentVideo, setCurrentVideo] = useState({
    title: '',
    date: '',
    embedId: '',
    streamyard: '',
    twitch: '',
    youtube: ''
  });

  useEffect(() => {
    let _video = StreamyardVideos.filter(function(ele: any) {
      return ele.embedId === id
    })[0];
    setCurrentVideo(_video);
  }, [id]);

  const videoUrl = `https://streamyard.com/e/${id}`

  return (
    <div id='page' className='resources detail pb-5'>
      <Helmet>
        <meta name="twitter:card" content="player" />
        <meta name="twitter:player" content={videoUrl} />
      </Helmet>
      <ReadingProgressBar />
      <div className='container'>
        {/* <div className='blog-img-container mt-5'>

        </div> */}
        {/*  */}
        <div className='row mt-5'>
          <div className='col-12 col-sm-12 col-md-8 video-section'>
            <div style={{width:'100%',height:'0px',position:'relative',paddingBottom:'56.25%'}} className='video-wrapper'>
              <iframe src={videoUrl} width="100%" height="100%" frameBorder="0" allowFullScreen style={{width:'100%',height:'100%',position:'absolute',left:'0px',top:'0px',overflow:'hidden'}}></iframe>
            </div>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-7'>
                <div className='video-date mt-3'>{currentVideo.date}</div>
                <div className='video-title mt-3'>{currentVideo.title}</div>
              </div>
              <div className='col-12 col-sm-12 col-md-5'>
                <div className='channel-links mt-3'>
                  {
                    currentVideo.streamyard && 
                    <a className='channel-link' href={currentVideo.streamyard} target='_blank'>
                      <img src={StreamyardPNG} className='channel-icon' alt='' />
                    </a>
                  }
                  {
                    currentVideo.twitch && 
                    <a className='channel-link' href={currentVideo.twitch} target='_blank'>
                      <img src={TwitchPNG} className='channel-icon' alt='' />
                    </a>
                  }
                  {
                    currentVideo.youtube && 
                    <a className='channel-link' href={currentVideo.youtube} target='_blank'>
                      <img src={YoutubePNG} className='channel-icon' alt='' />
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-4'>
            <LatestVideos />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourcesVideo