import './ComponentLoader.sass'
import { css } from '@emotion/react'
import BeatLoader from 'react-spinners/BeatLoader'

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin: 0 auto;
  border-color: red;
`

const color = '#1370F6'

function ComponentLoader(props: any) {
  const { loading } = props
  
  return (
    <div className={`p2e-component-loader ${loading ? '' : 'd-none'}`}>
      {/* <div className='overlay'></div> */}
      <BeatLoader color={color} loading={loading} css={override} size={15} />
    </div>
  )
}

export default ComponentLoader