import './CrabPrices.sass'
// import { ComingSoonCrabPrice } from "../../../images"
import InsightsToggleButton from '../../../components/InsightsToggleButton/InsightsToggleButton'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Insights from '../../../components/Insights/Insights'
import CrabadaAPI from '../CrabadaAPI'
import moment from 'moment'
import CrabPricesChart from './CrabPricesChart'
import { toDateString } from '../../../utils/DateUtil'
import Slider from '@mui/material/Slider'
import { Bulk, ComingSoonCrabPrice, Craboid, Gem, Organic, Prime, Ruined, Sunken, Surge } from "../../../images"
import { EventEmitter } from '../../../utils/Events'

const chartColors = {
  avgPrice: '#fc252b',
  higherPrice: '#108c8c',
  lowerPrice: '#c9b22e',
  totalPrice: '#793024',
  totalSales: '#0068ec'
};

const crabs: any = {
  SURGE: {color: '#fc252b', background: '#40090B', img: Surge},
  SUNKEN: {color: '#108c8c', background: '#074040', img: Sunken},
  PRIME: {color: '#c9b22e', background: '#40380F', img: Prime},
  BULK: {color: '#793024', background: '#401913', img: Bulk},
  CRABOID: {color: '#0068ec', background: '#001C40', img: Craboid},
  RUINED: {color: '#533fb4', background: '#1D1640', img: Ruined},
  GEM: {color: '#ec2c9e', background: '#400C2B', img: Gem},
  ORGANIC: {color: '#34a527', background: '#14400F', img: Organic}
}

function CrabPrices() {
  const [iHeight, setIHeight] = useState(0)
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState({
    from: moment().subtract(5, "days").format('YYYY-MM-DD') + 'T00:00:00.000000Z',
    breedCount: 0,
    legend: 0,
    purity: 0,
    stage: [],
    crabType: [],
    crabClass: [],
    dnaParts: []
  });
  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState({
    series: [],
    labels: [],
    colors: []
  });
  const [metricsData, setMetricsData] = useState({
    totalSales: '',
    totalPrice: ''
  });
  // const [crabClassData, setCrabClassData] = useState<any>({
  //   SURGE: {color: '#fc252b', background: '#40090B', img: Surge, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   SUNKEN: {color: '#108c8c', background: '#074040', img: Sunken, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   PRIME: {color: '#c9b22e', background: '#40380F', img: Prime, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   BULK: {color: '#793024', background: '#401913', img: Bulk, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   CRABOID: {color: '#0068ec', background: '#001C40', img: Craboid, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   RUINED: {color: '#533fb4', background: '#1D1640', img: Ruined, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   GEM: {color: '#ec2c9e', background: '#400C2B', img: Gem, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0},
  //   ORGANIC: {color: '#34a527', background: '#14400F', img: Organic, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0}
  // });
  const [SURGE, setSURGE] = useState({color: '#fc252b', background: '#40090B', img: Surge, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [SUNKEN, setSUNKEN] = useState({color: '#108c8c', background: '#074040', img: Sunken, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [PRIME, setPRIME] = useState({color: '#c9b22e', background: '#40380F', img: Prime, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [BULK, setBULK] = useState({color: '#793024', background: '#401913', img: Bulk, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [CRABOID, setCRABOID] = useState({color: '#0068ec', background: '#001C40', img: Craboid, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [RUINED, setRUINED] = useState({color: '#533fb4', background: '#1D1640', img: Ruined, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [GEM, setGEM] = useState({color: '#ec2c9e', background: '#400C2B', img: Gem, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  const [ORGANIC, setORGANIC] = useState({color: '#34a527', background: '#14400F', img: Organic, avgPrice: 0, higherPrice: 0, lowerPrice: 0, totalPrice: 0, totalSales: 0});
  // 
  const metricsWrapperRef = useRef<any>(null)
  const metricsRef = useRef<any>(null)
  const [metricsBoxRatio, setMetricsBoxRatio] = useState(1)
  const [openSideNavStatus, setOpenSideNavStatus] = useState(false)

  useEffect(() => {
    initHeight()
    getCrabPrices();
    getCrabPricesCrabClass();
  }, [])

  // 
  useLayoutEffect(() => {
    function handleReSize() {
      initHeight()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, []);

  const initHeight = () => {
    let cHeight = document.getElementById('chartSection')?.clientHeight
    setIHeight(cHeight ? cHeight : 0)
    if(window.screen.width < 1200) {
      setShow(false)
    }
  }

  // LayoutEffect
  useLayoutEffect(() => {
    function handleReSize() {
      scaleMetricsBox()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [show, openSideNavStatus]);

  const scaleMetricsBox = () => {
    if(metricsWrapperRef.current.clientWidth < metricsRef.current.clientWidth) {
      setMetricsBoxRatio(Math.round(metricsWrapperRef.current.clientWidth * 10 / metricsRef.current.clientWidth) / 10 - 0.1)
    } else {
      setMetricsBoxRatio(1)
    }
  }

  EventEmitter.subscribe('openSideNav', (event: any) => {
    setOpenSideNavStatus(event)
  });

  const getCrabPrices = () => {
    let params = formattedParams(filter, '');
    CrabadaAPI.getCrabPrices(params)
      .then((res: any) => {
        if(res.status === 200 && res.data) {
          setRowData(res.data);
          initCrabPrices(res.data);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  }

  const formattedParams = (filter: any, key: string) => {
    let _breedCount = [];
    if(filter.breedCount > 0) {
      for (let i = 0; i <= filter.breedCount; i++) {
        _breedCount.push(i);
      }
    }
    let _legend = [];
    if(filter.legend > 0) {
      for (let j = 0; j <= filter.legend; j++) {
        _legend.push(j);
      }
    }
    let _purity = [];
    if(filter.purity > 0) {
      for (let k = 0; k <= filter.purity; k++) {
        _purity.push(k);
      }
    }
    let _crabClass = [];
    if(key) {
      _crabClass.push(key);
    }
    let params = {
      ...filter,
      breedCount: _breedCount,
      legend: _legend,
      purity: _purity,
      crabClass: _crabClass
    }
    return params;
  }
  
  const getCrabPricesCrabClass = () => {
    Object.keys(crabs).forEach((key) => {
      let params = formattedParams(filter, key);
      CrabadaAPI.getCrabPrices(params)
        .then((res: any) => {
          if(res.status === 200 && res.data) {
            switch(key) {
              case 'SURGE':
                setSURGE({...SURGE, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'SUNKEN':
                setSUNKEN({...SUNKEN, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'PRIME':
                setPRIME({...PRIME, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'BULK':
                setBULK({...BULK, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'CRABOID':
                setCRABOID({...CRABOID, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'RUINED':
                setRUINED({...RUINED, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'GEM':
                setGEM({...GEM, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
              case 'ORGANIC':
                setORGANIC({...ORGANIC, avgPrice: calcSum(res.data, 'avgPrice'), higherPrice: calcSum(res.data, 'higherPrice'), lowerPrice: calcSum(res.data, 'lowerPrice'), totalPrice: calcSum(res.data, 'totalPrice'), totalSales: calcSum(res.data, 'totalSales')});
                break;
            }
          }
        })
        .catch((err: any) => {
          console.log('err: ', err);
        });
    });
  }

  const calcSum = (data: any, key: string) => {
    let avgPriceSum = 0;
    let higherPriceSum = 0;
    let lowerPriceSum = 0;
    let totalPriceSum = 0;
    let totalSalesSum = 0;
    for(let i = 0; i < data.length; i++) {
      avgPriceSum += data[i].avgPrice;
      higherPriceSum += data[i].higherPrice;
      lowerPriceSum += data[i].lowerPrice;
      totalPriceSum += data[i].totalPrice;
      totalSalesSum += data[i].totalSales;
    }
    let result: any = {
      avgPrice: avgPriceSum,
      higherPrice: higherPriceSum,
      lowerPrice: lowerPriceSum,
      totalPrice: totalPriceSum,
      totalSales: totalSalesSum
    };
    return key ? result[key] : result
  }

  const initCrabPrices = (_data: any) => {
    let _series: any = [];
    let _colors: any = [];
    let _totalSales = 0;
    let _totalPrice = 0;
    for(let i = 0; i < _data.length; i++) {
      _totalSales += _data[i].totalSales;
      _totalPrice += _data[i].totalPrice;
    }
    setMetricsData({
      totalSales: _totalSales ? _totalSales.toLocaleString() : '',
      totalPrice: _totalPrice ? _totalPrice.toLocaleString() : ''
    });
    // avgPrice
    _series.push({
      name: 'Avg Price',
      type: 'line',
      color: chartColors.avgPrice,
      data: formattedCrabPricesData(_data, "avgPrice")
    });
    _colors.push(chartColors.avgPrice);
    // higherPrice
    _series.push({
      name: 'Higher Price',
      type: 'line',
      color: chartColors.higherPrice,
      data: formattedCrabPricesData(_data, "higherPrice")
    });
    _colors.push(chartColors.higherPrice);
    // lowerPrice
    _series.push({
      name: 'Lower Price',
      type: 'line',
      color: chartColors.lowerPrice,
      data: formattedCrabPricesData(_data, "lowerPrice")
    });
    _colors.push(chartColors.lowerPrice);
    // totalPrice
    _series.push({
      name: 'Total Price',
      type: 'line',
      color: chartColors.totalPrice,
      data: formattedCrabPricesData(_data, "totalPrice")
    });
    _colors.push(chartColors.totalPrice);
    // totalSales
    _series.push({
      name: 'Total Sales',
      type: 'line',
      color: chartColors.totalSales,
      data: formattedCrabPricesData(_data, "totalSales")
    });
    _colors.push(chartColors.totalSales);
    setChartData({...chartData, series: _series})
  }

  const formattedCrabPricesData = (data: any, key: string) => {
    let _data = [];
    for(let i = 0; i < data.length; i++) {
      let date = new Date(data[i].bucketDate);
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss');
      _data.push({
        x: dateResult,
        y: data[i][key],
        category: '', 
        timeFilter: ''
      });
    }
    return _data;
  }

  const applyFilter = () => {
    getCrabPrices();
  }

  return (
    <div className='dashboard-charts crab-prices'>
      <hr className='section-divider' />
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-6 col-lg-8'>
          <div className='section-title-bar'>
            <div className='section-title'>Crab Prices</div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
          <InsightsToggleButton className='ms-auto mt-2' 
            show={show}
            toggleInsights={setShow}
          />
        </div>
      </div>
      {/*  */}
      <div className='row'>
        <div className='charts-section' id='chartSection'
          style={{'width': show ? 'calc(100% - 310px)' : '100%'}}
        >
          <img src={ComingSoonCrabPrice} className='w-100' alt='' />
          <div className='col-12 d-none'>
            <div className='chart-filter'>
              <div className='row'>
                <div className='col-3'>
                  <div className='filter-group'>
                    <label>Breed Count</label>
                    <div className='form-slider'>
                      <Slider
                        aria-label="Breed Count"
                        value={filter.breedCount}
                        step={1}
                        marks
                        min={0}
                        max={3}
                        valueLabelDisplay="auto"
                        name="breedCount"
                        onChange={(e: any) => setFilter({...filter, breedCount: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='filter-group'>
                    <label>Legend</label>
                    <div className='form-slider'>
                      <Slider
                        aria-label="Legend"
                        value={filter.legend}
                        step={1}
                        marks
                        min={0}
                        max={6}
                        valueLabelDisplay="auto"
                        name="legend"
                        onChange={(e: any) => setFilter({...filter, legend: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='filter-group'>
                    <label>Purity</label>
                    <div className='form-slider'>
                      <Slider
                        aria-label="Purity"
                        value={filter.purity}
                        step={1}
                        marks
                        min={0}
                        max={6}
                        valueLabelDisplay="auto"
                        name="purity"
                        onChange={(e: any) => setFilter({...filter, purity: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='filter-buttons'>
                    <button className='btn-apply-filter'
                      onClick={() => applyFilter()}
                    >Apply Filter</button>
                    <button className='btn-more-filters ms-3'>More Filters</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 mt-3 d-none'>
            <div className='chart-card'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                  <CrabPricesChart chartData={chartData} metricsData={metricsData} />
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                  <div className='metrics-wrapper' ref={metricsWrapperRef}>
                    <div className='chart-sales m-auto' ref={metricsRef}
                      style={{transform: `scale(${metricsBoxRatio})`}}
                    >
                      <div className='sales-items'>
                        <div className='token-item' style={{background: SURGE.background}}>
                          <img src={SURGE.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {SURGE.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: SUNKEN.background}}>
                          <img src={SUNKEN.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {SUNKEN.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: PRIME.background}}>
                          <img src={PRIME.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {PRIME.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: BULK.background}}>
                          <img src={BULK.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {BULK.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: CRABOID.background}}>
                          <img src={CRABOID.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {CRABOID.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: RUINED.background}}>
                          <img src={RUINED.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {RUINED.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: GEM.background}}>
                          <img src={GEM.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {GEM.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                        <div className='token-item' style={{background: ORGANIC.background}}>
                          <img src={ORGANIC.img} className='token-icon' alt='-' />
                          <div className='token-value'>
                            {ORGANIC.totalSales}
                            {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                          </div>
                        </div>
                      {
                        // crabClassData && Object.keys(crabClassData).map((key, index) => {
                        //   return (
                        //     <div className='token-item' key={index} style={{background: crabClassData[key].background}}>
                        //       <img src={crabClassData[key].img} className='token-icon' alt='-' />
                        //       <div className='token-value'>
                        //         {crabClassData[key].totalPrice}
                        //         {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                        //       </div>
                        //     </div>
                        //   );
                        // })
                        // crabClassData && crabClassData.map((item: any, index: number) =>
                        //   <div className='token-item' key={index} style={{background: item.color}}>
                        //     <img src={item.img} className='token-icon' alt='-' />
                        //     <div className='token-value'>
                        //       {item.totalPrice}
                        //       {/* <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span> */}
                        //     </div>
                        //   </div>
                        // )
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='row mx-0'>
            <a className='btn-submit-feedback mt-3'
              href='https://discord.gg/VDkTXHNrSp'
              target='_blank'
            >Submit Feedback</a>
          </div>
        </div>
        <div className={`insights-section ${show ? 'd-block' : 'd-none'}`} style={{height: iHeight + 'px'}}>
          <Insights />
        </div>
      </div>
    </div>
  )
}

export default CrabPrices