import { useState } from "react"
import ComponentLoader from "../../../components/ComponentLoader/ComponentLoader"
import { LineGraph } from "../../../components/Graphs"

function CrabPricesChart(props: any) {
  const { chartData, metricsData } = props
  const [loading, setLoading] = useState(false)
  
  return (
    <>
      <div className='row card-header'>
        <div className='header-info'>
          <div className='title'>
            Crabda Count (todays low)
            {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
          </div>
          <div className='value mt-2'>
            {metricsData.totalSales} &nbsp; 
            <span style={{color: '#A4A4A4'}}>
                {metricsData.totalPrice ? `($${metricsData.totalPrice})` : ''}
            </span>
          </div>
        </div>
        {/* <div className='header-toolbar mt-3'>
          <div className='time-filters'>
            <button className={`btn-filter `}
            //   onClick={() => setFilter('PURE')}
            >Today</button>
            <button className={`btn-filter active`}
            //   onClick={() => setFilter('NON_PURE')}
            >All Crabs</button>
          </div>
        </div> */}
      </div>
      <div className='card-body'>
        {
          loading ? <ComponentLoader loading={loading} />
          : <div className='chart-wrapper'>
            {
              <LineGraph 
                data={chartData.series}
                colors={chartData.colors}
                percentage={false}
                // range={filter}
              />
            }
          </div>
        }
      </div>
    </>
  )
}

export default CrabPricesChart
