import { useLocation, useNavigate } from 'react-router-dom'
import './GameTopNavBar.sass'
import GameTopNavBarMobile from './GameTopNavBarMobile';

function GameTopNavBar(props: any) {
  const { navs } = props
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <div className="game-navbar">
        <ul className='navs'>
          {
            navs && navs?.routes.map((item: any, index: number) => 
              <li 
                key={index}
                className={`nav-item ${location.pathname.includes(item.name.toLowerCase().split(' ').join('-')) ? 'active' : ''}`} 
                onClick={() => navigate(item.route)}
              >
                <span className='nav-link'>{item.name}</span>
              </li>
            )
          }
        </ul>
      </div>
      {/*  */}
      <GameTopNavBarMobile navs={navs} />
    </>
  );
}

export default GameTopNavBar;
