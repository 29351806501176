import { useEffect, useRef, useState } from 'react'
import { EyeIcon, EyeOffIcon, MikoTwitterProfile } from '../../images'
import './InsightsToggleButton.sass'

function InsightsToggleButton(props: any) {
  const { className, toggleInsights, show } = props
  const [insightsBtnTop] = useState(213)
  const [stickyClass, setStickyClass] = useState('')
  const btnRef = useRef<any>(null)

  useEffect(() => {
    const onScroll = () => {
      if(window.pageYOffset > insightsBtnTop) {
        setStickyClass('sticky')
      } else {
        setStickyClass('')
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div className={`insights-toggle-button ${show ? stickyClass : ''} ${className}`} ref={btnRef}>
      <div className='miko-avatar'>
        <img src={MikoTwitterProfile} alt='-' />
      </div>
      <div className='button-text ms-2'>Miko’s Insights</div>
      <button className='btn-toggle ms-auto'
        onClick={() => toggleInsights(!show)}
      >
        <img src={show ? EyeIcon : EyeOffIcon} alt='-' />
      </button>
    </div>
  )
}

export default InsightsToggleButton