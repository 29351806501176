import MarketplaceContainer from "../../../components/Marketplace/MarketplaceContainer";
import {TUS} from "../../../images";
import CrabadaAPI from "../CrabadaAPI";

function CrabadaMarketplace() {
  return (
      <MarketplaceContainer
          nftName={"Crabada"}
          baseCurrencyName={"TUS"}
          baseCurrencyImgSrc={TUS}
          api={CrabadaAPI}
          isDollarToggleVisible={true}
          isInSightsVisible={true}
          isTechnicalDifficultiesCallOutBoxVisible={false}
          isMaintenance={false}
      />
  )
}

export default CrabadaMarketplace
