import { useState } from 'react'
import './SideNavBar.sass'
import { Link, useLocation } from 'react-router-dom'
import {
  P2EIconBeta,
  P2ELogoWhiteBeta,
  ChevronUpIcon,
  ExpandIcon,
  Discord,
  HomeIcon,
  GamesIcon,
  NewsIcon,
  DiamondIcon,
  DiceIcon,
  Twitter,
  Twitch,
  Youtube,
  Facebook
} from '../../images/index'
import { EventEmitter } from '../../utils/Events'
// import games from '../../pages/GamesOverviewData';

function SideNavBar() {
  const location = useLocation()
  // const [expandedNav, setExpandedNav] = useState(window.screen.width > 767 ? true : false)
  const [expandedNav, setExpandedNav] = useState(false)

  const handleMenuClick = (_route: string) => {
    if(location.pathname !== _route)
    setExpandedNav(false)
  }

  const toggleNav = (event: any = null) => {
    if(event === null) {
      setExpandedNav(!expandedNav);
      EventEmitter.dispatch('openSideNav', !expandedNav);
    } else {
      setExpandedNav(event);
      EventEmitter.dispatch('openSideNav', event);
    }
  }

  return (
    <div className={`side-nav ${expandedNav ? 'expanded' : 'collapsed'}`}
      onMouseEnter={() => toggleNav(true)}
      onMouseLeave={() => toggleNav(false)}
    >
      <div className='logo-container'>
        <Link to='/' className='logo-link'>
          <img src={P2EIconBeta} className='logo' alt='P2E' />
        </Link>
        <button className='toggle-mob-nav'
          onClick={() => toggleNav()}
        >
          <img src={P2ELogoWhiteBeta} className='logo' alt='P2E' />
          <img src={ChevronUpIcon} className='dropdown-icon' alt='-' />
        </button>
      </div>
      <div className='nav-expand mt-3'>
        <button className='btn-expand'
          onClick={() => toggleNav()}
        >
          <img src={ExpandIcon} className='icon-expand' alt='<<' />
        </button>
      </div>
      <ul className='nav-items nav-top'>
        {/* {
          navs.banner && <li className='nav-item'><img src={navs.banner} className='nav-banner' alt='' /></li>
        } */}
        <li className='nav-item'>
          <Link to={`/home`} className='nav-link text-start'
            onClick={() => handleMenuClick('/home')}
            title={!expandedNav ? 'GAMES' : ''}
          >
            <img src={HomeIcon} className='nav-icon' alt='' />
            <span className='nav-text'>Games</span>
          </Link>
        </li>
        {/* <li className='nav-item'>
          <Link to={`/discover`} className='nav-link text-start'
            onClick={() => handleMenuClick('/discover')}
            title={!expandedNav ? 'GAMES' : ''}
          >
            <img src={GamesIcon} className='nav-icon' alt='' />
            <span className='nav-text'>Games</span>
          </Link>
        </li> */}
        <li className='nav-item'>
          <a href={`https://p2eanalytics.com/web3`} className='nav-link text-start'
            onClick={() => handleMenuClick('https://p2eanalytics.com/web3')}
            title={!expandedNav ? 'NEWS HUB' : ''}
          >
            <img src={NewsIcon} className='nav-icon' alt='' />
            <span className='nav-text'>NEWS HUB</span>
          </a>
        </li>
      </ul>
      <ul className='nav-items nav-bottom mt-0'>
        <hr className='nav-divider' />
        <li className='nav-item'>
          <Link to={`/careers`} className='nav-link text-start'
            onClick={() => handleMenuClick('/careers')}
          >
            <img src={DiamondIcon} className='nav-icon' alt='' />
            <span className='nav-text'>CAREERS</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link to={`/about`} className='nav-link text-start'
            onClick={() => handleMenuClick('/about')}
          >
            <img src={DiceIcon} className='nav-icon' alt='' />
            <span className='nav-text'>ABOUT</span>
          </Link>
        </li>
      </ul>
      <hr className='nav-divider' />
      <div className='game-list d-none'>
        <h6 className='text-start ms-2'>GAME LIST</h6>
        <ul>
          {/* {
            games && games.map((item: any, index: number) =>
              <li className={`game-item ${index === 0 ? 'mt-5' : 'mt-3'}`} key={index}>
                <Link to={item.route} className='w-100'
                  onClick={() => handleMenuClick(item.route)}
                >
                  <img src={item.navImg} className='game-img' alt={item.title} />
                </Link>
              </li>
            )
          } */}
          {/* <li className='game-item mt-3'>
            <img src={PGMainNav} className='game-img' alt='COMING SOON' />
            <img src={LabelComingSoon} className='coming-soon' alt='COMING SOON' />
          </li> */}
        </ul>
      </div>
      <div className='social-list'>
        <a className='btn-social twitter mt-4' href='https://twitter.com/P2EAnalytics' target='_blank'>
          <span>TWITTER</span>
          <img src={Twitter} className='icon-social' alt='-' />
        </a>
        <a className='btn-social discord mt-3' href='https://discord.com/invite/VDkTXHNrSp' target='_blank'>
          <span>DISCORD</span>
          <img src={Discord} className='icon-social' alt='-' />
        </a>
        <a className='btn-social twitch mt-3' href='https://www.twitch.tv/p2eanalytics' target='_blank'>
          <span>TWITCH</span>
          <img src={Twitch} className='icon-social' alt='-' />
        </a>
        <a className='btn-social youtube mt-3' href='https://www.youtube.com/channel/UCpT5JPTxuAvXirIYkLxMeWQ' target='_blank'>
          <span>YOUTUBE</span>
          <img src={Youtube} className='icon-social' alt='-' />
        </a>
        <a className='btn-social facebook mt-3' href='https://www.facebook.com/p2eanalytics' target='_blank'>
          <span>FACEBOOK</span>
          <img src={Facebook} className='icon-social' alt='-' />
        </a>
      </div>
      <hr className='nav-divider mt-4' />
      <h6 className='text-start ms-1 version-title'>Version 2.0.0</h6>
    </div>
  );
}

export default SideNavBar;
