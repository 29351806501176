import MarketplaceContainer from "../../../components/Marketplace/MarketplaceContainer";
import {AVAX} from "../../../images";
import ChefEconomyAPI from "../api/ChefEconomyAPI";
import ChefToolEconomyAPI from "../api/ChefToolEconomyAPI";

function PizzaGameChefsMarketplace() {
  return (
    <>
      <MarketplaceContainer
          nftName={"Pizza Game Chefs"}
          baseCurrencyName={"AVAX"}
          baseCurrencyImgSrc={AVAX}
          api={ChefEconomyAPI}
          isDollarToggleVisible={false}
          isInSightsVisible={true}
      />
      <MarketplaceContainer
          nftName={"Pizza Game Chef Tools"}
          baseCurrencyName={"AVAX"}
          baseCurrencyImgSrc={AVAX}
          api={ChefToolEconomyAPI}
          isDollarToggleVisible={false}
          isInSightsVisible={false}
      />
    </>
  )
}

export default PizzaGameChefsMarketplace
