import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import NavContext from '../../context/NavContext'
import { BannerPizzaGame } from '../../images'
import GameTopNavBar from '../../components/GameTopNavBar/GameTopNavBar'
import SodaTokenContainer from './SodaToken/SodaTokenInflation'
import PizzaGameChefsMarketplace from './Marketplace/PizzaGameChefsMarketplace'
import PizzaGameTokens from './PizzaGameTokens/PizzaGameTokens'
import PizzaTokenInflation from "./PizzaToken/PizzaTokenInflation";

const gameNavs = {
  title: 'Pizza Game',
  type: 'game',
  position: 'middle', // top, middle, bottom
  banner: BannerPizzaGame,
  routes: [
    {
      name: 'Profile',
      // route: '/games/pizza-game/profile'
      route: '/games/pizza-game/profile'
    },
    {
      name: 'Marketplace',
      route: '/games/pizza-game/marketplace'
    },
    {
      name: 'Pizza Token',
      route: '/games/pizza-game/pizza-token'
    },
    {
      name: 'Soda Token',
      route: '/games/pizza-game/soda-token'
    },
  ],
}

function PizzaGame() {
  const { setNavs } = useContext(NavContext)
  useEffect(() => {
    setNavs(gameNavs)
  }, [])

  return(
    <div className='container-fluid py-5'>
      <div className='dashboard-titlebar'>
        <div className='title-bar'>
          <div className='title'>Pizza Game</div>
          <PizzaGameTokens />
        </div>
      </div>
      <GameTopNavBar navs={gameNavs} />
      <div className='dashboard-container'>
        <div className='dashboard-content'>
          <Routes>
            {/* <Route
              path="/"
              element={<Navigate to="/games/pizza-game/profile" replace />}
            /> */}
            <Route path='/marketplace' element={<PizzaGameChefsMarketplace />} />
            <Route path='/pizza-token' element={<PizzaTokenInflation />} />
            <Route path='/soda-token' element={<SodaTokenContainer />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default PizzaGame