import CareerCard from '../../components/CareerCard/CareerCard';
import CareersHeader from '../../components/CareersHeader/CareersHeader';
// import MaintenanceCard from '../../components/MaintenanceCard/MaintenanceCard';
import './Careers.sass'
import Jobs from './Jobs';

function Careers() {
  
  return (
    <div className="careers">
      {/* <MaintenanceCard message="Coming Soon" /> */}
      <CareersHeader />
      <div className='container py-5'>
        <div className='content-area'>
          <h1 className='entry-title'>P2E Analytics – Careers</h1>
          <h1 className='mt-5'>Welcome to P2E Analytics</h1>
          <p className='mt-4'>
            We’re a well-funded, early-stage Web3 company backed by key ecosystem partners such as Ava Labs, Avalaunch, Big Brain Holdings, and others. 
            We’re building a Web3 hub where players can track their portfolio of NFTs and earnings, as well as optimize their gameplay via analytics dashboards. 
            Come join our team and be a part of the Web3 revolution!
          </p>
          
          <h2 className='mt-4'>Working in the Web3 Gaming Space</h2>
          <p className='mt-4'>
            Web3 gaming is a fast-paced and interesting space to work in. The industry has been growing rapidly as new games are being launched daily. 
            At P2E Analytics you’ll be contributing to the expansion of Web3. If you love gaming, researching and being challenged, then this is the place for you. 
          </p>

          <h2 className='mt-4'>Benefits &amp; Perks</h2>
          <ul className='mt-4'>
            <li className='mt-3'>A competitive salary</li>
            <li className='mt-3'>A remote role within a fully distributed team</li>
            <li className='mt-3'>Flexible PTO + local public holidays</li>
            <li className='mt-3'>Exciting work with a fun team that is passionate about Web3 gaming</li>
          </ul>

          <h2 className='mt-4'>Selection Process</h2>
          <p className='mt-4'>
            We are excited that you are interested in one of our open positions. Thank you for considering P2E Analytics as your future workplace!
          </p>
          <p className='mt-4'>
            Our selection process can vary depending on the position you are applying for. Generally speaking, our process includes:
          </p>
          <ul className='mt-4'>
            <li className='mt-3'>
              <b>Initial Interview: </b>
              Our first conversation with you will be focused on knowing who you are, your dreams, what drives you and what you are looking for. 
              We will introduce ourselves and share a bit more about the position. If the conversation goes well, we will invite you to a second interview, which will be technical.
            </li>
            <li className='mt-3'>
              <b>Technical Interview: </b>
              Our Technical Interview is where you will detail your technical expertise. We will share an assignment and offer you a fixed amount of time to finish it. 
              Don’t worry, you won’t need to deliver the assignment during our interview. However, we may ask you to share some technical insights during the call.
            </li>
            <li className='mt-3'>
              <b>Technical Assignment: </b>
              Everyone that gets to the Technical Interview will have the opportunity to deliver our standard technical assignment, which can vary depending on the role. 
              Details for each assignment will be offered during the Technical Interview. For example: writers need to write a blog post on a given topic. 
              If the blog post is considered within our quality standards and is delivered within the timeframe and scope of the assignment, we will consider hiring that candidate. 
              Our decision will be made based on many factors, but the technical assignment is mandatory.
            </li>
            <li className='mt-3'>
              <b>Proposal &amp; Negotiation: </b>
              If you were approved in all previous steps, we will get in touch and schedule a final call where we will negotiate the terms of your contract. 
              Once we reach an agreement, you will receive a copy of our work contract. Congratulations, you’re hired!
            </li>
          </ul>

          <h2 className='mt-4'>Who do I send my curriculum to?</h2>
          <p className='mt-4'>
            Thank you for applying for a position at P2E Analytics. Please, send your curriculum to <a href="mailto:admin@p2eanalytics.com">admin@p2eanalytics.com</a>. 
            If we believe it will be a good fit we’ll be in touch for an interview. 
          </p>
          <a className='btn btn-send-curriculum mt-4' href="mailto:admin@p2eanalytics.com">SEND CURRICULUM</a>
          
          <p className='mt-4'>Please don’t forget to:</p>
          <ul className='mt-4'>
            <li className='mt-3'>Attach your CV,</li>
            <li className='mt-3'>Add a link to your LinkedIn profile and,</li>
            <li className='mt-3'>
              In the email’s subject, add the role you are applying
              <ul>
                <li className='mt-3'>Example: “CRYPTO CONTENT WRITER”</li>
              </ul>
            </li>
          </ul>
          <p className='mt-4'>Good luck!</p>

          <p className='mt-5'>P2E Analytics Team</p>
        </div>
        <h2 className='mt-5'>Open Positions</h2>
        <div className='row'>
          {
            Jobs && Jobs.map((item: any, index: number) =>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6' key={index}>
                <CareerCard career={item} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Careers;
