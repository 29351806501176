import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import NavContext from '../../context/NavContext'
import GameTopNavBar from '../../components/GameTopNavBar/GameTopNavBar'
import SlimeTokenInflation from './SlimeToken/SlimeTokenInflation'
import SnailTrailMarketplace from './Marketplace/SnailTrailMarketplace'
import SnailTrailGameTokens from "./GameTokens/SnailTrailGameTokens";
import { SnailTrailHeader } from '../../images'

const gameNavs = {
  title: 'Snail Trail',
  type: 'game',
  position: 'middle',
  banner: SnailTrailHeader,
  routes: [
    {
      name: 'Profile',
      // route: '/games/snail-trail/profile'
      route: '/games/snail-trail/profile'
    },
    {
      name: 'Marketplace',
      route: '/games/snail-trail/marketplace'
    },
    // {
    //   name: 'Slime Token',
    //   route: '/games/snail-trail/slime-token'
    // }
  ],
}

function SnailTrail() {
  const { setNavs } = useContext(NavContext)
  useEffect(() => {
    setNavs(gameNavs)
  }, [])

  return(
    <div className='container-fluid py-5'>
      <div className='dashboard-titlebar'>
        <div className='title-bar'>
          <div className='title'>Snail Trail</div>
            <SnailTrailGameTokens />
        </div>
      </div>
      <GameTopNavBar navs={gameNavs} />
      <div className='dashboard-container'>
        <div className='dashboard-content'>
          <Routes>
            {/* <Route
              path="/"
              element={<Navigate to="/games/snail-trail/profile" replace />}
            /> */}
            <Route path='/marketplace' element={<SnailTrailMarketplace />} />
            <Route path='/slime-token' element={<SlimeTokenInflation />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default SnailTrail
