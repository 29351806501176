import axios from 'axios'

const headers = {
    'Content-Type': 'application/json',
};

const getAvaxToken = () => {
    let url = "https://api.crabada.com/public/price/using";
    return axios.get(url);
}

const getGameTokenByAssetID = (erc20ID: any) => {
    let url = `https://api.p2eanalytics.com/erc20s/${erc20ID}/quote`;
    return axios.get(url, { headers: headers });
}

export const getTokenPerformanceDataByAssetID = (erc20ID: any, params: any) => {
    let url = `https://api.p2eanalytics.com/erc20s/${erc20ID}/quote/historical?startTime=${params.startTime}&&endTime=${params.endTime}&&interval=${params.interval}`;
    return axios.get(url, { headers: headers });
}

const GameTokenAPI = {
    getAvaxToken: getAvaxToken,
    getGameTokenByAssetID: getGameTokenByAssetID,
    getTokenPerformanceDataByAssetID: getTokenPerformanceDataByAssetID
}

export default GameTokenAPI;
