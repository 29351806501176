import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { roundAndParseNumber, roundAndParsePercentage } from '../../utils/NumberUtil'
import moment from 'moment'

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

function xFormatter(value: moment.MomentInput, range: string) {
  return moment.utc(value).format('MMM DD');
}

function xTickAmount(range: string) {
  if(range === 'TWENTY_FOUR') {
    return 12;
  } else if(range === 'SEVEN') {
    return 7;
  } else if(range === 'THIRTY') {
    return 15;
  } else if(range === 'ALL_TIME') {
    return 15;
  }
  return 15;
}

const options: any = (colors: any, percentage: any, range: string, gradient: boolean) => {
  return {
    annotations: {
      yaxis: [{
        y: 0,
        strokeDashArray: 0,
        borderColor: '#ffffff',
        fillColor: '#ffffff',
        opacity: 1,
        label: {
          borderColor: '#ffffff',
          borderWidth: 1,
          text: ''
        }
      }]
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
      height: 350,
      type: 'area',
      zoom: {
        enabled: false
      }
    },
    colors: colors,
    plotOptions: {
      bar: {
        borderRadius: range === 'ALL_TIME' ? 1 : 6
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: gradient ? 'gradient' : 'solid',
      gradient: {
        shadeIntensity: 1,
        type: 'vertical',
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0.3,
        stops: [0, 99, 100],
        colorStops: [ 
          [
            {
              offset: 0,
              color: '#eee',
              opacity: 1
            },
            {
              offset: 0.6,
              color: '#B02170',
              opacity: 50
            },
            {
              offset: 100,
              color: '#B0217000',
              opacity: 1
            }
          ]
        ]
      }
    },
    stroke: {
      curve: 'smooth',
      colors: (colors && colors.length > 1) ? colors : undefined,
      width: 3
    },
    title: {
    },
    grid: {
      borderColor: '#424242',
      xaxis: {
        lines: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: 'transparent', // takes an array which will be repeated on columns
        opacity: 0
      },  
    },
    legend: {
      show: false
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: false
      },
      custom: (props: any) => {
        const {series, seriesIndex, dataPointIndex, w} = props
        let _colors = w.config.colors;
        let _series = w.config.series;
        // console.log('===', w.config);
        // timestamp
        let _timestamp = '';
        // values
        let valueStr = '';
        for(let i = 0; i < _series.length; i++) {
          let _value = percentage ? roundAndParsePercentage(_series[i].data[dataPointIndex].y) : roundAndParseNumber(_series[i].data[dataPointIndex].y);
          valueStr += '<div class="inline-flex">'+ (_series.length > 1 ? '<span class="tooltip-dot" style="background:'+ _colors[i] +'"></span>' : '') +'<span">' + _series[i].name + ': </span><strong> ' + _value + '</strong></div>';
        }
        // let _value = percentage ? roundAndParsePercentage(_series[seriesIndex].data[dataPointIndex].y) : roundAndParseNumber(_series[seriesIndex].data[dataPointIndex].y);
        return '<div class="tooltip-p2e">' +
                '<div class="text-start"><strong>' + moment.utc(_series[seriesIndex].data[dataPointIndex].x).format('M/D/YYYY') + '</strong>' +  _timestamp + '</div>' + 
                valueStr +
              '</div>';
      }
    },
    xaxis: {
      // type: range === 'all' ? 'datetime' : 'category',
      tickAmount: xTickAmount(range),
      // tickPlacement: 'on',
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          colors: '#fff'
        },
        formatter: (value: moment.MomentInput) => {
          return xFormatter(value, range);
        }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          colors: '#fff'
        },
        formatter: (value: number) => {
          if (percentage) {
            return roundAndParsePercentage(value)
          }
          return value >= 0 ? nFormatter(value, 3) : '-' + nFormatter(-value, 3)
        },
      },
    }
  }
};


function Area(props: any) {
  const { data, colors, percentage, range, gradient } = props

  return(
    <Chart options={options(colors, percentage, range, gradient)} series={data} type="line" height={320} />
  )
}

export default Area