import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ResourcesAPI from "./ResourcesAPI"

const LatestArticles = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getResources()
  }, [])

  const getResources = () => {
    ResourcesAPI.getResources()
      .then(res => {
        if(res.data) {
          initPosts(res.data)
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }

  const initPosts = (_data: any) => {
    let _posts: any = []
    for(let i = 0; i < _data.length; i++){
      let _post: any = {
        id: _data[i].id,
        created_at: _data[i].date,
        modified_at: new Date(_data[i].modified).getTime(),
        slug: _data[i].slug,
        // type: _data[i].type,
        link: _data[i].link,
        title: _data[i].title.rendered,
        content: _data[i].content.rendered,
        // featured_image_src: _data[i].featured_image_src,
        // featured_image_src_square: _data[i].featured_image_src_square,
        featured_image_src_original: _data[i].yoast_head_json.og_image ? _data[i].yoast_head_json.og_image[0].url : '',
        author_name: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].name : '',
        // author_avatar: _data[i]?._embedded.author[0].avatar_urls["96"],
        est_reading_time: _data[i]?.yoast_head_json.twitter_misc ? _data[i]?.yoast_head_json.twitter_misc["Est. reading time"] : '',
      }
      _posts.push(_post)
    }
    let _sortedPosts = _posts.sort(function(a: any, b: any) { return b.modified_at - a.modified_at});
    setPosts(_sortedPosts)
  }

  return (
    <div className=''>
      {
        posts && posts.map((item: any, index: number) =>
          <div className='row my-4' key={index}>
            <div className='col-6'>
              <div className='article-img-wrapper bg-temp-img'>
                <a href={`https://p2eanalytics.com/web3/${item.slug}`}>
                  <img src={item.featured_image_src_original} alt='' />
                </a>
              </div>
            </div>
            <div className='col-6'>
              <a className='article-title' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                {item.title}
              </a>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default LatestArticles;