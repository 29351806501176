import { createContext } from 'react';

export const defaultNav: any = {
    navs: {
        type: 'home', // home, game
        position: 'top', // top, middle, bottom
        banner: '',
        routes: [
        {
            name: 'Home',
            route: '/home'
        },
        {
            name: 'Discover',
            route: '/discover'
        },
        {
            name: 'Resources',
            route: 'https://p2eanalytics.com/web3'
        },
        {
            name: 'Careers',
            route: '/careers'
        },
        {
            name: 'About',
            route: '/about'
        },
        ],
    },
    setNavs: () => {}
}

const NavContext = createContext(defaultNav)

export default NavContext