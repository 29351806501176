import { round } from "lodash"
import moment from "moment"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ComponentLoader from "../../../components/ComponentLoader/ComponentLoader"
import { AreaGraph, BarGraph, MixedGraph } from "../../../components/Graphs"
import { ChartIcon, InfomationIcon, PIZZA } from "../../../images"
import { toDateString } from "../../../utils/DateUtil"
import { EventEmitter } from "../../../utils/Events"
import PizzaTokenAPI from "../api/PizzaTokenAPI";

function PizzaTokenCharts(props: any) {
	const { inSightsShow } = props
  const metricsWrapperRef = useRef<any>(null)
  const metricsRef = useRef<any>(null)
  const [metricsBoxRatio, setMetricsBoxRatio] = useState(1)
  const [openSideNavStatus, setOpenSideNavStatus] = useState(false)
  // 
  const [loading, setLoading] = useState(false)
  const [barToggleLoading, setBarToggleLoading] = useState(false)
  // Time Filter
  const [inflationTimeFilter, setInflationTimeFilter] = useState('SEVEN') // 
  // Area/Bar Toggle
  const [isInflationBarChart, setIsInflationBarChart] = useState(false)
  // Response Data
  const [inflationRowData, setInflationRowData] = useState([])
  // Charts Data
  const [inflationMetrics, setInflationMetrics] = useState({
    supply30DaysAgo: '',
    supply7DaysAgo: ''
  })
  const [inflationRolling, setInflationRolling] = useState({
    burned: '',
    inflationPercent: '',
    issuance: '',
    netSupply: ''
  })
  const [netSupplyData, setNetSupplyData] = useState([])
  const [inflationData, setInflationData] = useState([])
  const [issuanceBreedData, setIssuanceBreedData] = useState([])
  // Action
  const handleInflationBarChartToggle = (e:boolean) => {
    setBarToggleLoading(true)
    setIsInflationBarChart(e)
    initNetSupplyData(inflationRowData, inflationTimeFilter, e)
    setTimeout(() => {
      setBarToggleLoading(false)
    }, 100)
  }
  
  useEffect(() => {
    getPizzaInflationData()
  }, [inflationTimeFilter])

  useEffect(() => {
    getPizzaInflationGeneral()
  }, [])

  const getPizzaInflationGeneral = () => {
    // setLoad(true)
    PizzaTokenAPI.getPizzaInflationGeneral()
      .then(res => {
        if(res.data) {
          setInflationMetrics({
            supply30DaysAgo: (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)) > 9999 
              ? round(res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString()
              : (res.data.generalMetrics.supply30DaysAgo / Math.pow(10, 18)).toLocaleString(),
            supply7DaysAgo: (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)) > 9999 
              ? round(res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString()
              : (res.data.generalMetrics.supply7DaysAgo / Math.pow(10, 18)).toLocaleString()
          })
          setInflationRolling({
            burned: (res.data.rollingWindows.burned / Math.pow(10, 18)).toLocaleString(),
            inflationPercent: (res.data.rollingWindows.inflationPercent * 100).toFixed(3),
            issuance: (res.data.rollingWindows.issuance / Math.pow(10, 18)).toLocaleString(),
            netSupply: (res.data.rollingWindows.netSupply / Math.pow(10, 18)).toLocaleString()
          })
        }
        // setLoad(false)
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }

  const getPizzaInflationData = () => {
    setLoading(true)
    PizzaTokenAPI.getPizzaInflationData(inflationTimeFilter)
      .then(res => {
        if(res.data.data) {
          setInflationRowData(res.data.data)
          initNetSupplyData(res.data.data, inflationTimeFilter, isInflationBarChart)
          initInflationData(res.data.data, inflationTimeFilter)
          initIssuanceBreedData(res.data.data, inflationTimeFilter)
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const initNetSupplyData = (_data: any, _timeFilter: string, _isInflationBarChart: boolean) => {
    let netSupplyArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      netSupplyArr.push({ 
        x: dateResult, 
        y: _data[i].netSupply / Math.pow(10, 18),
        category: 'tus', 
        timeFilter: _timeFilter
      })
    }

    let _netSupplyData: any = []
    _netSupplyData.push({name: 'Net Supply', type: (_isInflationBarChart ? 'bar' : 'area'), data: netSupplyArr})
    setNetSupplyData(_netSupplyData)
  }

  const initInflationData = (_data: any, _timeFilter: string) => {
    let issuanceArr: any = []
    let breedingArr: any = []
    let inflationArr: any = []

    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')
      
      if(dateResult >= moment.utc('2022-03-01 00:00:00').format('YYYY-MM-DD HH:mm:ss')) {
        // 
        issuanceArr.push({
          x: dateResult,
          y: _data[i].mintPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });
        // 
        breedingArr.push({
          x: dateResult,
          y: _data[i].burnPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });
        // 
        inflationArr.push({
          x: dateResult,
          y: _data[i].inflationPercent,
          category: 'tus', 
          timeFilter: _timeFilter
        });      
      }
    }
    
    let _inflationData: any = []
    _inflationData.push({name: 'Issuance', type: 'line', color: '#1370F6', data: issuanceArr})
    _inflationData.push({name: 'Burn', type: 'line', color: '#B02170', data: breedingArr})
    _inflationData.push({name: '+/- Inflation', type: 'bar', color: '#00000000', data: inflationArr})
    setInflationData(_inflationData)
  }

  const initIssuanceBreedData = (_data: any, _timeFilter: string) => {
    let _mintsArr: any = []
    let _burnsArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      let mints = _data[i].timeBucketMints / Math.pow(10, 18);
      _mintsArr.push({
        x: dateResult, 
        y: mints, 
        category: 'tus', 
        timeFilter: _timeFilter
      })

      const burns = _data[i].timeBucketBurns / Math.pow(10, 18);
      _burnsArr.push({
        x: dateResult, 
        y: burns, 
        category: 'tus', 
        timeFilter: _timeFilter
      })
    }

    let _issuanceBreedData: any = []
    _issuanceBreedData.push({name: 'mints', data: _mintsArr})
    _issuanceBreedData.push({name: 'burns', data: _burnsArr})
    setIssuanceBreedData(_issuanceBreedData)
  }

  // LayoutEffect
  useLayoutEffect(() => {
    function handleReSize() {
      scaleMetricsBox()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [inSightsShow, openSideNavStatus])

  const scaleMetricsBox = () => {
    if(metricsWrapperRef.current.clientWidth < metricsRef.current.clientWidth) {
      setMetricsBoxRatio(Math.round(metricsWrapperRef.current.clientWidth * 10 / metricsRef.current.clientWidth) / 10 - 0.1)
    } else {
      setMetricsBoxRatio(1)
    }
  }

  EventEmitter.subscribe('openSideNav', (event: any) => {
    setOpenSideNavStatus(event)
  })
	
	return (
		<>
			<div className='row'>
        <div className='col-12'>
          <div className='chart-card'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className='row card-header'>
                  <div className='header-info'>
                    <div className='title'>
                      Net Supply
                      {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                    </div>
                    <div className='value mt-2'>
                      <div className='token-value'>
                        <img src={PIZZA} className='token-icon me-1' alt='-' />
                        { inflationRolling.netSupply }
                      </div>
                    </div>
                  </div>
                  <div className='header-toolbar mt-3'>
                    <div className='time-filters'>
                      <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                        onClick={() => setInflationTimeFilter('SEVEN')}
                      >7 Days</button>
                      <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                        onClick={() => setInflationTimeFilter('THIRTY')}
                      >30 Days</button>
                      <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                        onClick={() => setInflationTimeFilter('ALL_TIME')}
                      >All Time</button>
                    </div>
                    <img src={ChartIcon} className={`chart-icon ${isInflationBarChart ? 'active' : ''}`} alt='-' 
                      onClick={() => handleInflationBarChartToggle(!isInflationBarChart)}
                    />
                  </div>
                </div>
                <div className='card-body'>
                  {
                    (barToggleLoading || loading) ? <ComponentLoader loading={barToggleLoading || loading} />
                    : <div className='chart-wrapper'>
                      {
                        isInflationBarChart
                        ? <BarGraph 
                          data={netSupplyData}
                          colors={['#B02170']}
                          percentage={false}
                          range={inflationTimeFilter}
                          gradient={true}
                        />
                        : <AreaGraph 
                          data={netSupplyData}
                          colors={['#B02170']}
                          percentage={false}
                          range={inflationTimeFilter}
                          gradient={true}
                        />
                      }
                    </div>
                  }
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                <div className='metrics-wrapper' ref={metricsWrapperRef}>
                  <div className='chart-sales m-auto' ref={metricsRef}
                    style={{transform: `scale(${metricsBoxRatio})`}}
                  >
                    {
                      (inflationMetrics && inflationMetrics.supply7DaysAgo) ? 
                      <div className='all-time-sales my-2'>
                        <div className='sales-title'>Net Supply - 7 days ago</div>
                        <div className='sales-value'>
                          <img src={PIZZA} className='token-icon' alt='-' />
                          {inflationMetrics.supply7DaysAgo.toLocaleString()}
                        </div>
                      </div>
                      : ''
                    }
                    {
                      (inflationMetrics && inflationMetrics.supply30DaysAgo) ? 
                      <div className='all-time-sales my-2'>
                        <div className='sales-title'>Net Supply - 30 days ago</div>
                        <div className='sales-value'>
                          <img src={PIZZA} className='token-icon' alt='-' />
                          {inflationMetrics.supply30DaysAgo.toLocaleString()}
                        </div>
                      </div>
                      : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row sm'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info'>
                <div className='title'>
                  Net Inflation
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={PIZZA} className='token-icon me-1' alt='-' />
                    { inflationRolling.inflationPercent }%
                  </div>
                </div>
              </div>
            </div>
            <div className='row card-header'>
              <div className='custom-legend mt-1'>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#1370F6'}}></span>Issuance
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#B02170'}}></span>Burn
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#008F20'}}></span>
                  <span className='dot ms-0' style={{background: '#8F0004'}}></span>
                  +/- Inflation
                </div>
              </div>
              <div className='header-toolbar'>
                <div className='time-filters'>
                  <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <MixedGraph 
                    data={inflationData}
                    colors={['#1370F6', '#B02170', '#008F20']}
                    percentage={true}
                    range={inflationTimeFilter}
                    gradient={false}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info mt-2'>
                <div className='title' style={{color: '#1370F6'}}>
                  Issuance
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={PIZZA} className='token-icon me-1' alt='-' />
                    { inflationRolling.issuance }
                  </div>
                </div>
              </div>
              <div className='header-info mt-2'>
                <div className='title' style={{color: '#B02170'}}>
                  Burn
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  <div className='token-value'>
                    <img src={PIZZA} className='token-icon me-1' alt='-' />
                    { inflationRolling.burned }
                  </div>
                </div>
              </div>
            </div>
            <div className='row card-header'>
              <div className='custom-legend mt-1'>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#1370F6'}}></span>Mints
                </div>
                <div className='legend-item'>
                  <span className='dot' style={{background: '#B02170'}}></span>Burns
                </div>
              </div>
              <div className='header-toolbar'>
                <div className='time-filters'>
                  <button className={`btn-filter ${inflationTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'THIRTY' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${inflationTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setInflationTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <BarGraph 
                    data={issuanceBreedData}
                    colors={['#004EBF', '#B02170']}
                    percentage={false}
                    range={inflationTimeFilter}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
		</>
	)
}

export default PizzaTokenCharts