import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.sass';
import Home from './pages/Home';
import SideNavBar from './components/SideNavBar/SideNavBar';
import About from './pages/About';
import Discover from './pages/Discover';
import Resources from './pages/Resources';
import Careers from './pages/Careers';
import Crabada from './games/Crabada';
import PizzaGame from './games/PizzaGame';
import NavContext, { defaultNav } from './context/NavContext';
import { useState } from 'react';
import ResourcesDetail from './pages/Resources/ResourcesDetail';
import Footer from './components/Footer/Footer';
import ResourcesVideo from './pages/Resources/ResourcesVideo';
import CareerDetail from './pages/Careers/CareerDetail';
import SnailTrail from "./games/SnailTrail";
import Rss from './feed/rss';
import Games from './games';

function App() {
  const [navs, setNavs] = useState(defaultNav.navs)
  const navValue = { navs, setNavs }

  return (
    <NavContext.Provider value={navValue}>
      <Router>
        <Routes>
          <Route path='/feed/rss' element={<Rss />} />
          <Route path='*' element={<AppRoutes />} />
        </Routes>
      </Router>
    </NavContext.Provider>
  );
}

export default App;

// function FeedRoutes() {
//   return (
//     <Routes>
//       <Route path='/' element={<Home />} />
//       <Route path='rss' element={<Home />} />
//     </Routes>
//   );
// }

function AppRoutes() {
  return (
    <div className='App'>
      <SideNavBar />
      <div className='main'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='home' element={<Home />} />
          {/* <Route path='discover' element={<Discover />} /> */}
          <Route path='web3' element={<Resources />} />
            <Route path='web3/:slug' element={<ResourcesDetail />} />
            <Route path='web3/video/:id' element={<ResourcesVideo />} />
          <Route path='careers' element={<Careers />} />
            <Route path='careers/:id' element={<CareerDetail />} />
          <Route path='about' element={<About />} />
          {/*  */}
          <Route path="games/:slug/profile" element={<Games />} />
          {/*  */}
          <Route path="games/crabada/*" element={<Crabada />} />
          <Route path="games/pizza-game/*" element={<PizzaGame />} />
          <Route path="games/snail-trail/*" element={<SnailTrail />} />
          {/*  */}
          <Route
              path="*"
              element={<Navigate to="/" replace />}
          />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}
