import { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import './GameTopNavBarMobile.sass'
import { useLocation } from 'react-router-dom'

function GameTopNavBarMobile(props: any) {
  const { navs } = props
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState('')

  useEffect(() => {
    if(navs?.routes) {
      for(let i = 0; i < navs.routes.length; i++) {
        if(location.pathname.includes(navs?.routes[i].route)) {
          setCurrentRoute(navs?.routes[i].name)
        }
      }
    }
  }, [location, navs])

  return (
    <div className='game-mobile-nav'>
      {
        navs?.banner && <img src={navs?.banner} className='nav-banner' alt='' />
      }
      <DropdownButton className='' title={currentRoute}>
        {
          navs?.routes.map((item: any, index: number) =>
            <Dropdown.Item href={item.route} key={index}>{item.name}</Dropdown.Item>
          )
        }
      </DropdownButton>
    </div>
  )
}

export default GameTopNavBarMobile
