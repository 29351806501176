import './CallOutBox.sass'

const CallOutBox = (props: any) => {
  const { message } = props
  return (
    <div className='call-out-box'>
      <div className='call-out-box-wrapper'>
        {message}
      </div>
    </div>
  )
}

export default CallOutBox