import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Insights from '../../../components/Insights/Insights'
import { HatchingSchedule } from '../../../images'
import PopulationHeaderSection from "./PopulationHeaderSection";
import PopulationClassPurityDistribution from "./PopulationClassPurityDistribution";
import { EventEmitter } from '../../../utils/Events'
import PopulationChart from './PopulationChart'
import PopulationPurityChart from './PopulationPurityChart'
import MaintenanceCard from '../../../components/MaintenanceCard/MaintenanceCard';

function Population(props: any) {
  const { 
    populationDistribution, totalPopulation, totalPurityData, classPurityDistribution
  } = props
  // 
  const [iHeight, setIHeight] = useState(0)
  const [showInsights, setShowInsights] = useState(true)
  const [show, setShow] = useState(true)
  // 
  const metricsWrapperRef = useRef<any>(null)
  const metricsRef = useRef<any>(null)
  const [metricsBoxRatio, setMetricsBoxRatio] = useState(1)
  const [openSideNavStatus, setOpenSideNavStatus] = useState(false)

  useEffect(() => {
    initHeight()
  }, [])

  useLayoutEffect(() => {
    function handleReSize() {
      initHeight()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])

  const initHeight = () => {
    let cHeight = document.getElementById('chartSection')?.clientHeight
    setIHeight(cHeight ? cHeight : 0)
  }

  // LayoutEffect
  useLayoutEffect(() => {
    function handleReSize() {
      scaleMetricsBox()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [show, openSideNavStatus])

  const scaleMetricsBox = () => {
    if(metricsWrapperRef.current.clientWidth < metricsRef.current.clientWidth) {
      setMetricsBoxRatio(Math.round(metricsWrapperRef.current.clientWidth * 10 / metricsRef.current.clientWidth) / 10 - 0.1)
    } else {
      setMetricsBoxRatio(1)
    }
  }

  EventEmitter.subscribe('openSideNav', (event: any) => {
    setOpenSideNavStatus(event)
  })

  return (
    <>
      {/*  */}
      <div className='dashboard-charts population-charts'>
        <hr className='section-divider' />
        <PopulationHeaderSection
            showInsights={showInsights}
            setShowInsights={setShowInsights}
        />
        {/*  */}
        <div className='row'>
          <div className='charts-section' id='chartSection'
            style={{'width': showInsights ? 'calc(100% - 310px)' : '100%'}}
          >
            <div className=''>
              <div className='row'>
                <div className='col-12'>
                  <div className='chart-card'>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                        <PopulationChart totalPopulation={totalPopulation} />
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                        <div className='metrics-wrapper' ref={metricsWrapperRef}>
                          <div className='chart-sales m-auto' ref={metricsRef}
                            style={{transform: `scale(${metricsBoxRatio})`}}
                          >
                            <div className='sales-items'>
                            {
                              populationDistribution && populationDistribution.map((item: any, index: number) =>
                                <div className='token-item' key={index} style={{background: item.color}}>
                                  <img src={item.img} className='token-icon' alt='-' />
                                  <div className='token-value'>
                                    {item.value}
                                    <span className='token-percent'>{Math.round(item.value * 100 / totalPopulation)}%</span>
                                  </div>
                                </div>
                              )
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='chart-card'>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                        <PopulationPurityChart totalPopulation={totalPopulation} />
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                        <div className='metrics-wrapper' ref={metricsWrapperRef}>
                          <div className='chart-sales m-auto' ref={metricsRef}
                            style={{transform: `scale(${metricsBoxRatio})`}}
                          >
                            <div className='sales-items'>
                              <div className='purity-item pure'>
                                <div className='purity-label'>Pure</div>
                                <div className='purity-value'>{totalPurityData.pure}</div>
                              </div>
                              <div className='purity-item non-pure'>
                                <div className='purity-label'>Non Pure</div>
                                <div className='purity-value'>{totalPurityData.non_pure}</div>
                              </div>
                              <div className='purity-item total'>
                                <div className='purity-label'>TOTAL POPULATION</div>
                                <div className='purity-value'>{totalPurityData.total}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {Object.keys(classPurityDistribution).length > 0 &&
                <PopulationClassPurityDistribution
                    classPurityDistribution={classPurityDistribution}
                />
              }
              {/*  */}
              <div className='w-100'>
                <img src={HatchingSchedule} className='w-100' alt='' />
              </div>
              
            </div>
            {/*  */}
            {/* <MaintenanceCard message='Under Maintenance' /> */}
            {/*  */}
            <div className='row mx-0'>
              <a className='btn-submit-feedback mt-3'
                href='https://discord.gg/VDkTXHNrSp'
                target='_blank'
              >Submit Feedback</a>
            </div>
          </div>
          <div className={`insights-section ${showInsights ? 'd-block' : 'd-none'}`} style={{height: iHeight + 'px'}}>
            <Insights />
          </div>
        </div>
      </div>
    </>
  )
}

export default Population