import { useEffect, useState } from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AreaGraph } from "../../components/Graphs";
import moment from "moment";
import { toDateString } from "../../utils/DateUtil";
import GameTokenAPI from "../../apis/GameTokenAPI";

const TokenPerformance = (props: any) => {
    const { erc20IDs, tokens } = props;
    const [filter, setFilter] = useState({
        period: 'THIRTY',
        range: [new Date(), new Date()]
    });
    const [charts, setCharts] = useState<any>([]);

    useEffect(() => {
        if(tokens) {
            initChartsByTokens();
        }
    }, [tokens]);

    const initChartsByTokens = () => {
        let _charts = [];
        for(let i = 0; i < tokens.length; i++) {
            _charts.push({
                name: tokens[i].post_title,
                img: tokens[i].guid,
                total: 0,
                series: []
            });
        }
        setCharts(_charts);
    }

    useEffect(() => {
        handleClickFilter('THIRTY');
    }, [erc20IDs]);

    const getTokenPerformanceData = (_filter: any) => {
        let params = {
            startTime: _filter.range[0] ? new Date(_filter.range[0]).getTime() : '',
            endTime: _filter.range[1] ? new Date(_filter.range[1]).getTime() : '',
            interval: ''
        };
        for(let i = 0; i < erc20IDs.length; i++) {
            getGameTokenPerformanceDataByAssetID(erc20IDs[i], params);
        }
    }

    const getGameTokenPerformanceDataByAssetID = (erc20ID: any, params: any) => {
        GameTokenAPI.getTokenPerformanceDataByAssetID(erc20ID, params)
            .then((res: any) => {
                // console.log('res: ', res);
                if(res.status === 200 && res.data && res.data.data) {
                    initTokenSeries(res.data.data.quotes, res.data.data.symbol);
                }
            })
            .catch((err: any) => {
                console.log('err: ', err);
            });
    }

    const initTokenSeries = (quotes: any, tokenName: string) => {
        let series: any = [];
        let data: any = [];
        let total: number = 0;
        for(let i = 0; i < quotes.length; i++) {
            let timestamp = quotes[i].timestamp; //Example -> in ms
            const date = new Date(timestamp);
            const dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss');
            data.push({
                x: dateResult,
                y: quotes[i].quote.USD.price,
                category: '',
                timeFilter: ''
            });
            total += quotes[i].quote.USD.price;
        }
        series.push({
            name: tokenName,
            type: 'area',
            data: data
        });
        let _charts = [...charts];
        for(let i = 0; i < _charts.length; i++) {
            if(_charts[i].name === tokenName) {
                _charts[i].total = total;
                _charts[i].series = series;
                break;
            }
        }
        setCharts(_charts);
    }

    const handleClickFilter = (_period: string) => {
        let _startDate: any = new Date();
        switch(_period) {
            case 'TWENTY_FOUR' :
                _startDate -= 1000 * 60 * 60 * 24 * 1;
                break;
            case 'SEVEN' :
                _startDate -= 1000 * 60 * 60 * 24 * 7;
                break;
            case 'THIRTY' :
                _startDate -= 1000 * 60 * 60 * 24 * 30;
                break;
        }
        setFilter({
            period: _period,
            range: [_startDate, new Date()]
        });
        getTokenPerformanceData({
            period: _period,
            range: [_startDate, new Date()]
        });
    }

    const handleChangeDateRangePicker = (dates: any) => {
        setFilter({...filter, period: '', range: dates});
        getTokenPerformanceData({...filter, period: '', range: dates});
    }

    return (
        <>
            {
                (erc20IDs && erc20IDs.length && tokens && tokens.length) ? 
                <div className="profile-section">
                    <h4 className="section-header">
                        Token Performance
                        <span className="ms-auto">Data provided by source </span>
                    </h4>
                    <div className="section-card token-performance">
                        <div className="filter-bar">
                            <div className="filter-buttons mb-2">
                                <button className={`btn btn-filter ${filter.period === 'TWENTY_FOUR' ? 'active' : ''}`}
                                    onClick={() => handleClickFilter('TWENTY_FOUR')}
                                >1DAY</button>
                                <button className={`btn btn-filter ${filter.period === 'SEVEN' ? 'active' : ''}`}
                                    onClick={() => handleClickFilter('SEVEN')}
                                >7DAYS</button>
                                <button className={`btn btn-filter ${filter.period === 'THIRTY' ? 'active' : ''}`}
                                    onClick={() => handleClickFilter('THIRTY')}
                                >30DAYS</button>
                            </div>
                            <div className="date-range-picker mb-2">
                                <CalendarTodayIcon />
                                <DatePicker 
                                    placeholderText="Select Date Range"
                                    selectsRange={true}
                                    startDate={filter.range[0]}
                                    endDate={filter.range[1]}
                                    onChange={(dates: any) => handleChangeDateRangePicker(dates)} 
                                />
                            </div>
                        </div>
                        <div className="token-charts">
                            <div className="row">
                                {
                                    charts && charts.map((item: any, index: number) =>
                                    item.series.length ?
                                        <div className="col-12 col-sm-12 col-md-6" key={index}>
                                            <div className="chart-item">
                                                <div className="chart-header">
                                                    <img src={item.img} alt="-" />{item.name} ${item.total.toFixed(5)}
                                                </div>
                                                <div className='chart-wrapper'>
                                                    <AreaGraph 
                                                        data={item.series}
                                                        colors={['#B02170']}
                                                        percentage={false}
                                                        range={filter.period}
                                                        gradient={true}
                                                        parseNumber={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export default TokenPerformance;
