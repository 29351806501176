import axios from 'axios'

// Token Values
export const getTokenValues = () => {
    let url = "https://api.p2eanalytics.com/erc20s/erc20:627ddf0efc886d2b02d8fd16/quote";
    return axios.get(url);
}

// Economy API
// export const getRollingData = () => {
//     let url = "https://api.p2eanalytics.com/626ab3f9f19ae260acb3f7df/economy/marketplace";
//     let params = {
//         frequency: 'TWENTY_FOUR'
//     };
//     return axios.get(url, { params });
// }

export const getPizzaInflationData = (frequency: string) => {
    let url = "https://api.p2eanalytics.com/627ddf0efc886d2b02d8fd16/economy/inflation?scanIndexId=627ddf963025c15011825dd7";
    let params = {
        frequency: frequency
    };
    return axios.get(url, { params });
}

export const getPizzaInflationGeneral = () => {
    let url = "https://api.p2eanalytics.com/627ddf0efc886d2b02d8fd16/economy/inflation/general?scanIndexId=627ddf963025c15011825dd7";
    return axios.get(url);
}

const PizzaTokenAPI = {
    getTokenValues: getTokenValues,
    // getRollingData: getRollingData,
    getPizzaInflationData: getPizzaInflationData,
    getPizzaInflationGeneral: getPizzaInflationGeneral
}

export default PizzaTokenAPI;
