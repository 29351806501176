import moment from "moment";
import { useEffect, useState } from "react";
import ComponentLoader from "../../../components/ComponentLoader/ComponentLoader";
import { BarGraph, LineGraph } from "../../../components/Graphs";
import { ChartIcon, InfomationIcon } from "../../../images";
import { toDateString } from "../../../utils/DateUtil";
import CrabadaAPI from "../CrabadaAPI";

function PopulationPurityChart(props: any) {
  const { totalPopulation } = props
  const [loading, setLoading] = useState(false)
  // Filter Param
  const [filter, setFilter] = useState('ALL')
  // Area/Bar Toggle
  const [isPurityBarChart, setIsPurityBarChart] = useState(false)
  // Charts Data
  const [populationPurityRowData, setPopulationPurityRowData] = useState([])
  const [populationPurityData, setPopulationPurityData] = useState({
    series: [],
    labels: ['PURE', 'NON-PURE'],
    colors: ["#1888FE", "#00224E"]
  })

  useEffect(() => {
    getPopulationPurity()
  }, [filter])

  const getPopulationPurity = () => {
    setLoading(true)
    CrabadaAPI.getPopulationPurity()
      .then(res => {
        if(res.data) {
          setPopulationPurityRowData(res.data)
          initPopulationPurityData(res.data, isPurityBarChart)
        }
        setLoading(false)
      })
      .catch(err => {
        console.log('err: ', err);
        setLoading(false)
      })
  }

  const initPopulationPurityData = (_data: any, _isPurityBarChart: boolean) => {
    let _purePopulationArr = [];
    let _nonPurePopulationArr = [];
    for(let i = 0; i < _data.length; i++) {
      let date = new Date(_data[i].timestamp);
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss');
      _purePopulationArr.push({
        x: dateResult,
        y: _data[i].purePopulation,
        category: 'crabPopulationPurity', 
        timeFilter: ''
      });
      // 
      _nonPurePopulationArr.push({
        x: dateResult,
        y: _data[i].nonPurePopulation,
        category: 'crabPopulationPurity', 
        timeFilter: ''
      });
    }
    // 
    let _series: any = [
      {
        name: 'PURE',
        type: _isPurityBarChart ? 'bar' : 'line',
        color: '#1888FE',
        data: _purePopulationArr
      },
      {
        name: 'NON-PURE',
        type: _isPurityBarChart ? 'bar' : 'line',
        color: '#00224E',
        data: _nonPurePopulationArr
      }
    ];
    setPopulationPurityData({...populationPurityData, series: _series})
  }

  // Action
  const handlePurityBarChartToggle = (e: boolean) => {
    setLoading(true)
    setIsPurityBarChart(e)
    initPopulationPurityData(populationPurityRowData, e)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }

  return (
    <>
      <div className='row card-header'>
        <div className='header-info'>
          <div className='title'>
            Total Population
            {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
          </div>
          <div className='value mt-2'>
            { totalPopulation ? totalPopulation.toLocaleString() : '' }
          </div>
        </div>
        <div className='header-toolbar mt-3'>
          <div className='time-filters'>
            <button className={`btn-filter ${filter === 'PURE' ? 'active' : ''}`}
                onClick={() => setFilter('PURE')}
            >Pure</button>
            <button className={`btn-filter ${filter === 'NON_PURE' ? 'active' : ''}`}
                onClick={() => setFilter('NON_PURE')}
            >Non Pure</button>
            <button className={`btn-filter ${filter === 'ALL' ? 'active' : ''}`}
                onClick={() => setFilter('ALL')}
            >All Crabs</button>
          </div>
          <img src={ChartIcon} className={`chart-icon ${isPurityBarChart ? 'active' : ''}`} alt='-' 
          onClick={() => handlePurityBarChartToggle(!isPurityBarChart)}
          />
        </div>
      </div>
      <div className='card-body'>
        {
          loading ? <ComponentLoader loading={loading} />
          : <div className='chart-wrapper'>
            {
              isPurityBarChart
              ? <BarGraph 
                  data={populationPurityData.series}
                  colors={populationPurityData.colors}
                  percentage={false}
                  range={filter}
              />
              : <LineGraph 
                  data={populationPurityData.series}
                  colors={populationPurityData.colors}
                  percentage={false}
                  range={filter}
              />
            }
          </div>
        }
      </div>
    </>
  )
}

export default PopulationPurityChart