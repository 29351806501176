import { ComingSoonCard, P2ELogoWhiteBeta } from '../../images'
import './MaintenanceCard.sass'

function MaintenanceCard(props: any) {
  const { message } = props
  return (
    <div className='maintenance-card'>
      <img src={ComingSoonCard} className='maintenance-bg' alt='' />
      <div className='maintenance-content'>
        <img src={P2ELogoWhiteBeta} className='p2e-logo' alt='' />
        <div className='maintenance-message'>{message ? message : ''}</div>
      </div>
    </div>
  )
}

export default MaintenanceCard