import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import NavContext, { defaultNav } from '../../context/NavContext';
import './Resources.sass'
import ResourcesAPI from './ResourcesAPI';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 40px)',
  maxWidth: '1360px',
  height: '100%',
  maxHeight: 'calc(100vh - 250px)',
  color: '#FFF',
  background: 'linear-gradient(180deg, #001431 0%, #000D1F 100%)',
  border: '2px solid #1370F6',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  zIndex: 10000,
  display: 'flex'
}

function Resources() {
  const navigate = useNavigate();
  const { setNavs } = useContext(NavContext);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [bottomPosts, setBottomPosts] = useState({
    count: 0,
    postsA: [],
    postsB: [],
    postsC: [],
    postsD: [],
    postsE: []
  });
  const [pageConfig, setPageConfig] = useState({
    totalPosts: 0,
    totalPages: 1,
    perPage: 14,
    page: 1,
  });
  const [postCPage, setPostCPage] = useState(0);
  const [postEPage, setPostEPage] = useState(0);
  // 
  const [posts, setPosts] = useState([]);
  const [searchClass, setSearchClass] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  // 
  const [newsCategoryId, setNewsCategoryId] = useState('');

  useEffect(() => {
    setNavs(defaultNav.navs)
    getAllPosts();
    getCategories();
  }, [])

  const getCategories = () => {
    ResourcesAPI.getCategories()
      .then((res: any) => {
        if(res.status === 200 && res.data) {
          let _categories: any = [];
          for(let i = 0; i < res.data.length; i++) {
            if(res.data[i].parent === 0) {
              _categories.push({
                id: res.data[i].id,
                name: res.data[i].name,
                slug: res.data[i].slug,
                count: res.data[i].count,
                parent: res.data[i].parent
              });
              if(res.data[i].name === 'News') {
                setNewsCategoryId(res.data[i].id);
              }
            }
          }
          setCategories(_categories);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      })
  }

  const formattedPosts = (_data: any) => {
    let _posts: any = []
    for(let i = 0; i < _data.length; i++){
      _posts.push({
        id: _data[i].id,
        created_at: _data[i].date,
        modified_at: new Date(_data[i].modified).getTime(),
        slug: _data[i].slug,
        type: _data[i].type,
        link: _data[i].link,
        title: _data[i].title.rendered,
        content: _data[i].content.rendered,
        excerpt: _data[i].excerpt.rendered,
        // featured_image_src: _data[i].featured_image_src,
        // featured_image_src_square: _data[i].featured_image_src_square,
        featured_image_src_original: _data[i].yoast_head_json?.og_image ? _data[i].yoast_head_json?.og_image[0].url : '',
        author_name: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].name : '',
        author_avatar: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].avatar_urls["96"] : '',
      })
    }
    let _sortedPosts = _posts.sort(function(a: any, b: any) { return b.created_at - a.created_at});
    return _sortedPosts;
  }

  const handleClickCategoryButton = (category_id: number) => {
    if(category_id) {
      getPostsByCategoryById(category_id);
    } else {
      getAllPosts();
    }
    setActiveCategory(category_id);
    setPostCPage(0);
    setPostEPage(0);
  }

  const getPostsByCategoryById = (category_id: number) => {
    ResourcesAPI.getResourcesByCategory(category_id)
      .then((res: any) => {
        if(res.headers && res.headers['x-wp-total']) {
          setPageConfig({...pageConfig, totalPosts: res.headers['x-wp-total']});
        }
        if(res.headers && res.headers['x-wp-totalpages']) {
          setPageConfig({...pageConfig, totalPages: res.headers['x-wp-totalpages']});
        }
        if(res.data) {
          let _posts = formattedPosts(res.data);
          initBottomPostsByCategory(_posts);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  }

  const initBottomPostsByCategory = (_posts: any) => {
    let _postsA: any = [];
    let _postsB: any = [];
    let _postsC: any = [];
    let _postsD: any = [];
    let _postsE: any = [];
    for(let i = 0; i < _posts.length; i++) {
      if(i === 0) {
        _postsA.push(_posts[i]);
      }
      if(i >= 1 && i <= 3) {
        _postsB.push(_posts[i]);
      }
      if(i >= 4) {
        _postsC.push(_posts[i]);
      }
    }
    for (let j = 0; j < posts.length; j++) {
      if(j < 2) {
        _postsD.push(posts[j]);
      } else {
        _postsE.push(posts[j]);
      }
    }
    setBottomPosts({
      count: _posts.length,
      postsA: _postsA,
      postsB: _postsB,
      postsC: _postsC,
      postsD: _postsD,
      postsE: _postsE
    });
  }

  const getAllPosts = () => {
    ResourcesAPI.getResources()
      .then((res: any) => {
        if(res.headers && res.headers['x-wp-total']) {
          setPageConfig({...pageConfig, totalPosts: res.headers['x-wp-total']});
        }
        if(res.headers && res.headers['x-wp-totalpages']) {
          setPageConfig({...pageConfig, totalPages: res.headers['x-wp-totalpages']});
        }
        if(res.data) {
          let _posts = formattedPosts(res.data);
          initBottomPosts(_posts);
          setPosts(_posts);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  }

  const initBottomPosts = (_posts: any) => {
    let _postsA: any = [];
    let _postsB: any = [];
    let _postsC: any = [];
    let _postsD: any = [];
    let _postsE: any = [];
    for(let i = 0; i < _posts.length; i++) {
      if(i === 0) {
        _postsA.push(_posts[i]);
      }
      if(i >= 1 && i <= 3) {
        _postsB.push(_posts[i]);
      }
      if(i >= 4 && i < 6) {
        _postsD.push(_posts[i]);
      }
      if(i >= 6) {
        _postsE.push(_posts[i]);
      }
    }
    setBottomPosts({
      count: _posts.length,
      postsA: _postsA,
      postsB: _postsB,
      postsC: _postsC,
      postsD: _postsD,
      postsE: _postsE
    });
  }

  const handleKeyUp = (e: any) => {
    setFilteredPosts(posts.filter(function(ele: any) {
      return ele.title.toLowerCase().includes(searchKey.toLowerCase());
    }));
  }
  
  return (
    <div className='resources'>
      <div className='container'>
        <div className='carousel-wrapper mt-5'>
          <div className='resources-banner'>
            <h1 className='title'>NEWS HUB</h1>
            <p className='description'>
              View the latest crypto gaming blogs, news, events, and more! 
            </p>
          </div>
        </div>
        {/*  */}
        <div className='blog-section mt-4'>
          <div className='highlight-section'>
            <div className={`section-header blue-top py-3`}>
              <div className={`category-tabs ${searchClass}`}>
                <button className={`btn btn-category ${activeCategory === 0 ? 'active' : ''}`}
                  onClick={() => handleClickCategoryButton(0)}
                >All</button>
                {
                  categories && categories.map((item: any, index: number) =>
                    item.count > 0 ?
                    <button className={`btn btn-category ${activeCategory === item.id ? 'active' : ''}`}
                      key={index}
                      onClick={() => handleClickCategoryButton(item.id)}
                    >{item.name}</button>
                    : null
                  )
                }
              </div>
              <div className={`search-box ${searchClass}`}>
                <a type="application/rss+xml" href={`/feed/rss?cid=${newsCategoryId}`} target='_blank' style={{marginRight: '15px', color: '#FFF'}}>
                  <RssFeedIcon />
                </a>
                <input type='text' className='search-input ms-auto' 
                  placeholder='Search' 
                  value={searchKey} 
                  onFocus={(e) => setSearchClass('search')}
                  onBlur={(e) => setSearchClass(searchKey ? 'search' : '')}
                  onChange={(e) => setSearchKey(e.target.value)} 
                  onKeyUp={(e) => handleKeyUp(e)}
                />
              </div>
            </div>
            {
              !searchKey && bottomPosts && 
              <>
                <div className='row mt-4'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-7'>
                    {
                      bottomPosts && bottomPosts.postsA && bottomPosts.postsA.map((item: any, index: number) =>
                        <div className='blog-A' key={index}>
                          <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                            <div className='blog-img-wrapper'>
                              <img src={item.featured_image_src_original} alt='' />
                            </div>
                            <div className='blog-title mt-4'>{item.title}</div>
                            <div className='blog-date pb-2'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                            <div className='blog-content' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            <div className='read-more mt-2'>Read More...</div>
                          </a>
                        </div>
                      )
                    }
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                    {
                      bottomPosts && bottomPosts.postsB && bottomPosts.postsB.map((item: any, index: number) =>
                      <div className='col-12 col-sm-12 col-md-12 px-md-20 px-lg-25' key={index}>
                        <div className='row mb-3'>
                          <div className='col-12 col-sm-5 col-md-5 mb-2'>
                            <div className='blog-img-wrapper'>
                              <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                                <img src={item.featured_image_src_original} alt='' />
                              </a>
                            </div>
                          </div>
                          <div className='col-12 col-sm-7 col-md-7 mb-2'>
                            <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                              <div className='blog-title blog-sm mb-1'>{item.title}</div>
                              <div className='blog-date'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                              <div className='blog-content blog-sm' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                              <div className='read-more mt-2'>Read More...</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                {
                  bottomPosts && bottomPosts.postsC && bottomPosts.postsC.map((item: any, index: number) =>
                    index < (4 + 4 * postCPage) ?
                    <div className='col-6 col-sm-6 col-md-6 px-md-20 px-lg-25' key={index}>
                      <div className='row my-3'>
                        <div className='col-12 col-sm-6 col-md-6 my-2'>
                          <div className='blog-img-wrapper'>
                            <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                              <img src={item.featured_image_src_original} alt='' />
                            </a>
                          </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 my-2'>
                          <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                            <div className='blog-title blog-sm mb-1'>{item.title}</div>
                            <div className='blog-date'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                            <div className='blog-content blog-sm' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            <div className='read-more mt-2'>Read More...</div>
                          </a>
                        </div>
                      </div>
                    </div>
                    : null
                )}
                </div>
                <div className='row'>
                  <button className={`btn btn-primary btn-load-more mt-4 mx-auto ${bottomPosts.postsC.length > (4 + 4 * postCPage) ? '' : 'd-none'}`}
                    onClick={() => setPostCPage(postCPage + 1)}
                  >Load More</button>
                </div>
              </>
            }
            {/*  */}
            {
              searchKey && 
              <div className='row'>
              {
                filteredPosts && filteredPosts.map((item: any, index: number) =>
                  <div className='col-6 col-sm-6 col-md-6 px-md-20 px-lg-25' key={index}>
                    <div className='row my-3'>
                      <div className='col-12 col-sm-6 col-md-6 my-2'>
                        <div className='blog-img-wrapper'>
                          <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                            <img src={item.featured_image_src_original} alt='' />
                          </a>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-6 my-2'>
                        <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                          <div className='blog-title blog-sm mb-1'>{item.title}</div>
                          <div className='blog-date'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                          <div className='blog-content blog-sm' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                          <div className='read-more mt-2'>Read More...</div>
                        </a>
                      </div>
                    </div>
                  </div>
              )}
              </div>
            }
          </div>
          {/* Latest Posts */}
          {
            !searchKey && 
            <>
              <div className='red-top mt-3'>
                <h4 className='title text-red'>Latest Posts</h4>
              </div>
              <div className='row'>
              {
                bottomPosts && bottomPosts.postsD && bottomPosts.postsD.map((item: any, index: number) =>
                  <div className='col-6 col-sm-6 col-md-6 px-md-20 px-lg-25' key={index}>
                    <div className='row my-3'>
                      <div className='col-12 col-sm-6 col-md-6 my-2'>
                        <div className='blog-img-wrapper'>
                          <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                            <img src={item.featured_image_src_original} alt='' />
                          </a>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-6 my-2'>
                        <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                          <div className='blog-title blog-sm mb-1'>{item.title}</div>
                          <div className='blog-date'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                          <div className='blog-content blog-sm' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                          <div className='read-more mt-2'>Read More...</div>
                        </a>
                      </div>
                    </div>
                  </div>
              )}
              </div>
              <div className='row'>
              {
                bottomPosts && bottomPosts.postsE && bottomPosts.postsE.map((item: any, index: number) =>
                  index < (8 + 6 * postEPage) ?
                  <div className='col-6 col-sm-6 col-md-6 px-md-20 px-lg-25' key={index}>
                    <div className='row my-3'>
                      <div className='col-12 col-sm-5 col-md-5 my-2'>
                        <div className='blog-img-wrapper'>
                          <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                            <img src={item.featured_image_src_original} alt='' />
                          </a>
                        </div>
                      </div>
                      <div className='col-12 col-sm-7 col-md-7 my-2'>
                        <a className='blog-link' href={`https://p2eanalytics.com/web3/${item.slug}`}>
                          <div className='blog-title blog-sm mb-1'>{item.title}</div>
                          <div className='blog-date'>Posted {moment(item.created_at).format('MMM DD, YYYY h:mm a')}</div>
                          <div className='blog-content blog-sm' dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                          <div className='read-more mt-2'>Read More...</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  : null
              )}
              </div>
              <div className='row'>
                <button className={`btn btn-primary btn-load-more mt-4 mx-auto ${(bottomPosts.postsD.length + bottomPosts.postsE.length) > (10 + 6 * postEPage) ? '' : 'd-none'}`}
                  onClick={() => setPostEPage(postEPage + 1)}
                >Load More</button>
              </div>
            </>
          }
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default Resources;
