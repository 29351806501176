import React from 'react';
import { useLocation } from 'react-router-dom';
import ResourcesAPI from '../pages/Resources/ResourcesAPI';

export default function Rss() {
  const [posts, setPosts] = React.useState([]);
  const search = useLocation().search;
  const cid = new URLSearchParams(search).get('cid');

  React.useEffect(() => {
    if(cid) {
      getNewsPosts(cid);
    } else {
      getAllPosts();
    }
  }, [cid]);

  const getNewsPosts = (cid: any) => {
    if(cid > 0) {
      ResourcesAPI.getResourcesByCategory(cid)
        .then((res: any) => {
          if(res.data) {
            let _posts = formattedPosts(res.data);
            setPosts(_posts);
          }
        })
        .catch((err: any) => {
          console.log('err: ', err);
        });
    }
  }

  const formattedPosts = (_data: any) => {
    let _posts: any = []
    for(let i = 0; i < _data.length; i++){
      _posts.push({
        id: _data[i].id,
        created_at: _data[i].date,
        modified_at: new Date(_data[i].modified).getTime(),
        slug: _data[i].slug,
        type: _data[i].type,
        link: _data[i].link,
        title: _data[i].title.rendered,
        content: _data[i].content.rendered,
        excerpt: _data[i].excerpt.rendered,
        // featured_image_src: _data[i].featured_image_src,
        // featured_image_src_square: _data[i].featured_image_src_square,
        featured_image_src_original: _data[i].yoast_head_json.og_image[0].url,
        author_name: _data[i]?._embedded.author[0].name,
        author_avatar: _data[i]?._embedded.author[0].avatar_urls["96"],
      })
    }
    let _sortedPosts = _posts.sort(function(a: any, b: any) { return b.created_at - a.created_at});
    return _sortedPosts;
  }

  const getAllPosts = () => {
    ResourcesAPI.getResources()
      .then((res: any) => {
        if(res.data) {
          let _posts = formattedPosts(res.data);
          setPosts(_posts);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  }

  return (
    <pre>
    {`<?xml version="1.0" encoding="UTF-8"?>
      <?xml-stylesheet type="text/xsl" media="screen" href="/~d/styles/rss2full.xsl"?>
      <?xml-stylesheet type="text/css" media="screen" href="http://rss.cnn.com/~d/styles/itemcontent.css"?>
      <rss
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:content="http://purl.org/rss/1.0/modules/content/"
        xmlns:atom="http://www.w3.org/2005/Atom"
        xmlns:media="http://search.yahoo.com/mrss/" version="2.0">
        <channel>
          <title>P2E Analytics</title>
          <link>https://p2eanalytics.com</link>
          <description>Your GameFi hub. Providing web3 gamers with all the necessary tools, insights, analytics, and information to make better decisions in web3 gaming</description>
            ${rssFeedItems(posts)}
        </channel>
      </rss>`}
    </pre>
  );
}

export function rssFeedItems(posts: any) {
  
  return posts
  .map((item: any) => {
    return `
      <item>
        <title>${item.title}</title>
        <description>${item.excerpt}</description>
        <author>${item.author_name}</author>
        <link>${'https://p2eanalytics.com/web3/' + item.slug}</link>
        <guid>${'https://p2eanalytics.com/web3/' + item.slug}</guid>
        <pubDate>${item.created_at}</pubDate>
        <media:group>
          <media:content medium="image" url="${item.featured_image_src_original}" type="image/jpeg" />
        </media:group>
      </item>
      `;
  })
  .join("");
}