
export type Purities = {
  ZERO: number,
  ONE: number,
  TWO: number,
  THREE: number,
  FOUR: number,
  FIVE: number,
  SIX: number,
  ALL: number,
}

type SingleClassPurityDistributionProps = {
  img: any;
  color: string;
  purities: Purities;
};

const SingleClassPurityDistribution = (props: SingleClassPurityDistributionProps) => {
  const {img, color, purities} = props;
  return (
    <tr>
      <td>
        <img src={img} className='classIcon' />
      </td>
      <td><div className='classValue' style={{background: color}}>{purities.ZERO}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.ONE}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.TWO}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.THREE}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.FOUR}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.FIVE}</div></td>
      <td><div className='classValue' style={{background: color}}>{purities.SIX}</div></td>
      <td><div className='classValue' style={{border: `solid 1px ${color}`}}>{purities.ALL}</div></td>
    </tr>
  );
}

export default SingleClassPurityDistribution;
