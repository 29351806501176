import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeaturedGame from '../../components/FeaturedGame/FeaturedGame';
import HeroImageCarousel from '../../components/HeroImageCarousel/HeroImageCarousel'
import LatestBlog from '../../components/LatestBlog/LatestBlog';
import ResourcesAPI from '../Resources/ResourcesAPI';
import './Home.sass'
import GameProfileAPI from '../../apis/GameProfileAPI';

function Home() {
  const [games, setGames] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [pageConfig, setPageConfig] = useState({
    total: 10,
    totalPage: 1,
    page: 1
  });

  useEffect(() => {
    getGameProfiles(1);
    getBlogs();
  }, []);

  const getGameProfiles = (page: number) => {
    GameProfileAPI.getGameProfiles(page)
      .then((res: any) => {
        if(res.status === 200 && res.data) {
          // 
          setPageConfig({
            ...pageConfig,
            total: parseInt(res.headers['x-wp-total']),
            totalPage: parseInt(res.headers['x-wp-totalpages']),
            page: page
          });
          // 
          let sorted = res.data.sort(function (a: any, b: any) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
          // 
          let _games: any = [...games];
          for(let i = 0; i < sorted.length; i++) {
            _games.push(sorted[i]);
          }
          setGames(_games);
        }
      })
      .catch((err: any) => {
        console.log('err: ', err);
      })
  }

  const handleLoadMore = () => {
    getGameProfiles(pageConfig.page + 1);
  }

  const getBlogs = () => {
    ResourcesAPI.getResources(3)
      .then(res => {
        if(res.data) {
          let _posts = formattedPosts(res.data);
          setBlogs(_posts);
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }

  const formattedPosts = (_data: any) => {
    let _posts: any = []
    for(let i = 0; i < _data.length; i++){
      _posts.push({
        id: _data[i].id,
        created_at: _data[i].date,
        modified_at: new Date(_data[i].modified).getTime(),
        slug: _data[i].slug,
        type: _data[i].type,
        link: _data[i].link,
        title: _data[i].title.rendered,
        content: _data[i].content.rendered,
        excerpt: _data[i].excerpt.rendered,
        featured_image_src_original: _data[i].yoast_head_json?.og_image ? _data[i].yoast_head_json?.og_image[0].url : '',
        author_name: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].name : '',
        author_avatar: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].avatar_urls["96"] : '',
      })
    }
    let _sortedPosts = _posts.sort(function(a: any, b: any) { return b.created_at - a.created_at});
    return _sortedPosts;
  }
  
  return (
    <div className="home">
      <div className='container'>
        <div className='carousel-wrapper mt-5'>
          <HeroImageCarousel />
        </div>
        <div className='featured-games my-5'>
          <h3 className='title mb-5'>Featured Games</h3>
          <div className='games-wrapper'>
            <div className='row'>
              {
                games && games.map((item, index) =>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-4' key={index}>
                    <FeaturedGame game={item} /> 
                  </div>
                )
              }
            </div>
            <div className='row'>
              <button className={`btn btn-primary btn-load-more mt-4 mx-auto ${pageConfig.page === pageConfig.totalPage ? 'd-none' : ''}`}
                onClick={() => handleLoadMore()}
              >Load More</button>
            </div>
          </div>
        </div>
        <div className='latest-blogs my-5'>
          <h3 className='title mb-5'>Latest Play to Earn News</h3>
          <div className='blogs-wrapper'>
            <div className='row'>
              {
                blogs && blogs.map((item: any, index: number) =>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 mb-4' key={index}>
                    <LatestBlog data={item} />
                  </div>
                )
              }
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='button-wrapper'>
                  <a href='https://p2eanalytics.com/web3' className='btn-launch-news-hub'>
                    Launch News Hub
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
