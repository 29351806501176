import axios from 'axios'

// Token Values
export const getResources = (perPage = 100) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/posts?_embed&per_page=${perPage}`
    return axios.get(url)
}

export const getResourcesByPage = (perPage = 14, page = 1) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/posts?_embed&per_page=${perPage}&page=${page}`
    return axios.get(url)
}

export const getResourceBySlug = (slug: any) => {
    let url = "https://p2eanalytics.wpengine.com/wp-json/wp/v2/posts?_embed"
    let params = {
        slug: slug
    }
    return axios.get(url, { params })
}

export const getCategories = () => {
    let url = "https://p2eanalytics.wpengine.com/wp-json/wp/v2/categories?per_page=100"
    return axios.get(url)
}

export const getResourcesByCategory = (category_id: number) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/posts?_embed&per_page=100&categories=${category_id}`
    return axios.get(url)
}

export const getResourcesByCategoryAndPage = (category_id: number, perPage = 14, page = 1) => {
    let url = `https://p2eanalytics.wpengine.com/wp-json/wp/v2/posts?_embed&per_page=${perPage}&page=${page}&categories=${category_id}`
    return axios.get(url)
}

const CrabadaAPI = {
    getResources: getResources,
    getResourcesByPage: getResourcesByPage,
    getResourceBySlug: getResourceBySlug,
    getCategories: getCategories,
    getResourcesByCategory: getResourcesByCategory,
    getResourcesByCategoryAndPage: getResourcesByCategoryAndPage
}

export default CrabadaAPI;