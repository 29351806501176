import { CommunitySocialMediaLeadPDF, CryptoContentWriterPDF, SEOLead } from "../../assets";

export const careers = [
    {
      title: 'Blockchain God',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Back End Wizard',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Blockchain God',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Back End Wizard',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Blockchain God',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Back End Wizard',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Blockchain God',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
    {
      title: 'Back End Wizard',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
    },
]

export const Jobs = [
    {
        id: `1`,
        title: `Community & Social Media Lead`,
        description: `We're looking for a high-skilled Community & Social Media Lead to help drive new users
        acquisition through social media in North America, as well as help us managing our
        Discord channel. As part of the Content Marketing Team, you’ll be responsible for
        leading our social media and community strategy: from content automation up to
        hosting our live events. Alongside our Operational Manager, you will engage our vibrant
        and passionate community by offering content and experiences that will make them fall
        in love with our brand!`,
        file: CommunitySocialMediaLeadPDF
    },
    {
        id: `2`,
        title: `Crypto Content Writer`,
        description: `We're looking for a high-skilled Crypto Content Writer to help drive new users
        acquisition through search channels and social media in North America by producing
        amazing content to our news hub. As part of the Content Marketing Team, you’ll be
        responsible for creating daily posts about news related to the most recent crypto games
        in the cryptoverse.`,
        file: CryptoContentWriterPDF
    },
    {
        id: `3`,
        title: `SEO Lead`,
        description: `We're looking for a high-skilled SEO Analyst to help drive new users acquisition through
        search channels and social media in North America. As part of the Content Marketing
        Team, you’ll be responsible for leading our SEO efforts and supporting our writers and
        journalists in their quest to produce the best content for both our users and our
        company.`,
        file: SEOLead
    }
];

export default Jobs;