import GameTokens from "../GameTokens/GameTokens";
import GameTopNavBar from "../GameTopNavBar/GameTopNavBar";

const GamePageLayout = (props: any) => {
    const { title, tokens, erc20IDs, navs, children } = props;
    return (
        <div className='container-fluid py-5'>
            <div className='dashboard-titlebar'>
                <div className='title-bar'>
                <div className='title'>{title}</div>
                <GameTokens tokens={tokens} erc20IDs={erc20IDs} />
                </div>
            </div>
            <GameTopNavBar navs={navs} />
            {children}
        </div>
    );
}

export default GamePageLayout;
