import axios from 'axios'

const headers = {
    'Content-Type': 'application/json',
};

const getProjects = () => {
    let url = `https://api.p2eanalytics.com/projects`;
    return axios.get(url, { headers: headers });
}

const getProjectAssetIDs = (id: any) => {
    let url = `https://api.p2eanalytics.com/projects`;
    return axios.get(url, { headers: headers });
}

const ProjectAPI = {
    getProjects: getProjects,
    getProjectAssetIDs: getProjectAssetIDs
}

export default ProjectAPI;
