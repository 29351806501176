import { Link } from 'react-router-dom';
import './LatestBlog.sass'

function LatestBlog(props: any) {
  const { data } = props;

  return (
    <div className='latest-blog'>
      <a className='blog-link' href={`https://p2eanalytics.com/web3/${data.slug}`}>
        <div className='blog-wrapper'>
          <div className='thumbnail'>
            <img src={data.featured_image_src_original} className='' alt='' />
          </div>
          <h3 className='blog-title'>{data.title}</h3>
          <div className='blog-content' dangerouslySetInnerHTML={{__html: data.excerpt}}></div>
        </div>
      </a>
    </div>
  )
}

export default LatestBlog;