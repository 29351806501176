import {AVAX, SLIME} from '../../../images/index'
import './snailTrailGame-tokens.sass'
import { useEffect, useState } from 'react'
import CrabadaAPI from '../../Crabada/CrabadaAPI'
import SlimeAPI from "../api/SlimeAPI";

function SnailTrailGameTokens () {
  const [avax, setAvax] = useState('0')
  const [slime, setSlime] = useState('0')

  useEffect(() => {
    CrabadaAPI.getTokenValues()
      .then(res => {
        if(res.data.result) {
          let tokens = res.data.result;
          setAvax(parseFloat(tokens.avax_usd).toFixed(2))
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
    // 
    SlimeAPI.getTokenValues()
      .then(res => {
        if(res.data.data) {
          let tokens = res.data.data[18216];
          setSlime(parseFloat(tokens.quote.USD.price).toFixed(5))
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }, [])

  return (
    <div className='dashboard-tokens'>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/avalanche/' className='' target='_blank'>
          <img src={AVAX} className='token-icon' alt='-' />
        </a>
          AVAX &nbsp; ${avax}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/snail-trail/' className='' target='_blank'>
          <img src={SLIME} className='token-icon' alt='-' />
        </a>
          {/*SLIME &nbsp; ${slime}*/}
          SLIME
      </div>
    </div>
  )
}

export default SnailTrailGameTokens
