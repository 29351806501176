import { 
  AVAX,
  TUS,
  PIZZA,
  InfomationIcon,
  SODA,
} from '../../../images/index'
import './pizzagame-tokens.sass'
import { useEffect, useState } from 'react'
import CrabadaAPI from '../../Crabada/CrabadaAPI'
import PizzaTokenAPI from "../api/PizzaTokenAPI";

function PizzaGameTokens () {
  const [avax, setAvax] = useState('0')
  const [pizza, setPizza] = useState('0')
  const [soda, setSoda] = useState('0')

  useEffect(() => {
    CrabadaAPI.getTokenValues()
      .then(res => {
        if(res.data.result) {
          let tokens = res.data.result;
          setAvax(parseFloat(tokens.avax_usd).toFixed(2))
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
    // 
    PizzaTokenAPI.getTokenValues()
      .then(res => {
        if(res.data.data) {
          let tokens = res.data.data[18216];
          setPizza(parseFloat(tokens.quote.USD.price).toFixed(5))
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }, [])

  return (
    <div className='dashboard-tokens'>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/avalanche/' className='' target='_blank'>
          <img src={AVAX} className='token-icon' alt='-' />
        </a>
          AVAX &nbsp; ${avax}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/pizza-game/' className='' target='_blank'>
          <img src={PIZZA} className='token-icon' alt='-' />
        </a>
          PIZZA &nbsp; ${pizza}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com' className='' target='_blank'>
          <img src={SODA} className='token-icon' alt='-' />
        </a>
          {/* SODA &nbsp; ${soda} */}
          SODA &nbsp; <img src={InfomationIcon} className='info-icon' alt='' title='Coming Soon' />
      </div>
    </div>
  )
}

export default PizzaGameTokens