import { useEffect, useState } from 'react';
import GameTokenAPI from '../../apis/GameTokenAPI';
import './GameTokens.sass';

const GameTokens = (props: any) => {
    const { title, tokens, erc20IDs } = props;
    const [avaxToken, setAvaxToken] = useState('0');
    const [gameTokens, setGameTokens] = useState<any>([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        GameTokenAPI.getAvaxToken()
            .then(res => {
                if(res.data.result) {
                    let tokens = res.data.result;
                    setAvaxToken(parseFloat(tokens.avax_usd).toFixed(4));
                }
            })
            .catch(err => {
                console.log('err: ', err)
            });
    }, []);

    useEffect(() => {
        if(tokens) {
            initTokens();
        }
    }, [tokens]);

    const initTokens = () => {
        let _gameTokens = [];
        for (let i = 0; i < tokens.length; i++) {
            _gameTokens.push({
                name: tokens[i].post_title,
                img: tokens[i].guid,
                value: ''
            });
        }
        setGameTokens(_gameTokens);
    }

    useEffect(() => {
        if(erc20IDs) {
            getGameTokens();
        }
    }, [erc20IDs]);

    const getGameTokens = () => {
        for (let i = 0; i < erc20IDs.length; i++) {
            getGameTokenValueByID(erc20IDs[i]);
        }
    }

    const getGameTokenValueByID = (erc20ID: string) => {
        GameTokenAPI.getGameTokenByAssetID(erc20ID)
            .then((res: any) => {
                if(res.status === 200 && res.data && res.data.data) {
                    let tokens = res.data.data[Object.keys(res.data.data)[0]];
                    let tokenName = tokens.symbol;
                    let tokenValue = parseFloat(tokens.quote.USD.price).toFixed(5);
                    updateGameTokenValue(tokenName, tokenValue);
                }
            })
            .catch((err: any) => {
                console.log('err: ', err);
            });
    }

    const updateGameTokenValue = (tokenName: string, tokenValue: string) => {
        let _gameTokens = gameTokens;
        for(let i = 0; i < _gameTokens.length; i++) {
            if(_gameTokens[i]['name'] === tokenName) {
                _gameTokens[i]['value'] = tokenValue;
            } else if(_gameTokens[i]['name'] === 'AVAX') {
                _gameTokens[i]['value'] = avaxToken;
            }
        }
        setGameTokens(_gameTokens);
        setTimeout(function(){
            setReload(!reload);
        }, 1500);
    }

    return (
        <div className='dashboard-tokens'>
            {
                gameTokens && gameTokens.map((item: any, index: number) =>
                    <div className='dashboard-token' key={index}>
                        <a href='' className='' target='_blank'>
                            <img src={item.img} className='token-icon' alt='-' />
                        </a>
                        {
                            item.name === 'AVAX' 
                            ? <>{item.name} &nbsp; {avaxToken ? '$' + avaxToken : ''}</>
                            : <>{item.name} &nbsp; {item.value ? '$' + item.value : ''}</>
                        }
                        
                    </div>
                )
            }
        </div>
    );
}

export default GameTokens;
