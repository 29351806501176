import { Link } from "react-router-dom"
import { Playback, Video1, Video2 } from "../../images";

export const StreamyardVideos = [
  {
    title: 'P2EA Live Show',
    date: 'Jun 13, 2022',
    embedId: 'tpv2yyqabmef',
    thumbnail: Video1,
    streamyard: 'https://streamyard.com/tpv2yyqabmef',
    twitch: '',
    youtube: 'https://www.youtube.com/watch?v=4kZEdqBpFac&list=PLwaZpQTygp8U-5WZKW-U-rJs2tPl6lbtP&index=1'
  },
  {
    title: 'Crabada Battle game is live, join us for the adventure',
    date: 'May 15, 2022',
    embedId: '2s4jdpfpis75',
    thumbnail: Video2,
    streamyard: 'https://streamyard.com/2s4jdpfpis75',
    twitch: '',
    youtube: ''
  }
];

const LatestVideos = () => {

  return (
    <div className='latest-articles blue-top'>
      <h3 className='title'>Latest Shows</h3>
      {
        StreamyardVideos && StreamyardVideos.map((item: any, index: number) =>
          <div className='row my-4 video-item' key={index}>
            <div className='col-6'>
              <a href={`https://p2eanalytics.com/web3/video/${item.embedId}`} className='video-link'>
                <div className='video-img-wrapper'>
                  <img src={item.thumbnail} className='w-100' alt='' />
                  <img src={Playback} className='overlay-icon' alt='' />
                </div>
              </a>
            </div>
            <div className='col-6'>
              <a href={`https://p2eanalytics.com/web3/video/${item.embedId}`}>
                <div className='video-date'>{item.date}</div>
                <div className='video-title'>{item.title}</div>
              </a>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default LatestVideos;