import "./Profile.sass";
import Overview from "./Overview";
import TokenPerformance from "./TokenPerformance";
import GameContent from "./GameContent";
import RelatedGameArticles from "../RelatedGameArticles/RelatedGameArticles";

const GameProfile = (props: any) => {
    const { data, erc20IDs } = props;
    return (
        <div className="profile">
            <Overview data={data} erc20IDs={erc20IDs} />
            {/*  */}
            <TokenPerformance erc20IDs={erc20IDs} tokens={data?.game_tokens} />
            {/*  */}
            <GameContent data={data} title={data?.game_name} />
            {/*  */}
            <RelatedGameArticles />
        </div>
    );
}

export default GameProfile;
