import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useLayoutEffect, useState } from 'react';
import Slider from "react-slick";
import { Modal, Box } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ResourcesAPI from '../../pages/Resources/ResourcesAPI';
import moment from 'moment';
import { Playback } from '../../images';

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div
            className={className}
            onClick={onClick}
        >
			<ArrowForwardIcon />
		</div>
	);
}

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div
            className={className}
            onClick={onClick}
        >
			<ArrowBackIcon />
		</div>
	);
}

const GameContent = (props: any) => {
    const { data, title } = props;
    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    });
    const [open, setOpen] = useState(false);
    const [openedVideo, setOpenedVideo] = useState<any>(null);
    const [openedVideoIndex, setOpenedVideoIndex] = useState(0);
    const handleOpen = (video: any, index: number) => {
        setOpenedVideoIndex(index);
        setOpenedVideo(video);
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const prevMedia = () => {
        let newIndex = openedVideoIndex;
        if(openedVideoIndex === 0) {
            newIndex = data.game_videos.split([',']).length - 1;
        } else {
            newIndex = openedVideoIndex - 1;
        }
        setOpenedVideoIndex(newIndex);
        setOpenedVideo(data.game_videos.split([','])[newIndex]);
    }
    
    const nextMedia = () => {
        let newIndex = openedVideoIndex;
        if(openedVideoIndex === data.game_videos.split([',']).length - 1) {
            newIndex = 0;
        } else {
            newIndex = openedVideoIndex + 1;
        }
        setOpenedVideoIndex(newIndex);
        setOpenedVideo(data.game_videos.split([','])[newIndex]);
    }

    useLayoutEffect(() => {
        function handleReSize() {
          let _slidesToShow: number = 2;
          if(window.screen.width < 768) {
            _slidesToShow = 1;
          } else {
            _slidesToShow = 2;
          }
          setSettings({
            ...settings,
            slidesToShow: _slidesToShow
          });
        }
        window.addEventListener('resize', handleReSize);
        handleReSize();
        return () => window.removeEventListener('resize', handleReSize);
    }, []);

    // === Articles === //
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    useEffect(() => {
        getResources();
    }, [title]);

    const getResources = () => {
        ResourcesAPI.getResources()
            .then(res => {
                if(res.data) {
                    initPosts(res.data)
                }
            })
            .catch(err => {
                console.log('err: ', err)
            });
    }

    const initPosts = (_data: any) => {
        let _posts: any = [];
        for(let i = 0; i < _data.length; i++){
        let _post: any = {
            id: _data[i].id,
            created_at: _data[i].date,
            modified_at: new Date(_data[i].modified).getTime(),
            slug: _data[i].slug,
            // type: _data[i].type,
            link: _data[i].link,
            title: _data[i].title.rendered,
            content: _data[i].content.rendered,
            excerpt: _data[i].excerpt.rendered,
            // featured_image_src: _data[i].featured_image_src,
            // featured_image_src_square: _data[i].featured_image_src_square,
            featured_image_src_original: _data[i].yoast_head_json?.og_image ? _data[i].yoast_head_json?.og_image[0].url : '',
            author_name: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].name : '',
            // author_avatar: _data[i]?._embedded.author[0].avatar_urls["96"],
            est_reading_time: _data[i]?.yoast_head_json.twitter_misc ? _data[i]?.yoast_head_json.twitter_misc["Est. reading time"] : '',
            categories: _data[i].categories
        }
        _posts.push(_post)
        }
        // recent game posts
        let _sortedGamePosts = _posts.filter(function(ele: any) {
            let res: boolean = true;
            for(let i = 0; i < title?.split(' ').length; i++) {
                res = res && ele.title.toLowerCase().includes(title?.split(' ')[i].toLowerCase());
            }
            return res;
        }).sort(function(a: any, b: any) { return b.modified_at - a.modified_at});
        setFilteredPosts(_sortedGamePosts);
    }
    
    return (
        <div className={`profile-section ${data?.game_videos || (filteredPosts && filteredPosts.length > 0) ? '' : 'd-none'}`}>
            <h4 className="section-header">
                {title} Content
            </h4>
            {
                data?.game_videos && 
                <>
                    <h5 className="section-sub-header">
                        Videos
                    </h5>
                    <div className="section-card videos">
                        <div className="videos-container">
                            <Slider className="w-100" {...settings}>
                                {
                                    data?.game_videos && data?.game_videos.split(',').map((item: any, index: number) =>
                                        <div className="video-item" key={index}
                                            onClick={() => handleOpen(item, index)}
                                        >
                                            <div className="video-thumbnail-wrapper">
                                                <img src={`https://img.youtube.com/vi/${item.trim().split('https://www.youtube.com/watch?v=')[1]}/0.jpg`} className="thumbnail-img" alt="" />
                                                <img src={Playback} className='overlay-icon' alt='' />
                                            </div>
                                            <div className="video-info">
                                                <div className="video-title">{data?.game_video_titles ? data?.game_video_titles.split(',')[index]?.trim() : ''}</div>
                                                <div className="video-date">{data?.game_video_dates ? data?.game_video_dates.split(',')[index]?.trim() : ''}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </>
            }
            {/*  */}
            {
                filteredPosts && filteredPosts.length > 0 && 
                <>
                    <h5 className="section-sub-header">
                        Our articles on {title}
                    </h5>
                    <div className="section-card articles">
                        <div className="articles-container">
                            <Slider className="w-100" {...settings} slidesToShow={filteredPosts.length < 3 ? 2 : settings.slidesToShow}>
                                {
                                    filteredPosts && filteredPosts.map((item: any, index: number) =>
                                        <div className="article-item" key={index}>
                                            <div className="article-image">
                                                <img className='w-100' src={item.featured_image_src_original} alt="" />
                                            </div>
                                            <a className="article-headline" href={`https://p2eanalytics.com/web3/${item.slug}`}>
                                                {item.title}
                                            </a>
                                            <div className="article-date">
                                                {item.created_at ? 'Posted ' + moment(item.created_at).format('MMM DD, YYYY h:mm a') : ''}
                                            </div>
                                            <a className="article-subline" href={`https://p2eanalytics.com/web3/${item.slug}`} dangerouslySetInnerHTML={{__html: item.excerpt}}></a>
                                        </div>
                                    )
                                }
                                <div className="article-item">
                                    <div className="article-image">
                                        <div className='news-hub-banner'>
                                            <div className='text-banner'>
                                                <div className='headline'>NEWS HUB</div>
                                                <div className='subline'>View the latest crypto gaming blogs, news, events, and more!</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="article-button">
                                        <a className="btn-launch-news-hub" href="https://p2eanalytics.com/web3">Launch News Hub</a>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </>
            }
            {/*  */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="profile-media-popup"
            >
                <Box className='popup-content'>
                    <div className="popup-arrow popup-arrow-prev"
                        onClick={() => prevMedia()}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <div style={{position: 'relative', width: '100%', height: '100%', paddingTop: '56.25%'}}>
                        {
                            openedVideo && <iframe width="100%" height="100%" 
                                src={`https://www.youtube.com/embed/${openedVideo.trim().split('https://www.youtube.com/watch?v=')[1]}?controls=1`} 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px'
                                }}
                            ></iframe>
                        }
                    </div>
                    <div className="popup-arrow popup-arrow-next"
                        onClick={() => nextMedia()}
                    >
                        <ArrowRightIcon />
                    </div>
                    <div className='mobile-arrows'>
                        <div className="popup-arrow-prev"
                            onClick={() => prevMedia()}
                        >
                            <SkipPreviousIcon />
                        </div>
                        <div className="popup-arrow-next"
                            onClick={() => nextMedia()}
                        >
                            <SkipNextIcon />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default GameContent;
