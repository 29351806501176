import { AVAX, BSC, Discord, ETH, GameControllerIconB, Medium, Telegram, Twitter } from "../../images";
import { Modal, Box } from '@mui/material'
import { useState } from "react";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import GameTokens from "../GameTokens/GameTokens";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 0px)',
  maxWidth: '1400px',
  maxHeight: 'calc(100vh - 50px)',
  color: '#FFF',
  background: 'linear-gradient(180deg, #001431 0%, #000D1F 100%)',
  border: '2px solid #1370F6',
  borderRadius: 2,
  boxShadow: 24,
  p: 0,
  zIndex: 10000,
  overflow: 'auto'
}

const Overview = (props: any) => {
    const { data, erc20IDs } = props;
    const [open, setOpen] = useState(false);
    const [openedImage, setOpenedImage] = useState<any>(null);
    const [openedImageIndex, setOpenedImageIndex] = useState(0);
    const handleOpen = (image: any, index: number) => {
        setOpenedImageIndex(index);
        setOpenedImage(image);
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const prevMedia = () => {
        let newIndex = openedImageIndex;
        if(openedImageIndex === 0) {
            newIndex = data.preview_images.length - 1;
        } else {
            newIndex = openedImageIndex - 1;
        }
        setOpenedImageIndex(newIndex);
        setOpenedImage(data.preview_images[newIndex]);
    }

    const nextMedia = () => {
        let newIndex = openedImageIndex;
        if(openedImageIndex === data.preview_images.length - 1) {
            newIndex = 0;
        } else {
            newIndex = openedImageIndex + 1;
        }
        setOpenedImageIndex(newIndex);
        setOpenedImage(data.preview_images[newIndex]);
    }

    return (
        <div className="profile-section">
            <h4 className="section-header">Overview</h4>
            <div className="section-card">
                <div className="responsive-row">
                    <div className="profile-overview">
                        <div className="profile-main">
                            <div className="profile-avatar">
                                {
                                    data?.game_logo && <img src={data?.game_logo.guid} alt="" />
                                }
                            </div>
                            <div className="profile-info mt-auto mb-3">
                                <div className="profile-headline">
                                    {data?.game_name}
                                    {/* <img className="token-icon" src={AVAX} alt='-' /> */}
                                    <span className={`game-status ${data?.game_status}`}>
                                        {data?.game_status.toLowerCase() === 'development' 
                                            ? 'Under development' 
                                            : (data?.game_status.toLowerCase() === 'beta' 
                                                ? 'Beta testing' 
                                                : (data?.game_status.toLowerCase() === 'live' ? 'Live' : '')
                                            )
                                        }
                                    </span>
                                </div>
                                <div className="profile-subline">{data?.game_category}</div>
                                <div className='social-items'>
                                    <a href={data?.social_twitter} className='social-item' target='_blank'>
                                        <img src={Twitter} className='social-icon' alt='twitter' />
                                    </a>
                                    <a href={data?.social_telegram} className='social-item' target='_blank'>
                                        <img src={Telegram} className='social-icon' alt='telegram' />
                                    </a>
                                    <a href={data?.social_discord} className='social-item' target='_blank'>
                                        <img src={Discord} className='social-icon' alt='discord' />
                                    </a>
                                    <a href={data?.social_medium} className='social-item' target='_blank'>
                                        <img src={Medium} className='social-icon' alt='medium' />
                                    </a>
                                    <a href={data?.game_website} className='social-link' target='_blank' rel='nofollow'>
                                        <img src={GameControllerIconB} className='icon-web' alt='' />
                                    </a>
                                </div>
                                <div className="profile-network">
                                    {
                                        data?.game_network && data?.game_network.length > 0 && 
                                        data?.game_network.map((item: any, index: number) =>
                                            <div className='' key={index}>
                                                <img src={item?.guid} alt='-' />{item?.post_title} &nbsp;
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="profile-tokens">
                            <GameTokens tokens={data?.game_tokens} erc20IDs={erc20IDs} />
                        </div>
                        <div className="profile-description">
                            {data?.game_description}
                        </div>
                    </div>
                    <div className="overview-images">
                        {
                            (data && data?.preview_images) && data?.preview_images?.map((item: any, index: number) =>
                                index < 4 ?
                                <div className="overview-image-item" key={index}>
                                    <div className="overview-image-wrapper"
                                        onClick={() => handleOpen(item, index)}
                                    >
                                        <img src={item.guid} className="overview-image" alt="" />
                                    </div>
                                    {
                                        data?.preview_images.length > 4 && index === 3 && 
                                        <div className="overlay-more" onClick={() => handleOpen(item, index)}>+{data?.preview_images.length - 4}</div>
                                    }
                                </div>
                                : null
                            )
                        }
                    </div>
                </div>
            </div>
            {/*  */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="profile-media-popup"
            >
                <Box className='popup-content' sx={style}>
                    <div className="popup-arrow-prev"
                        onClick={() => prevMedia()}
                    >
                        <ArrowLeftIcon />
                    </div>
                    {
                    openedImage && <img src={openedImage.guid} alt="" width={'100%'} />
                    }
                    <div className="popup-arrow-next"
                        onClick={() => nextMedia()}
                    >
                        <ArrowRightIcon />
                    </div>
                    <div className='mobile-arrows'>
                        <div className="popup-arrow-prev"
                            onClick={() => prevMedia()}
                        >
                            <SkipPreviousIcon />
                        </div>
                        <div className="popup-arrow-next"
                            onClick={() => nextMedia()}
                        >
                            <SkipNextIcon />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Overview;
