import moment from "moment";

// ========== Miko's Tweets ========== //
export const mikoTweets = [
  {
    content: (
      <p>
        Why am I bullish on @avalancheavax #web3 gaming prospects? 
        Well, today, @playcastlecrush, a game developed by @wildlifestudios just started minting NFTs for WL spots. 
        You can get a Founder chest (35 AVAX) or Ascenders (3.5 AVAX). 
        <br />
        Is this a big deal? Yes!
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1533860723738218497?s=20&t=CHqGpD-OcLYrZDVnDHICgQ",
    timestamp: "1:17 PM · Jun 6, 2022",
    posted_at: new Date('2022-06-06 01:17:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        I believe that this is only the beginning for @PlayCrabada. 
        <br />
        The team is amazing and has been taking feedback from everyone including myself on how to stabilize the 
        economy by focusing on $TUS burns and lower idle game rewards.
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1532021943393783813?s=20&t=CHqGpD-OcLYrZDVnDHICgQ",
    timestamp: "11:31 AM · Jun 1, 2022",
    posted_at: new Date('2022-06-01 11:31:00 am').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        #web3 games need to market like #web2 games to make it. Game development is a business.
        <br />
        The high-level difference between web3 and web2 is that value accrues to both the players and game developers. 
        This also means that the risk is also distributed to both parties.
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1529575285833752577?s=20&t=CHqGpD-OcLYrZDVnDHICgQ",
    timestamp: "5:29 PM · May 25, 2022",
    posted_at: new Date('2022-05-25 05:29:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        1/ We're in an experimental phase with #GameFi #P2E economics. 
        There are many different token and reward models. 
        @SnailTrailGame has an interesting model which seems to be on the path to sustainability.
        <br />
        $SNAIL has reduced net emission by 350k tokens since launch 👇
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1528750680365797377?s=20&t=CHqGpD-OcLYrZDVnDHICgQ",
    timestamp: "10:52 AM · May 23, 2022",
    posted_at: new Date('2022-05-23 10:52:00 am').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        1/ Back in action! Sorry for being on a hiatus for so long. First, 
        a personal update, I'm now fully dedicated to web3! 
        <br />
        Today was my last day 
        on my 2-week notice. Second, we've ramped up @p2eanalytics, so expect some news, 
        frequent feature additions and #p2e content!
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1521354185098342400?s=20&t=N5lmByPPEmaKitnz_EHFlA",
    timestamp: "1:01 AM · May 3, 2022",
    posted_at: new Date('2022-05-03 01:01:00 am').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        1/ @PlayCrabada #P2E economy update! {" "}
        <br />
        Let's dig in. We'll start with $TUS minting and burning which impacts inflation. 
        <br />
        Based on the below chart from the @p2eanalytics #GameFi hub, you'll see that, 
        as expected, minting new $TUS has steadily increased due to higher # of crabs.
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1511471634930151427?s=20&t=N5lmByPPEmaKitnz_EHFlA",
    timestamp: "6:31 PM · Apr 5, 2022",
    posted_at: new Date('2022-04-05 06:31:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <p>
        1/ Crabada economy and ROI update using some charts from @P2EAnalytics!{" "}
        <br />
        @PlayCrabada is the #1 #P2E game on @avalancheavax .<br /> <br />
        Also:
        <br />
        1) @P2EAnalytics Discord will be live tomorrow <br />
        2) Site will go live Tuesday/Wednesday <br /> <br />
        Inflation: it's been 0.20-0.45% this week which is good,
      </p>
    ),
    link: "https://twitter.com/mikocryptonft/status/1508221257774514181?s=20&t=jUSkswTpeKd2T7q9NPaYCA",
    timestamp: "7:15 PM · Mar 27, 2022",
    posted_at: new Date('2022-03-27 07:15:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <>
        1/ Major improvements in the @PlayCrabada economy! <br />
        <br />
        A) Net inflation down to 0.2%! Driven by higher breeding and lower
        emission, likely due to more crabs either looting or renting. <br />
        B) Marketplace fees jumped. I added an annualized revenue metric in
        USD...
      </>
    ),
    link: "https://twitter.com/mikocryptonft/status/1504494608541265931?s=20&t=wz0bBW4rpKlfzFmaS6KOSQ",
    timestamp: "12:27 PM · Mar 17, 2022",
    posted_at: new Date('2022-03-17 12:27:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <>
        1/ As promised, a summarized comparison of @PlayCrabada and
        @AxieInfinity .<br />
        <br />
        In this thread I will cover gameplay, tokenomics, ROI impact and the
        teams. <br />
        This is my high level view and is not meant to be a comprehensive
        coverage.
      </>
    ),
    link: "https://twitter.com/mikocryptonft/status/1502709837762895883?s=20&t=wz0bBW4rpKlfzFmaS6KOSQ",
    timestamp: "1:15 PM · Mar 12, 2022",
    posted_at: new Date('2022-03-12 01:15:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <>
        Time for the weekly @PlayCrabada Economy update! <br />
        <br />
        A) Net inflation ticked up to 0.6%; good news is that breeding has
        stabilized <br />
        B) Both marketplace and tavern fees were higher, potentially pointing to
        increase in activity C) TUS holder count growth accelerated!
      </>
    ),
    link: "https://twitter.com/mikocryptonft/status/1500695179480731653?s=20&t=wz0bBW4rpKlfzFmaS6KOSQ",
    timestamp: "11:49 PM · Mar 6, 2022",
    posted_at: new Date('2022-03-06 11:49:00 pm').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
  {
    content: (
      <>
        As promised, I've pulled together the latest on the @PlayCrabada economy
        as well as an ROI guide. To start, let's look at $TUS inflation: <br />
        <br />
        A) Net inflation is flat at 0.5% compared to 2/20. Both emission and
        breeding are steady (surprisingly) <br />
        B) Marketplace activity has declined
      </>
    ),
    link: "https://twitter.com/mikocryptonft/status/1498184086472597505?s=20&t=wz0bBW4rpKlfzFmaS6KOSQ",
    timestamp: "1:31 AM · Feb 28, 2022",
    posted_at: new Date('2022-02-28 01:31:00 am').getTime(),
    sponsored: false,
    name: 'Miko',
    username: '@mikocryptonft'
  },
];

// ========== P2E Analytics Tweets ========== //
export const p2eTweets = [
  {
    content: (
      <p>
        1/Daily digest from P2Eanalytics.com
        <br />
        A daily thread that covers: upcoming minting events, testnet launches, 
        new game announcements, AMAs/spaces and game updates.
        <br />
        Games, please reach out if we missed your update! #P2E #GameFi
        <br />
        Follow and RT if you find it helpful👇
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1534240352487591936?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "2:26 PM · Jun 7, 2022",
    posted_at: new Date('2022-06-07 02:26:00 pm').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
  {
    content: (
      <p>
        1/ Its time for our daily discord  mini-game announcement! 
        <br />
        Discord mini-game🎮 giveaway
        <br />
        🎁 will be in #Avax 🔺
        <br />
        Follow👉 @P2EAnalytics on Twitter:
        <br />
        Follow 👉 @mikocryptonft on Twitter: 
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1533916645139177474?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "5:00 PM · Jun 6, 2022",
    posted_at: new Date('2022-06-06 05:00:00 pm').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
  {
    content: (
      <p>
        1/ Daily digest from P2Eanalytics.com
        <br />
        A daily thread that covers: upcoming minting events, testnet launches, 
        new game announcements, AMAs/spaces and game updates.
        <br />
        Games, please reach out if we missed your update! #P2E #GameFi
        <br />
        Follow and RT if you find it helpful.👇
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1533858028453351425?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "1:07 PM · Jun 6, 2022",
    posted_at: new Date('2022-06-06 01:07:00 pm').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
  {
    content: (
      <p>
        1/ Daily digest from P2Eanalytics.com 
        <br />
        A daily thread that covers: upcoming minting events, testnet launches, 
        new game announcements, AMAs/spaces and game updates.
        <br />
        Games, please reach out if we missed your update! #P2E #GameFi
        <br />
        Follow and RT if you find it helpful.👇
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1532774035267911683?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "1:19 PM · Jun 3, 2022",
    posted_at: new Date('2022-06-03 01:19:00 pm').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
  {
    content: (
      <p>
        1/ Daily digest from P2Eanalytics.com 
        <br />
        A daily thread that covers: upcoming minting events, testnet launches, 
        new game announcements, AMAs/spaces and game updates.
        <br />
        Games, please reach out if we missed your update! #P2E #GameFi
        <br />
        Follow and RT if you find it helpful.👇
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1532391765382156288?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "12:00 PM · Jun 2, 2022",
    posted_at: new Date('2022-06-02 12:00:00 pm').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
  {
    content: (
      <p>
        1/ Daily digest from p2Eanalytics.com 
        <br />
        A daily thread that covers: upcoming minting events, testnet launches, 
        new game announcements, AMAs/spaces and game updates.
        <br />
        Games, please reach out if we missed your update! #P2E #GameFi
        <br />
        Follow and RT if you find it helpful.👇
      </p>
    ),
    link: "https://twitter.com/P2EAnalytics/status/1532026379260272640?s=20&t=y5T90NGnX7zXi3jAuiV_yg",
    timestamp: "11:48 AM · Jun 1, 2022",
    posted_at: new Date('2022-06-01 11:48:00 am').getTime(),
    sponsored: false,
    name: 'P2E Analytics',
    username: '@P2EAnalytics'
  },
];

const _tweets = [...mikoTweets, ...p2eTweets];

export const tweets = _tweets.sort(function(a: any, b: any) {
  return b.posted_at - a.posted_at;
})
