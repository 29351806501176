import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CareersHeader from '../../components/CareersHeader/CareersHeader';
// import MaintenanceCard from '../../components/MaintenanceCard/MaintenanceCard';
import './Careers.sass'
import Jobs from './Jobs';

function CareerDetail() {
  const { id } = useParams();
  const [job, setCurrentJob] = useState({
    id: '',
    title: '',
    description: '',
    file: null
  });
  const [iframeHeight, setIframeHeight] = useState(1080);

  useEffect(() => {
    let _job = Jobs.filter(function(ele: any) {
      return ele.id === id
    })[0];
    setCurrentJob(_job);
  }, [id]);

  useLayoutEffect(() => {
    function handleReSize() {
      setIframeHeight(window.innerHeight - 72); // innnerHeight - topbarHeight
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [])
  
  return (
    <div className="careers">
      {/* <MaintenanceCard message="Coming Soon" /> */}
      <CareersHeader />
      <div className='container-fluid'>
        <div className='row'>
          {
            job.file && 
            <embed src={job.file} width="100%" height={iframeHeight + 'px'} />
          }
        </div>
      </div>
    </div>
  );
}

export default CareerDetail;
