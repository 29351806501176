import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import moment from "moment";
import {toDateString} from "../../utils/DateUtil";
import {EventEmitter} from "../../utils/Events";
import {ChartIcon} from "../../images";
import ComponentLoader from "../ComponentLoader/ComponentLoader";
import {AreaGraph, BarGraph} from "../Graphs";
import MarketplaceApiType from "../../games/MarketplaceApiType";
import CallOutBox from '../CallOutBox/CallOutBox';
import { round } from 'lodash';

type MarketplaceChartsProps = {
  nftName: string,
  isBaseCurrencyOfMarketplace: boolean,
  isInSightsShown:  boolean,
  baseCurrencyName: string,
  baseCurrencyImgSrc: string,
  api: MarketplaceApiType,
  isTechnicalDifficultiesCallOutBoxVisible?: boolean
}

function MarketplaceCharts(props: MarketplaceChartsProps) {
  const { baseCurrencyImgSrc, isBaseCurrencyOfMarketplace, isInSightsShown, api, nftName, isTechnicalDifficultiesCallOutBoxVisible} = props
  const metricsWrapperRef = useRef<any>(null)
  const metricsRef = useRef<any>(null)
  const [metricsBoxRatio, setMetricsBoxRatio] = useState(1)
  const [openSideNavStatus, setOpenSideNavStatus] = useState(false)
  // 
  const [loading, setLoading] = useState(false)
  const [barToggleLoading, setBarToggleLoading] = useState(false)
  // ============ Marketplace ============= //
  // Time Filter
  const [marketTimeFilter, setMarketTimeFilter] = useState('SEVEN') // 
  // Area/Bar Toggle
  const [isMarketplaceBarChart, setIsMarketplaceBarChart] = useState(false)
  // Response Data
  const [marketplaceData, setMarketplaceData] = useState([])
  // Charts Data
  const [marketMetrics, setMarketMetrics] = useState({
    allTimeVolume: '',
    allTimeUSDVolume: '',
    last30DaysVolume: '',
    last30DaysUSDVolume: '',
    last7DaysVolume: '',
    last7DaysUSDVolume: ''
  })
  const [marketRolling, setMarketRolling] = useState({
    volume: '',
    usdVolume: '',
    averagePrice: '',
    usdAveragePrice: '',
    unitsSold: '',

  })
  const [salesData, setSalesData] = useState([])
  const [averagePriceData, setAveragePriceData] = useState([])
  const [nftSoldData, setNftSoldData] = useState([])
  // Action

  const handleMarketplaceBarChartToggle = (isMarketplaceBarChart: boolean) => {
    setBarToggleLoading(true)
    setIsMarketplaceBarChart(isMarketplaceBarChart)
    initSalesData(marketplaceData, marketTimeFilter, isMarketplaceBarChart)
    setTimeout(() => {
      setBarToggleLoading(false)
    }, 100)
  }
  useEffect(() => {
    initSalesData(marketplaceData, marketTimeFilter, isMarketplaceBarChart)
    initAveragePriceData(marketplaceData, marketTimeFilter)
  }, [isBaseCurrencyOfMarketplace])

  useEffect(() => {
    getMarketplaceSales()
  }, [marketTimeFilter])

  useEffect(() => {
    getMarketplaceGeneral()
  }, [])

  const getMarketplaceGeneral = () => {
    // setLoading(true)
    api.getMarketplaceGeneral()
      .then((res : any) => {
        if(res.data) {
          setMarketMetrics({
            allTimeVolume: res.data.generalMetrics.allTimeVolume 
              ? (res.data.generalMetrics.allTimeVolume / Math.pow(10, 18)) > 9999 
                ? round(res.data.generalMetrics.allTimeVolume / Math.pow(10, 18)).toLocaleString() 
                : (res.data.generalMetrics.allTimeVolume / Math.pow(10, 18)).toLocaleString()
              : '0',
            allTimeUSDVolume: res.data.generalMetrics.allTimeUSDVolume 
              ? res.data.generalMetrics.allTimeUSDVolume > 9999 
                ? round(res.data.generalMetrics.allTimeUSDVolume).toLocaleString() 
                : res.data.generalMetrics.allTimeUSDVolume.toLocaleString() 
              : '0',
            last30DaysVolume: res.data.generalMetrics.last30DaysVolume 
              ? (res.data.generalMetrics.last30DaysVolume / Math.pow(10, 18)) > 9999 
                ? round(res.data.generalMetrics.last30DaysVolume / Math.pow(10, 18)).toLocaleString() 
                : (res.data.generalMetrics.last30DaysVolume / Math.pow(10, 18)).toLocaleString() 
              : '0',
            last30DaysUSDVolume: res.data.generalMetrics.last30DaysUSDVolume 
              ? res.data.generalMetrics.last30DaysUSDVolume > 9999 
                ? round(res.data.generalMetrics.last30DaysUSDVolume).toLocaleString() 
                : res.data.generalMetrics.last30DaysUSDVolume.toLocaleString() 
              : '0',
            last7DaysVolume: res.data.generalMetrics.last7DaysVolume 
              ? (res.data.generalMetrics.last7DaysVolume / Math.pow(10, 18)) > 9999 
                ? round(res.data.generalMetrics.last7DaysVolume / Math.pow(10, 18)).toLocaleString() 
                : (res.data.generalMetrics.last7DaysVolume / Math.pow(10, 18)).toLocaleString() 
              : '0',
            last7DaysUSDVolume: res.data.generalMetrics.last7DaysUSDVolume 
              ? res.data.generalMetrics.last7DaysUSDVolume 
                ? round(res.data.generalMetrics.last7DaysUSDVolume).toLocaleString() 
                : res.data.generalMetrics.last7DaysUSDVolume.toLocaleString() 
              : '0'
          })
          setMarketRolling({
            volume: res.data.rollingWindows.volume ? (res.data.rollingWindows.volume / Math.pow(10, 18)).toLocaleString() : '0',
            usdVolume: res.data.rollingWindows.usdVolume ? res.data.rollingWindows.usdVolume.toLocaleString() : '0',
            averagePrice: res.data.rollingWindows.averagePrice ? (res.data.rollingWindows.averagePrice / Math.pow(10, 18)).toLocaleString() : '0',
            usdAveragePrice: res.data.rollingWindows.usdAveragePrice ? res.data.rollingWindows.usdAveragePrice.toLocaleString() : '0',
            unitsSold: res.data.rollingWindows.unitsSold ? res.data.rollingWindows.unitsSold.toLocaleString() : '0'
          })
        }
        // setLoading(false)
      })
      .catch((err : any) => {
        console.log('err: ', err)
        // setLoading(false)
      })
  }

  const getMarketplaceSales = () => {
    setLoading(true)
    api.getMarketplaceSales(marketTimeFilter)
      .then((res : any) => {
        if(res.data.data) {
          setMarketplaceData(res.data.data)
          initSalesData(res.data.data, marketTimeFilter, isMarketplaceBarChart)
          initAveragePriceData(res.data.data, marketTimeFilter)
          initNftSoldData(res.data.data, marketTimeFilter)
        }
        setLoading(false)
      })
      .catch((err : any) => {
        console.log('err: ', err)
        setLoading(false)
      })
  }

  const initSalesData = (_data: any, _timeFilter: string, _isMarketplaceBarChart: boolean) => {
    let salesArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      let date = new Date(timestamp)
      let dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')
      
      if(isBaseCurrencyOfMarketplace) {
        salesArr.push({ 
          x: dateResult, 
          y: _data[i].averageNativeVolume / Math.pow(10, 18),
          category: 'market', 
          timeFilter: _timeFilter 
        })
      } else {
        // if (new Date(dateResult).getTime() > new Date("2021/11/14T00:00:00.000Z").getTime()) {
          salesArr.push({ 
            x: dateResult, 
            y: _data[i].averageUSDVolume,
            category: 'market', 
            timeFilter: _timeFilter 
          })
        // }
      }
    }

    let _salesData: any = []
    _salesData.push({name: 'Sales', type: (_isMarketplaceBarChart ? 'bar' : 'area'), data: salesArr})
    setSalesData(_salesData)
  }

  const initAveragePriceData = (_data: any, _timeFilter: string) => {
    let averagePriceArr: any = []

    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      const date = new Date(timestamp)
      const dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')
      
      if(isBaseCurrencyOfMarketplace) {
        averagePriceArr.push({ 
          x: dateResult, 
          y: _data[i].averageNativePrice / Math.pow(10, 18),
          category: 'market', 
          timeFilter: _timeFilter 
        })
      } else {
        // if (new Date(dateResult).getTime() > new Date("2021-11-14T00:00:00.000Z").getTime()) {
          averagePriceArr.push({ 
            x: dateResult, 
            y: _data[i].averageUSDPrice,
            category: 'market', 
            timeFilter: _timeFilter 
          })
        // }
      }
    }
    
    let _averagePriceData: any = []
    _averagePriceData.push({name: 'Average Price', type: 'area', data: averagePriceArr})
    setAveragePriceData(_averagePriceData)
  }

  const initNftSoldData = (_data: any, _timeFilter: string) => {
    let soldArr: any = []
    
    for (let i = 0; i < _data.length; i++) {
      let timestamp = _data[i].timestamp //Example -> in ms
      const date = new Date(timestamp)
      const dateResult = moment.utc(toDateString(date)).format('YYYY-MM-DD HH:mm:ss')

      soldArr.push({ 
        x: dateResult, 
        y: _data[i].unitsSold, 
        category: 'market', 
        timeFilter: _timeFilter 
      })
    }

    let _nftSoldData: any = []
    _nftSoldData.push({name: `${nftName} Sold`, type: 'area', data: soldArr})
    setNftSoldData(_nftSoldData)
  }

  // LayoutEffect
  useLayoutEffect(() => {
    function handleReSize() {
      scaleMetricsBox()
    }
    window.addEventListener('resize', handleReSize);
    handleReSize();
    return () => window.removeEventListener('resize', handleReSize);
  }, [isInSightsShown, openSideNavStatus])

  const scaleMetricsBox = () => {
    if(metricsWrapperRef.current.clientWidth < metricsRef.current.clientWidth) {
      setMetricsBoxRatio(Math.round(metricsWrapperRef.current.clientWidth * 10 / metricsRef.current.clientWidth) / 10 - 0.1)
    } else {
      setMetricsBoxRatio(1)
    }
  }

  EventEmitter.subscribe('openSideNav', (event: any) => {
    setOpenSideNavStatus(event)
  })

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='chart-card'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className='row card-header'>
                  <div className='header-info'>
                    <div className='title'>
                      Sales - Last 24h
                      {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                    </div>
                    <div className='value mt-2'>
                      {
                        isBaseCurrencyOfMarketplace
                        ? <div className='token-value'><img src={baseCurrencyImgSrc} className='token-icon me-1' alt='-' />{ marketRolling.volume }</div>
                        : <div className='token-value'>$ { marketRolling.usdVolume }</div>
                      }
                      {isTechnicalDifficultiesCallOutBoxVisible &&
                        <CallOutBox message='Sales data is experiencing errors which will be rectified shortly. Apologies for any confusion.' />
                      }
                    </div>
                  </div>
                  <div className='header-toolbar mt-3'>
                    <div className='time-filters'>
                      <button className={`btn-filter ${marketTimeFilter === 'SEVEN' ? 'active' : ''}`}
                        onClick={() => setMarketTimeFilter('SEVEN')}
                      >7 Days</button>
                      <button className={`btn-filter ${marketTimeFilter === 'THIRTY' ? 'active' : ''}`}
                              onClick={() => setMarketTimeFilter('THIRTY')}
                      >30 Days</button>
                      <button className={`btn-filter ${marketTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                        onClick={() => setMarketTimeFilter('ALL_TIME')}
                      >All Time</button>
                    </div>
                    <img src={ChartIcon} className={`chart-icon ${isMarketplaceBarChart ? 'active' : ''}`} alt='-'
                      onClick={() => handleMarketplaceBarChartToggle(!isMarketplaceBarChart)}
                    />
                  </div>
                </div>
                <div className='card-body'>
                  {
                    (barToggleLoading || loading) ? <ComponentLoader loading={barToggleLoading || loading} />
                    : <div className='chart-wrapper'>
                      {
                        isMarketplaceBarChart
                        ? <BarGraph 
                          data={salesData}
                          colors={['#B02170']}
                          percentage={false}
                          range={marketTimeFilter}
                          gradient={true}
                        />
                        : <AreaGraph 
                          data={salesData}
                          colors={['#B02170']}
                          percentage={false}
                          range={marketTimeFilter}
                          gradient={true}
                        />
                      }
                    </div>
                  }
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                <div className='metrics-wrapper' ref={metricsWrapperRef}>
                  <div className='chart-sales m-auto' ref={metricsRef}
                    style={{transform: `scale(${metricsBoxRatio})`}}
                  >
                    {
                      (marketMetrics && marketMetrics.allTimeVolume) ?
                      <div className='all-time-sales my-2'>
                        <div className='sales-title'>All Time Sales</div>
                        <div className='sales-value'>
                          {
                            isBaseCurrencyOfMarketplace

                            ? <div className='token-value'><img src={baseCurrencyImgSrc} className='token-icon' alt='-' />{marketMetrics.allTimeVolume}</div>
                            : <div className='token-value'>$ {marketMetrics.allTimeUSDVolume}</div>
                          }
                        </div>
                      </div>
                      : ''
                    }
                    <div className='sales-items'>
                      {
                        (marketMetrics && marketMetrics.last7DaysVolume) ?
                        <div className='sales-item my-2'>
                          <div className='sales-title'>Last 7 days</div>
                          <div className='sales-value'>
                            {
                              isBaseCurrencyOfMarketplace
                              ? <div className='token-value'><img src={baseCurrencyImgSrc} className='token-icon' alt='-' />{marketMetrics.last7DaysVolume}</div>
                              : <div className='token-value'>$ {marketMetrics.last7DaysUSDVolume}</div>
                            }
                          </div>
                        </div>
                        : ''
                      }
                      {
                        (marketMetrics && marketMetrics.last30DaysVolume) ?
                            <div className='sales-item my-2'>
                              <div className='sales-title'>Last 30 days</div>
                              <div className='sales-value'>
                                {
                                  isBaseCurrencyOfMarketplace
                                      ? <div className='token-value'><img src={baseCurrencyImgSrc} className='token-icon' alt='-' />{marketMetrics.last30DaysVolume}</div>
                                      : <div className='token-value'>$ {marketMetrics.last30DaysUSDVolume}</div>
                                }
                              </div>
                            </div>
                            : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row sm'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info'>
                <div className='title'>
                  Average Price - Last 24h
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  {
                    isBaseCurrencyOfMarketplace
                    ? <div className='token-value'><img src={baseCurrencyImgSrc} className='token-icon me-1' alt='-' />{ marketRolling.averagePrice }</div>
                    : <div className='token-value'>$ { marketRolling.usdAveragePrice }</div>
                  }
                </div>
              </div>
              <div className='header-toolbar mt-3'>
                <div className='time-filters'>
                  <button className={`btn-filter ${marketTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setMarketTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${marketTimeFilter === 'THIRTY' ? 'active' : ''}`}
                          onClick={() => setMarketTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${marketTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setMarketTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <AreaGraph 
                    data={averagePriceData}
                    colors={['#B02170']}
                    percentage={false}
                    range={marketTimeFilter}
                    gradient={true}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6'>
          <div className='chart-card'>
            <div className='row card-header'>
              <div className='header-info'>
                <div className='title'>
                  {nftName} Sold - Last 24h
                  {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
                </div>
                <div className='value mt-2'>
                  {/* <img src={CRA} className='token-icon me-1' alt='-' /> */}
                  <div className='token-value'>{ marketRolling.unitsSold }</div>
                  {isTechnicalDifficultiesCallOutBoxVisible &&
                    <CallOutBox message={`${nftName} sold data is experiencing errors which will be rectified shortly. Apologies for any confusion.`} />
                  }
                </div>
              </div>
              <div className='header-toolbar mt-3'>
                <div className='time-filters'>
                  <button className={`btn-filter ${marketTimeFilter === 'SEVEN' ? 'active' : ''}`}
                    onClick={() => setMarketTimeFilter('SEVEN')}
                  >7 Days</button>
                  <button className={`btn-filter ${marketTimeFilter === 'THIRTY' ? 'active' : ''}`}
                          onClick={() => setMarketTimeFilter('THIRTY')}
                  >30 Days</button>
                  <button className={`btn-filter ${marketTimeFilter === 'ALL_TIME' ? 'active' : ''}`}
                    onClick={() => setMarketTimeFilter('ALL_TIME')}
                  >All Time</button>
                </div>
              </div>
            </div>
            <div className='card-body'>
              {
                loading ? <ComponentLoader loading={loading} />
                : <div className='chart-wrapper'>
                  <AreaGraph 
                    data={nftSoldData}
                    colors={['#B02170']}
                    percentage={false}
                    range={marketTimeFilter}
                    gradient={true}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MarketplaceCharts