import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import Slider from "react-slick";
import ResourcesAPI from '../../pages/Resources/ResourcesAPI';

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div
            className={className}
            onClick={onClick}
        >
			<ArrowForwardIcon />
		</div>
	);
}

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div
            className={className}
            onClick={onClick}
        >
			<ArrowBackIcon />
		</div>
	);
}

const RelatedGameArticles = () => {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    });

    useLayoutEffect(() => {
        function handleReSize() {
          let _slidesToShow: number = 3;
          if(window.screen.width < 768) {
            _slidesToShow = 1;
          } else if(window.screen.width < 1024) {
            _slidesToShow = 2;
          } else {
            _slidesToShow = 3;
          }
          setSettings({
            ...settings,
            slidesToShow: _slidesToShow
          });
        }
        window.addEventListener('resize', handleReSize);
        handleReSize();
        return () => window.removeEventListener('resize', handleReSize);
    }, []);

    useEffect(() => {
        getResources();
    }, []);

    const getResources = () => {
        ResourcesAPI.getResources()
            .then(res => {
                if(res.data) {
                    initPosts(res.data)
                }
            })
            .catch(err => {
                console.log('err: ', err)
            });
    }

    const initPosts = (_data: any) => {
        let _posts: any = [];
        for(let i = 0; i < _data.length; i++){
        let _post: any = {
            id: _data[i].id,
            created_at: _data[i].date,
            modified_at: new Date(_data[i].modified).getTime(),
            slug: _data[i].slug,
            // type: _data[i].type,
            link: _data[i].link,
            title: _data[i].title.rendered,
            content: _data[i].content.rendered,
            excerpt: _data[i].excerpt.rendered,
            // featured_image_src: _data[i].featured_image_src,
            // featured_image_src_square: _data[i].featured_image_src_square,
            featured_image_src_original: _data[i].yoast_head_json?.og_image ? _data[i].yoast_head_json?.og_image[0].url : '',
            author_name: _data[i]?._embedded.author ? _data[i]?._embedded.author[0].name : '',
            // author_avatar: _data[i]?._embedded.author[0].avatar_urls["96"],
            est_reading_time: _data[i]?.yoast_head_json.twitter_misc ? _data[i]?.yoast_head_json.twitter_misc["Est. reading time"] : '',
            categories: _data[i].categories
        }
        _posts.push(_post)
        }
        // recent posts
        let _sortedPosts = _posts.sort(function(a: any, b: any) { return b.modified_at - a.modified_at});
        setPosts(_sortedPosts);
        // 
        let _groupedPosts: any = [];
        for(let i = 0; i < _sortedPosts.length; i++) {
            if(_groupedPosts.length < 10) {
                _groupedPosts.push(_sortedPosts[i]);
            }
        }
        setFilteredPosts(_groupedPosts);
    }

    return (
        <div className="profile-section">
            <h4 className="section-header">
                Related Articles &nbsp; &nbsp;
            </h4>
            <div className="section-card articles">
                <div className="articles-container">
                    <Slider className="w-100" {...settings} slidesToShow={filteredPosts.length < 3 ? 2 : settings.slidesToShow}>
                        {
                            filteredPosts && filteredPosts.map((item: any, index: number) =>
                                <div className="article-item" key={index}>
                                    <div className="article-image">
                                        <img className='w-100' src={item.featured_image_src_original} alt="" />
                                    </div>
                                    <a className="article-headline" href={`https://p2eanalytics.com/web3/${item.slug}`}>
                                        {item.title}
                                    </a>
                                    <div className="article-date">
                                        {item.created_at ? 'Posted ' + moment(item.created_at).format('MMM DD, YYYY h:mm a') : ''}
                                    </div>
                                    <a className="article-subline" href={`https://p2eanalytics.com/web3/${item.slug}`} dangerouslySetInnerHTML={{__html: item.excerpt}}></a>
                                </div>
                            )
                        }
                        <div className="article-item">
                            <div className="article-image">
                                <div className='news-hub-banner'>
                                    <div className='text-banner'>
                                        <div className='headline'>NEWS HUB</div>
                                        <div className='subline'>View the latest crypto gaming blogs, news, events, and more!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="article-button">
                                <a className="btn-launch-news-hub" href="https://p2eanalytics.com/web3">Launch News Hub</a>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default RelatedGameArticles;
