import { Cloud, Decoration, Fight, MissionFrame, P2EHi, Thund } from '../../images';
import './About.sass'

function About() {
    
  return (
    <div className="about">
      <div className='container'>
        <section className='hello-section'>
          <img src={P2EHi} className='img-p2e' alt='' />
          <h2 className='title'>Hello, World.</h2>
          <div className='description mx-auto'>
            <p>
              We believe in a level playing field in all things Gaming and DeFi.
            </p>
            <p className='line-2'>
              P2E Analytics was born out of a communal need to bring together Web3 gamers under one roof helping them make informed decisions throughout their journey into crypto gaming.
              We dedicate this platform, our work, resources, and time to our community. All our efforts are made for you.
            </p>
          </div>
        </section>
        {/*  */}
        <section className='mission-section'>
          <img src={MissionFrame} className='frame-mission' alt='' />
          <div className='text-center'>
            <img src={Cloud} className='cloud1' alt='' />
          </div>
          <h2 className='title'>Mission</h2>
          <div className='text-center'>
            <img src={Cloud} className='cloud2' alt='' />
            <img src={Cloud} className='cloud3' alt='' />
          </div>
          <div className='description mx-auto'>
            <p>
              Our mission is to provide accurate and dependable resources covering the world of Web3 gaming to empower players to level-up their decision-making.
            </p>
          </div>
        </section>
        {/*  */}
        <section className='values-section'>
          <h2 className='title'>Values</h2>
          <img src={Thund} className='img-thund' alt='' />
          <div className='section-content'>
            <div className='left-decoration'>
              <img src={Decoration} className='decoration-img' alt='' />
            </div>
            <div className='px-3'>
              <h4 className='sub-title'>Thorough</h4>
              <p className='description mx-auto'>
                To become the one-stop-shop for all things GameFi. Providing Web3 gamers with all necessary tools, insights, analytics, and information to make better decisions in Web3 gaming, across different digital realms. 
              </p>
            </div>
            <div className='right-decoration'>
              <img src={Decoration} className='decoration-img' alt='' />
            </div>
          </div>
          <div className='section-content'>
            <div className='left-decoration'>
              <img src={Decoration} className='decoration-img' alt='' />
            </div>
            <div className='px-3'>
              <h4 className='sub-title'>Cooperative</h4>
              <p className='description mx-auto'>
                We want to create a cooperative community that helps the next generation of users enter the world of Web3, by helping decrease the learning curve on all things GameFi.
              </p>
            </div>
            <div className='right-decoration'>
              <img src={Decoration} className='decoration-img' alt='' />
            </div>
          </div>
        </section>
        {/*  */}
        <section className='privacy-section'>
          <h2 className='title'>Privacy &amp; Security</h2>
          <img src={Fight} className='img-fight' alt='' />
          <div className='description mx-auto'>
            <p>
              P2E Analytics is passionate and committed to safeguarding the privacy and security of our users every step of the way by enacting high-level industry standards for data protection designed to keep your personal information private and in your control. 
            </p>
            <p className='line-2'>
              Protecting the confidentiality and security of users' information has always been an integral part of how we conduct our business.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
