import InflationContainer from "../../../components/Inflation/InflationContainer";
import PizzaTokenCharts from "./PizzaTokenCharts";

function PizzaTokenInflation() {
	return (
			<InflationContainer
				title="Pizza Token"
				chartsComponent={
					<PizzaTokenCharts
							inSightsShow={true}
					/>
				}
			/>
	)
}

export default PizzaTokenInflation