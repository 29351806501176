import InsightsToggleButton from "../../../components/InsightsToggleButton/InsightsToggleButton";

type PopulationHeaderSectionProps = {
  showInsights: boolean;
  setShowInsights: Function;
};

function PopulationHeaderSection(props: PopulationHeaderSectionProps) {
  const { showInsights, setShowInsights } = props;
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-8">
        <div className="section-title-bar">
          <div className="section-title">Population Breakdown</div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <InsightsToggleButton
          className="ms-auto mt-2"
          show={showInsights}
          toggleInsights={setShowInsights}
        />
      </div>
    </div>
  );
}

export default PopulationHeaderSection;
