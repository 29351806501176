import { useEffect, useState } from "react";
import { Bulk, Craboid, Gem, InfomationIcon, Organic, Prime, Ruined, Sunken, Surge } from "../../../images";
import SingleClassPurityDistribution, {Purities} from "./SingleClassPurityDistribution";

type ClassPurityDistribution = {
  SURGE: Purities;
  SUNKEN: Purities;
  PRIME: Purities;
  BULK: Purities;
  CRABOID: Purities;
  RUINED: Purities;
  GEM: Purities;
  ORGANIC: Purities;
};

type PopulationClassPurityDistributionProps = {
  classPurityDistribution: ClassPurityDistribution;
};

function PopulationClassPurityDistribution(
  props: PopulationClassPurityDistributionProps
) {
  const { classPurityDistribution } = props;
  const [totalAdultPopulation, setTotalAdultPopulation] = useState('')

  useEffect(() => {
    if(classPurityDistribution) {
      let total = 0;
      total += classPurityDistribution.SURGE.ALL;
      total += classPurityDistribution.SUNKEN.ALL;
      total += classPurityDistribution.PRIME.ALL;
      total += classPurityDistribution.BULK.ALL;
      total += classPurityDistribution.CRABOID.ALL;
      total += classPurityDistribution.RUINED.ALL;
      total += classPurityDistribution.GEM.ALL;
      total += classPurityDistribution.ORGANIC.ALL;
      setTotalAdultPopulation(total.toLocaleString());
    }
  }, [classPurityDistribution])

  return (
    <div className="row">
      <div className="col-12">
        <div className="chart-card table-card">
          <div className='row card-header'>
            <div className='header-info'>
              <div className='title'>
                Total Adult Population
                {/* <img src={InfomationIcon} className='info-icon ms-1' alt='-' /> */}
              </div>
              <div className='value mt-2'>
                { totalAdultPopulation }
              </div>
            </div>
          </div>
          <table className='population-table'>
            <thead>
              <tr>
                <th></th>
                <th>0/6</th>
                <th>1/6</th>
                <th>2/6</th>
                <th>3/6</th>
                <th>4/6</th>
                <th>5/6</th>
                <th>6/6</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <SingleClassPurityDistribution
                img={Surge}
                color='#40090B'
                purities={classPurityDistribution.SURGE}
              />
              <SingleClassPurityDistribution
                img={Sunken}
                color='#074040'
                purities={classPurityDistribution.SUNKEN}
              />
              <SingleClassPurityDistribution
                img={Prime}
                color='#40380F'
                purities={classPurityDistribution.PRIME}
              />
              <SingleClassPurityDistribution
                img={Bulk}
                color='#401913'
                purities={classPurityDistribution.BULK}
              />
              <SingleClassPurityDistribution
                img={Craboid}
                color='#001C40'
                purities={classPurityDistribution.CRABOID}
              />
              <SingleClassPurityDistribution
                img={Ruined}
                color='#1D1640'
                purities={classPurityDistribution.RUINED}
              />
              <SingleClassPurityDistribution
                img={Gem}
                color='#400C2B'
                purities={classPurityDistribution.GEM}
              />
              <SingleClassPurityDistribution
                img={Organic}
                color='#14400F'
                purities={classPurityDistribution.ORGANIC}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PopulationClassPurityDistribution;
