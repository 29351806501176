import { 
  AVAX,
  TUS,
  CRA,
  CRAM,
} from '../../../images/index'
import './crabada-tokens.sass'
import CrabadaAPI from '../CrabadaAPI'
import { useEffect, useState } from 'react'

function CrabadaTokens () {
  const [tokenValues, setTokenValues] = useState({
    avax: '0',
    cra: '0',
    tus: '0',
    cram: '0'
  })

  useEffect(() => {
    CrabadaAPI.getTokenValues()
      .then(res => {
        if(res.data.result) {
          let tokens = res.data.result;
          setTokenValues({
            ...tokenValues,
            avax: parseFloat(tokens.avax_usd).toFixed(4),
            cra: parseFloat(tokens.cra_usd).toFixed(4),
            tus: parseFloat(tokens.tus_usd).toFixed(4),
            cram: parseFloat(tokens.cram_usd).toFixed(4)
          })
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
  }, [])

  return (
    <div className='dashboard-tokens'>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/avalanche/' className='' target='_blank'>
          <img src={AVAX} className='token-icon' alt='-' />
        </a>
          AVAX &nbsp; ${tokenValues.avax}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/crabada/' className='' target='_blank'>
          <img src={CRA} className='token-icon' alt='-' />
        </a>
          CRA &nbsp; ${tokenValues.cra}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/treasure-under-sea/' className='' target='_blank'>
          <img src={TUS} className='token-icon' alt='-' />
        </a>
          TUS &nbsp; ${tokenValues.tus}
      </div>
      <div className='dashboard-token'>
        <a href='https://coinmarketcap.com/currencies/crabada-amulet/' className='' target='_blank'>
          <img src={CRAM} className='token-icon' alt='-' />
        </a>
          CRAM &nbsp; ${tokenValues.cram}
      </div>
    </div>
  )
}

export default CrabadaTokens